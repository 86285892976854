import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, IconButton } from '@mui/material'
import theme from '../../../styles/MUI-theme'

const FilterCarsByEquipment = ({ handleShowDialogWindow }) => {
    const { t } = useTranslation()
    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={{ xs: 0.5, lg: 1.5 }}>
            <Typography
                variant="h1"
                fontSize={{ xs: 24, lg: 32 }}
                fontWeight={'bold'}
                color={theme.palette.secondary.main}
            >
                {t('RAC.step_two.heading')}
            </Typography>
            <Box
                role="button"
                color={theme.palette.primary.main}
                onClick={handleShowDialogWindow}
                sx={{ color: theme.palette.primary.main, fontSize: { xs: 12, lg: 16 } }}
            >
                {/* {t('RAC.step_two.show_all_options')} */}
                <IconButton>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABJklEQVQ4jdXTvytFcRjH8de9jh+3pHSzskmGa7yjgSwWJiblbtdmNRgoRimbxV9hQhmNVmWQW0i6fhV3UIbzPSnO5XAZvJdP55znefd0vs83Z2IdRrGMMR8p4AwDeEr5vo8VHObDi14UUwqhE30h0yiGfomsgA7kmjQ0Ixf6ChBhDttowxHGcZ9B1IM9DGIH7XlsYgbdeEEl41SVUN+NWWzm0YUrNHCXjJyBQqhv4BJdEVbFJ/KMR8xnlO2gipsw0GqENfE/WsRIEGbhAsM4xga2ktOs4eEbooTH0FfjbTV+hf8tG3qXP5ZN4QDnIadbkS1gCf0hq63I6iiJr0wpPDcl+kK2hF3xLp1gshXZKcphojJuPyv+k9Woiy9sGg1ch0zjJvR7BVpCO7+ivlRpAAAAAElFTkSuQmCC"
                        width="19"
                        height="20"
                        alt=""
                    />
                    {/* <FilterAltOutlinedIcon sx={{ color: theme.palette.primary.main }} /> */}
                </IconButton>
            </Box>
        </Box>
    )
}

export default FilterCarsByEquipment
