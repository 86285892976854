import React, { useEffect, useState } from 'react'
import { REACT_APP_AUTOMAPA_API_KEY, environment } from '../../environment'
import TextLinks from '../../components/TextLinks'
import { useTranslation } from 'react-i18next'
import '../../styles/locations-box.css'
import { Helmet } from 'react-helmet'
import { API } from '../../forms/api'
import Links from '../../components/Links'

function LocationsMap(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('LocationsMap')
        console.log(params)
    }

    let placeholder = params.block[params.locale].hasOwnProperty('ButtonName') ? params.block[params.locale]['ButtonName'] : '',
        Link = params.block[params.locale].hasOwnProperty('Link') ? params.block[params.locale]['Link'] : '',
        LinkName = params.block[params.locale].hasOwnProperty('LinkName') ? params.block[params.locale]['LinkName'] : '';

    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])

    const targeoInitializeScript = document.getElementById("targeoInitializeScript");
    const targeoSuggesterInitializeScript = document.getElementById("targeoSuggesterInitializeScript");
    if (!targeoInitializeScript && !targeoSuggesterInitializeScript) {
        const targeoScript = document.createElement('script');
        targeoScript.setAttribute('id','targeoSuggesterInitializeScript');
        targeoScript.src = `https://mapa.targeo.pl/Targeo.html?vn=3_0&v=full&rv=form&ext=suggest&k=${REACT_APP_AUTOMAPA_API_KEY}&e=TargeoSuggesterInitialize&f=SuggesterInitialize`
        // targeoScript.src = `https://mapa.targeo.pl/Targeo.html?vn=3_0&k=${REACT_APP_AUTOMAPA_API_KEY}&f=TargeoMapInitializeBox`
        targeoScript.async = true

        const handleScriptLoad2 = () => {
            document.dispatchEvent(new Event('DOMContentLoaded'))
        }
        targeoScript.addEventListener('load', handleScriptLoad2)
        document.body.appendChild(targeoScript)
    }
    
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const getReleasePlaces = async () => {
        try {
            const response = await fetch(API['GET_RELEASE_PLACES'], {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                console.log(' res.releasePlaces')
                console.log( res.releasePlaces )
                setData(
                    res.releasePlaces
                        /*.filter(item => !item.name.includes('Autopunkt'))
                        .filter(item => !item.name.includes('Miasto'))
                        .sort((placeA, placeB) =>
                            placeA.city.toLowerCase().trim().localeCompare(placeB.city.toLowerCase().trim())
                        )*/
                )
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
            setError(error)
        }
    }
    useEffect(() => {
        getReleasePlaces()
    }, [])

    function pointsVars() {
        let points = '';
        Object.keys(data).map((item, i) => (
            points += point(item)
        ))
        return points
    }
    function point(num) {
        let coords = data[num].hasOwnProperty('gpsCoords') ? data[num].gpsCoords : false;
        if (!coords) return '';
        let lon = coords.hasOwnProperty('lon') ? data[num].gpsCoords.lon : false;
        let lat = coords.hasOwnProperty('lat') ? data[num].gpsCoords.lat : false;
        if (!lat && !lon ) return '';
        let city = data[num].hasOwnProperty('city') ? data[num].city : false;
        if (!city) return '';
        let name = data[num].hasOwnProperty('name') ? data[num].name : false;
        if (!name) return '';
        let address = data[num].hasOwnProperty('address1') ? data[num].address1 : '';
        let price = data[num].hasOwnProperty('priceIOH') ? data[num].priceIOH : 0;
        let img = 'orange-2.png';
        if (price >= 55) img = 'orange-2.png';
        if (price >= 120) img = 'orange-2.png';
        let url = window.origin.includes(environment.localhost) ? environment.siteUrlDev : window.location.origin;
        return "p"+num+" = new Targeo.MapObject.Point("+
            "{x: "+lon+", y: "+lat+"},"+
            "{imageUrl: '"+url+"/_templates/img/icons/pin/pin-"+img+"', w: 48, h: 48, coordsAnchor: {x: 24, y: 24}},"+
            "'p"+num+"',"+
            "{name: '"+name+"', zIndex: 502, draggable: true, balloon: {body: '"+city+"<br/>"+name+"<br/>"+address+"'}}"+
        ");"+
        "MapBox.UOAdd(p"+num+");"
    }
    return (
        <section className={'locations-box'}>
            <div className="wrapper">
                <div className={'container mx-auto px-8 d-flex'}>
                    <div className="map-box">
                        {data.length > 0 && <Helmet>
                            {/* <script type="text/javascript" id="TargeoSuggesterInitialize">{`  
                                function checkForQueryDiv() {
                                    const queryDiv = document.getElementById('query');
                                    if (queryDiv) {
                                        clearInterval(intervalId); 
                                        handleQueryDivAdded();
                                    }
                                }

                                const intervalId = setInterval(checkForQueryDiv, 500);

                                function handleQueryDivAdded() {         
                                    var suggest = new Targeo.FTSSuggest('query', {objectTypes: {places: true, poi: false}, icons: true, button: document.getElementById('search')});
                                    document.getElementById('query').addEventListener('searchResult', function(ev) { 
                                    console.log('searchResult', ev.detail);
                                });
                                                    
                                }
                            `}
                            </script> */}
                            <script type="text/javascript" nonce="425R2daGOJRZh9nR6085" id="TargeoMapInitializeBox">
                        {` 
                                    var MapBox;
                                    function TargeoMapInitializeBox() {
                                        var mapOptions = {
                                            container: 'TargeoMapContainerBox',
                                            z: 29.5,
                                            c: {x: 19.47970000, y: 52.06890000},
                                            modArguments: {
                                                Ribbon: {controls: []},
                                                Layers: {modes: ['map', 'satellite']},
                                                Buildings3D: {disabled: false, on: false},
                                                POI: {disabled: false, submit: false, correct: true, visible: true},
                                                FTS: {disabled: false},
                                                FindRoute: {disabled: false, pro_prompt: true},
                                                Traffic: {disabled: false, visible: false},
                                            }
                                        };
                                        MapBox = new Targeo.Map(mapOptions);
                                        MapBox.listen('onInit', function() {
                                            ` +
                            pointsVars() +
                            `
                                        })
                                        MapBox.initialize();
                                    }
                                    var TargeoInterval = setInterval(function(){          
                                        if (typeof Targeo !== 'undefined'){
                                            clearInterval(TargeoInterval);
                                            TargeoMapInitializeBox()
                                        }
                                    },100);
                                `}
                    </script>
                        </Helmet>
                        }
                        <a
                            href="https://mapa.targeo.pl/"
                            id="TargeoMapContainerBox"
                            style={{ position: 'relative', width: '100%', height: '160px' }}
                        >
                            Map Box Targeo
                            {error && <p className="error">Error:</p>}
                        </a>
                    </div>
                    <div className="info-box">
                        {params.block[params.locale]['Title'] && <h2 className={'c-'+params.block[params.locale]['TitleColor']}>{params.block[params.locale]['Title']}</h2>}
                        {params.block[params.locale]['Text'] && (
                            <div className="text-box">
                                <TextLinks text={params.block[params.locale]['Text']} line={params.line} />
                            </div>
                        )}
                        <div className="link-box">
                            {(LinkName !== '' && Link !== '') && <Links
                                    class="btn-link empty"
                                    url={Link}
                                    name={LinkName}
                                    line={params.line}
                                />
                            }
                        </div>
                    </div>                  
                </div>
            </div>
        </section>
    )
}

export default LocationsMap
