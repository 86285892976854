import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import theme from '../../../styles/MUI-theme'
import IconSearchResult from '../../components/IconSearchResult'
import IconUmbrella from '../../svg/IconUmbrella'
import IconWallet from '../../svg/IconWallet'
import IconTheft from '../../svg/IconTheft'
import IconTire from '../../svg/IconTire'
import IconCar from '../../svg/IconCar'
import IconTowing from '../../svg/IconTowing'
import IconSplotch from '../../svg/IconSplotch'
import IconMoneyBill from '../../svg/IconMoneyBill'
import PricesPackages from './PricesPackages'
import IconWindowDamage from '../../svg/IconWindowDamage'

const PackageDetails = ({
    id,
    name,
    ownContributnionToDamage,
    thiefProtection,
    widthTowing,
    withGlassProtection,
    withTiresProtection,
    withUpholsteryProtection,
    withPlatesProtextion,
    depositPrice,
    totalPrice,
    dayCount,
    selectedPackageID,
    handleClick,
    bestseller,
}) => {
    const { t } = useTranslation()
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(selectedPackageID === id)
    }, [id, selectedPackageID])

    return (
        <Box
            type="button"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            width="100%"
            minWidth={{ xs: '75%', lg: '33.33%' }}
            maxWidth={{ xs: '90%', lg: '33.33%' }}
            sx={{
                border: isActive ? '1px solid #f79226 ' : '1px solid #ccc',
                opacity: isActive ? 1 : 0.7,
                transition: 'opacity 0.5s',
                borderRadius: '20px',
                bgcolor: 'white',
                cursor: 'pointer',
            }}
            onClick={() => handleClick(id)}
        >
            <Box position="relative" overflow="hidden" pt={4} pb={2}>
                {bestseller && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: { xs: '20px', md: '28px' },
                            right: { xs: '-44px', md: '-48px' },
                            px: 5,
                            py: { xs: 0.5, md: 0.5 },
                            background: '#f79226 ',
                            color: 'white',
                            textTransform: 'uppercase',
                            fontSize: { xs: 11, md: 13 },
                            transform: 'rotate(45deg)',
                            fontWeight: '700',
                            letterSpacing: { xs: '1px', md: '2px' },
                        }}
                    >
                        Bestseller
                    </Box>
                )}
                <Box display="flex" alignItems="center" pb={3} px={3}>
                    {isActive ? (
                        <RadioButtonCheckedIcon color="secondary" />
                    ) : (
                        <RadioButtonUncheckedIcon color="secondary" />
                    )}
                    <Typography
                        sx={{
                            fontSize: { xs: 14, lg: 18 },
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            pl: { xs: 1, lg: 2 },
                        }}
                    >
                        {name}
                    </Typography>
                </Box>

                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconUmbrella />
                            </Box>
                            <Box></Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('RAC.step_three.oc_insurance')}</Typography>
                        </Box>
                        <IconSearchResult icon="YES" hideBackground noMargin />
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconWallet />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>
                                {t('RAC.step_three.own_contribution_in_damage')}
                            </Typography>
                        </Box>
                        {ownContributnionToDamage === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <Typography fontSize={{ xs: 14, lg: 16 }} fontWeight="bold" whiteSpace="nowrap">
                                {ownContributnionToDamage}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconTheft />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>
                                {t('RAC.step_three.theft_protection')}
                            </Typography>
                        </Box>
                        {thiefProtection === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <Typography fontSize={{ xs: 14, lg: 16 }} fontWeight="bold" whiteSpace="nowrap">
                                {thiefProtection}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconTowing />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('RAC.step_three.towing')}</Typography>
                        </Box>
                        {widthTowing === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <IconSearchResult icon="NO" hideBackground noMargin />
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconWindowDamage />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}> {t('RAC.step_three.protections.0')}</Typography>
                        </Box>
                        {withGlassProtection === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <IconSearchResult icon="NO" hideBackground noMargin />
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconTire />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('RAC.step_three.protections.1')}</Typography>
                        </Box>
                        {withTiresProtection === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <IconSearchResult icon="NO" hideBackground noMargin />
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconSplotch />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('RAC.step_three.protections.2')}</Typography>
                        </Box>
                        {withUpholsteryProtection === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <IconSearchResult icon="NO" hideBackground noMargin />
                        )}
                    </Box>
                </Box>
                <Box py={1} borderBottom="1px solid #CECECE">
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconCar />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('RAC.step_three.protections.3')}</Typography>
                        </Box>
                        {withPlatesProtextion === 'true' ? (
                            <IconSearchResult icon="YES" hideBackground noMargin />
                        ) : (
                            <IconSearchResult icon="NO" hideBackground noMargin />
                        )}
                    </Box>
                </Box>
                <Box py={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                        <Box display="flex" alignItems="center" maxWidth="80%">
                            <Box minWidth="36px">
                                <IconMoneyBill />
                            </Box>
                            <Typography fontSize={{ xs: 12, lg: 13 }}>{t('deposit')}</Typography>
                        </Box>
                        <Typography fontSize={{ xs: 14, lg: 16 }} fontWeight="bold" whiteSpace="nowrap">
                            {depositPrice}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <PricesPackages totalPrice={totalPrice} dayCount={dayCount} isActive={isActive} />
        </Box>
    )
}

export default PackageDetails
