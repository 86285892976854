import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Typography } from '@mui/material'

import IconSearchResult from '../../components/IconSearchResult'
import BackButtonInForm from '../../components/BackButtonInForm'

import '../../../styles/targeo-search.css'

const InfoAboutPickupPrices = ({ handleClickTab1, LAC, ...props }) => {
    const { t } = useTranslation()

    return (
        <Box py={1}>
            <Box pt={2} pl={1} display="flex" alignItems="center">
                <IconSearchResult icon="Address" hideBackground />

                <Box pl={0.5}>
                    <Typography fontSize="13px" fontWeight="700">
                        {t('RAC.step_one.pickup_point')}
                    </Typography>
                    <Typography fontSize="13px">{t('RAC.step_one.network')}</Typography>
                    <Typography fontSize="18px" fontWeight="700" pt={1}>
                        {t('from')} 0 zł
                    </Typography>
                </Box>
            </Box>

            {!LAC && (
                <BackButtonInForm
                    handleClick={handleClickTab1}
                    sx={{
                        fontSize: 14,
                        my: 1,
                        ml: 5.5,
                        p: 0,
                    }}
                >
                    {t('btn.check')}
                </BackButtonInForm>
            )}
            <Divider sx={{ mt: LAC ? 2 : 1, mb: LAC ? 0 : 1 }} />

            <Box pt={2} pl={1} display="flex" alignItems="center">
                <IconSearchResult icon="Location" hideBackground />

                <Box pl={0.5}>
                    <Typography fontSize="13px" fontWeight="700">
                        {t('RAC.step_one.choose_location')}
                    </Typography>
                    <Typography fontSize="13px">{t('RAC.step_one.address')}</Typography>
                    <Typography fontSize="18px" fontWeight="700">
                        {t('from')} 55 zł
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
export default InfoAboutPickupPrices
