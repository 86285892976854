import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { InputAdornment, MenuItem, TextField } from '@mui/material'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import useFetchLac from '../../hooks/useFetchLac'
import Spinner from '../../components/Spinner'
import ErrorBox from '../../RAC/ErrorBox'
import { API } from '../../api'

export default function CountrySelect({ userData, handleUserDataChange, showPopup, formType }) {
    const { locale } = useParams()

    const { data, loading, error, fetchData } = useFetchLac()

    useEffect(() => {
        const url = `${API[`GET_DICT_COUNTRIES`]}`
        fetchData(url, 'GET')
    }, [])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return data ? (
        <TextField
            name="userCountry"
            select
            id="country-select"
            value={userData.userCountry}
            onChange={e => {
                handleUserDataChange(e.target.name, e.target.value); 
                // handleUserDataChange('userPhoneNumber', ''); 
                formType === 'ind' ? showPopup('ind') : showPopup('business')}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LanguageOutlinedIcon />
                    </InputAdornment>
                ),
            }}
        >
            <MenuItem disabled value="none">
                {locale === 'pl' ? 'Kraj' : 'Country'}
            </MenuItem>
            {locale === 'pl'
                ? Object.keys(data.pl).map(country => (
                      <MenuItem key={country} value={country}>
                          {data.pl[country]}
                      </MenuItem>
                  ))
                : Object.keys(data.en).map(country => (
                      <MenuItem key={country} value={country}>
                          {data.en[country]}
                      </MenuItem>
                  ))}
        </TextField>
    ) : (
        <Spinner size={20} />
    )
}
