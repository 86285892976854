import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconTire({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    class="st0"
                    d="M12,24C5.38,24,0,18.62,0,12S5.38,0,12,0s12,5.38,12,12S18.62,24,12,24z M12,1C5.93,1,1,5.93,1,12
		s4.93,11,11,11s11-4.93,11-11S18.07,1,12,1z"
                />
                <path
                    class="st0"
                    d="M12,19.16c-3.95,0-7.16-3.21-7.16-7.16S8.05,4.84,12,4.84s7.16,3.21,7.16,7.16S15.95,19.16,12,19.16z M12,5.84
		C8.6,5.84,5.84,8.6,5.84,12S8.6,18.16,12,18.16s6.16-2.76,6.16-6.16S15.4,5.84,12,5.84z"
                />
                <g>
                    <rect
                        x="7.38"
                        y="8.32"
                        transform="matrix(0.309 -0.9511 0.9511 0.309 -4.6519 14.8332)"
                        class="st0"
                        width="1"
                        height="4.6"
                    />
                    <rect
                        x="7.06"
                        y="14.92"
                        transform="matrix(0.5881 -0.8088 0.8088 0.5881 -8.6158 13.9269)"
                        class="st0"
                        width="4.6"
                        height="1"
                    />
                    <rect
                        x="14.1"
                        y="13.22"
                        transform="matrix(0.809 -0.5878 0.5878 0.809 -6.3364 11.5451)"
                        class="st0"
                        width="1"
                        height="4.6"
                    />
                    <rect
                        x="13.82"
                        y="10.02"
                        transform="matrix(0.9512 -0.3086 0.3086 0.9512 -2.4599 5.4875)"
                        class="st0"
                        width="4.6"
                        height="1"
                    />
                    <path
                        class="st0"
                        d="M12.03,14.56c-1.41,0-2.56-1.15-2.56-2.56s1.15-2.56,2.56-2.56s2.56,1.15,2.56,2.56S13.44,14.56,12.03,14.56z
			 M12.03,10.44c-0.86,0-1.56,0.7-1.56,1.56s0.7,1.56,1.56,1.56c0.86,0,1.56-0.7,1.56-1.56S12.89,10.44,12.03,10.44z"
                    />
                    <rect x="11.53" y="5.34" class="st0" width="1" height="4.6" />
                </g>
            </svg>
        </SvgIcon>
    )
}
