import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useFormattedDate from '../hooks/useFormattedDate'
import useFormattedPrice from '../hooks/useFormattedPrice'
import { Alert, Box, Container, Divider, Typography, useTheme } from '@mui/material'

import useFetch from '../hooks/useFetch'

import { environment } from '../../environment'
import { API } from '../api'
import Spinner from '../components/Spinner'
import ErrorBox from './ErrorBox'
import { CheckCircle } from '@mui/icons-material'
import '../../styles/form.css'

const FormSummary = () => {
    const { t, i18n } = useTranslation()
    const { data, loading, error, fetchData } = useFetch()
    const { id, locale } = useParams()
    const theme = useTheme()

    const formattedDate = useFormattedDate(data?.variables?.payment_time_remaining_installment)
    const priceRemainingFormatted = useFormattedPrice(
        parseFloat(data?.variables?.price_remaining_installment).toFixed(2)
    )
    const priceFullFormatted = useFormattedPrice(parseFloat(data?.variables?.full_calculation_price).toFixed(2))
    const priceIntallment =
        useFormattedPrice(parseFloat(data?.variables?.full_calculation_required_instalment).toFixed(2)) || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return data ? (
        <Container>
            <Box p={4} width="100%">
                <Box textAlign="center" mb={2}>
                    <CheckCircle color="secondary" sx={{ fontSize: 40 }} />
                </Box>
                <Typography variant="h1" textAlign="center" fontWeight="bold" fontSize={{ xs: 16, lg: 20 }} mb={3}>
                    {t('RAC.thank_you_page.title')}
                </Typography>
                {data?.variables?.reservation_number && (
                    <>
                        <Typography
                            variant="h2"
                            textAlign="left"
                            fontWeight="bold"
                            fontSize={{ xs: 12, lg: 16 }}
                            color={theme.palette.primary.dark}
                            mb={1}
                        >
                            {t('order_number')}:
                        </Typography>
                        <Typography fontWeight="normal" fontSize={{ xs: 12, lg: 16 }} mb={3}>
                            {data?.variables?.reservation_number}
                        </Typography>
                    </>
                )}
                <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 12, lg: 16 }} color={theme.palette.primary.dark} mb={1}>
                    {t('status')}
                </Typography>
                {data?.variables?.payment_advance === 'yes' ? (
                    <>
                        <Alert severity="success" variant="standard" sx={{ mb: 3 }}>
                            {t('RAC.thank_you_page.reservation_paid_only_advance')}
                        </Alert>
                        {priceFullFormatted && (
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                                    <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                        {t('rental_cost')}
                                    </Typography>
                                    <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                        {priceFullFormatted}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </>
                ) : (
                    <Alert severity="success" variant="standard" sx={{ mb: 3 }}>
                        {t('RAC.thank_you_page.reservation_paid')}
                    </Alert>
                )}
                <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 12, lg: 16 }} color={theme.palette.primary.dark} mb={1}>
                    {t('paid')}
                </Typography>
                <Divider sx={{ mb: 1 }} />
                {data?.variables?.payment_advance === 'yes' ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                            {t('RAC.thank_you_page.advance_cost')}
                        </Typography>
                        <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                            {priceIntallment}
                        </Typography>
                    </Box>
                ) : (
                    priceFullFormatted && (
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                                <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                    {t('rental_cost')}
                                </Typography>
                                <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                    {priceFullFormatted}
                                </Typography>
                            </Box>
                        </>
                    )
                )}
                {data?.variables?.payment_advance === 'yes' && (
                    <>
                        <Typography
                            variant="h2"
                            textAlign="left"
                            fontWeight="bold"
                            fontSize={{ xs: 12, lg: 16 }}
                            color={theme.palette.primary.dark}
                            mb={1}
                        >
                            {t('to_pay')}
                        </Typography>
                        <Divider sx={{ mb: 1 }} />

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                {t('remaining_rental_cost')}
                            </Typography>
                            <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                {priceRemainingFormatted}
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 1 }} />

                        {formattedDate && (
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                    {t('date_of_payment')}
                                </Typography>
                                <Typography fontSize={{ xs: 12, lg: 16 }} mb={1}>
                                    {formattedDate}
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Container>
    ) : (
        <Spinner />
    )
}

export default FormSummary
