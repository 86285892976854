import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { environment } from '../../../environment'
import useFetch from '../../hooks/useFetch'
import { API } from '../../api'
import theme from '../../../styles/MUI-theme'
import Spinner from '../../components/Spinner'
import ChooseButton from '../../components/ChooseButton'
import ErrorBox from '../ErrorBox'
import useFormattedPrice from '../../hooks/useFormattedPrice'

const Prices = ({ item, carClass, model }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()
    const [offerId] = useState(item.classId)
    const [offerPrice] = useState(item.price)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formattedPrice = useFormattedPrice(item.price).replace(' zł', '')
    const formattedPricePerDay = useFormattedPrice(parseFloat(item.price / item.dayCount).toFixed(2)).replace(' zł', '')

    const handleSubmit = () => {
        setIsSubmitting(true)

        const data = {
            car_class_id: offerId,
            car_class_name: carClass.name,
            model: model,
            seats: carClass.seats.toString(),
            fuel: carClass.fuelType,
            transmission: carClass.transmissionType,
        }

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('SUBMIT : ', data)

        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box position="relative">
            <Box
                key={item.classId}
                display="flex"
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
                position="relative"
                pb={{ xs: 4, md: 3 }}
            >
                <Box width={{ xs: '100%', md: '50%' }} pb={1} pr={2}>
                    <Typography fontSize={{ xs: 10, md: 12 }} mb={0.5}>
                        {t('RAC.step_two.info_text_fuel_level')}
                    </Typography>
                    <Typography fontSize={{ xs: 10, md: 12 }} mb={0.5}>
                        {t('RAC.step_two.info_text_limit_abroad')}
                    </Typography>
                    <Typography
                        fontSize={{
                            xs: 10,
                            md: 12,
                        }}
                    >
                        ** {t('RAC.step_two.price_does_not_include_fees')}
                    </Typography>
                </Box>
                <Box width={{ xs: '100%', md: '50%' }}>
                    <Box display="flex" gap={6} alignItems="center" mb={{ xs: 0.5, md: 2 }} py={{ xs: 1, md: 2 }}>
                        <Box display="flex" alignItems="flex-end">
                            <Typography
                                fontSize={{ xs: 32, md: 42 }}
                                lineHeight={{ xs: 1, md: 1 }}
                                fontWeight="bold"
                                color={theme.palette.secondary.main}
                            >
                                {formattedPrice}
                            </Typography>
                            <Box px={1}>
                                <Typography fontSize={{ xs: 11, md: 14 }} fontWeight="bold">
                                    ZŁ **
                                </Typography>
                                <Typography fontSize={{ xs: 11, md: 14 }} textTransform="uppercase">
                                    / {item.dayCount} {t('RAC.step_two.price.days')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="flex-end">
                            <Typography fontSize={{ xs: 32, md: 42 }} lineHeight={{ xs: 1, md: 1 }} fontWeight="bold">
                                {formattedPricePerDay}{' '}
                            </Typography>
                            <Box px={1}>
                                <Typography fontSize={{ xs: 11, md: 14 }} fontWeight="bold">
                                    ZŁ **
                                </Typography>
                                <Typography fontSize={{ xs: 11, md: 14 }} textTransform="uppercase">
                                    / {t('RAC.step_two.price.per_day')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: { xs: '-58px', sm: '-74px' },
                    left: { xs: '50%', sm: 'auto' },
                    right: { xs: 'auto', sm: 0 },
                    transform: 'translateX(-50%)',
                }}
            >
                <ChooseButton
                    blueBtn
                    id="R2"
                    disableElevation
                    variant="contained"
                    disabled={isSubmitting}
                    handleClick={handleSubmit}
                >
                    {t('btn.choose')}
                </ChooseButton>
            </Box>
        </Box>
    )
}

export default Prices
