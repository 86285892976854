import { Box } from '@mui/material'
import React from 'react'

const ReservationDetailsBoxIcon = ({ children, ...sx }) => (
    <Box p={{ xs: 1, lg: 3 }} sx={{ ...sx }}>
        {children}
    </Box>
)

export default ReservationDetailsBoxIcon
