import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconFuel({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        class="st0"
                        d="M17.47,24H1.72c-0.28,0-0.5-0.22-0.5-0.5v-3.03c0-0.28,0.22-0.5,0.5-0.5h15.75c0.28,0,0.5,0.22,0.5,0.5v3.03
		C17.97,23.78,17.75,24,17.47,24z M2.22,23h14.75v-2.03H2.22V23z"
                    />
                    <path
                        class="st0"
                        d="M13.93,9.93H5.26c-0.28,0-0.5-0.22-0.5-0.5V6.4c0-0.28,0.22-0.5,0.5-0.5h8.67c0.28,0,0.5,0.22,0.5,0.5v3.03
		C14.43,9.71,14.21,9.93,13.93,9.93z M5.76,8.93h7.67V6.9H5.76V8.93z"
                    />
                    <g>
                        <path
                            class="st0"
                            d="M16.92,20.97H2.27V2.18C2.27,0.98,3.25,0,4.45,0h10.29c1.2,0,2.18,0.98,2.18,2.18V20.97z M3.27,19.97h12.65
			V2.18c0-0.65-0.53-1.18-1.18-1.18H4.45C3.8,1,3.27,1.53,3.27,2.18V19.97z"
                        />
                    </g>
                    <path
                        class="st0"
                        d="M22.28,8.41c-0.28,0-0.5-0.22-0.5-0.5V4.15l-3.3-3.3c-0.2-0.2-0.2-0.51,0-0.71s0.51-0.2,0.71,0l3.59,3.59v4.18
		C22.78,8.19,22.55,8.41,22.28,8.41z"
                    />
                    <path
                        class="st0"
                        d="M22.28,6.82c-1.58,0-2.87-1.29-2.87-2.87c0-0.77,0.3-1.49,0.84-2.03l0.71,0.71C20.6,2.97,20.4,3.44,20.4,3.94
		c0,1.03,0.84,1.87,1.87,1.87V6.82z"
                    />
                    <path
                        class="st0"
                        d="M20.33,19.31c-1.35,0-2.45-1.1-2.45-2.45v-7h1v7c0,0.8,0.65,1.45,1.45,1.45c0.8,0,1.45-0.65,1.45-1.45V7.91h1
		v8.95C22.78,18.21,21.68,19.31,20.33,19.31z"
                    />
                    <path
                        class="st0"
                        d="M18.87,9.86h-1c0-0.8-0.65-1.45-1.45-1.45l0-1C17.77,7.41,18.87,8.51,18.87,9.86z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
