import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import EquipmentItem from './EquipmentItem'

const Extras2 = ({ equipment, initialEquipmentValues, processData }) => {
    const { t } = useTranslation()

    const [selectedEquipment, setSelectedEquipment] = useState(equipment)

    return (
        processData && (
            <Box mb={2}>
                <Typography textAlign="left" variant="h2" fontSize={{ xs: 16, lg: 20 }} fontWeight={'bold'} color='secondary' mb={2}>
                    {t('RAC.step_three.heading_2')}
                </Typography>
                <Box border='1px solid #ccc' borderRadius='20px' py={1}>
                    {equipment?.map((el, i) => (
                        <EquipmentItem
                            key={el.id}
                            equipment={el}
                            withBorder={i !== 0}
                            initialEquipmentValues={initialEquipmentValues}
                            selectedEquipment={selectedEquipment}
                            setSelectedEquipment={setSelectedEquipment}
                        />
                    ))}
                </Box>
            </Box>
        )
    )
}

export default Extras2
