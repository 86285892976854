import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconSplotch({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M18.68,18.23c-0.28,0-0.5-0.22-0.5-0.5V9.34c0-1.12-0.91-2.03-2.03-2.03h-8c-1.12,0-2.03,0.91-2.03,2.03v8.39
		c0,0.28-0.22,0.5-0.5,0.5s-0.5-0.22-0.5-0.5V9.34c0-1.67,1.36-3.03,3.03-3.03h8c1.67,0,3.03,1.36,3.03,3.03v8.39
		C19.18,18.01,18.96,18.23,18.68,18.23z"
                />
                <path
                    d="M15.2,5.61H9.12c-0.35,0-0.67-0.14-0.91-0.39C7.98,4.96,7.86,4.62,7.89,4.28l0.26-3.03C8.21,0.56,8.82,0,9.51,0h5.3
		c0.69,0,1.3,0.56,1.36,1.25l0.26,3.03c0.03,0.34-0.08,0.69-0.31,0.94C15.88,5.46,15.55,5.61,15.2,5.61z M9.51,1
		C9.33,1,9.17,1.15,9.15,1.33v0L8.89,4.36C8.88,4.45,8.92,4.5,8.94,4.54c0.03,0.03,0.08,0.07,0.17,0.07h6.09
		c0.09,0,0.14-0.04,0.17-0.07c0.03-0.03,0.06-0.09,0.05-0.17l-0.26-3.03C15.15,1.15,14.98,1,14.81,1H9.51z M8.65,1.29L8.65,1.29
		L8.65,1.29z"
                />
                <path
                    d="M17.89,24H6.11c-0.54,0-1.28-0.23-1.73-0.54l-0.93-0.64c-0.32-0.22-0.87-0.74-0.87-1.25v-3.46c0-0.51,0.54-0.88,1.29-0.88
		h2.87c0.54,0,1.28,0.24,1.72,0.55l0.94,0.67c0.27,0.19,0.82,0.37,1.15,0.37h2.87c0.33,0,0.88-0.17,1.15-0.37l0.94-0.67
		c0.44-0.31,1.18-0.55,1.72-0.55h2.87c0.75,0,1.29,0.37,1.29,0.88v3.46c0,0.51-0.54,1.02-0.87,1.25l-0.93,0.64c0,0,0,0,0,0
		C19.17,23.77,18.43,24,17.89,24z M19.33,23.05L19.33,23.05L19.33,23.05z M3.59,18.28v3.3C3.6,21.59,3.76,21.81,4.02,22l0.93,0.64
		C5.23,22.83,5.78,23,6.11,23h11.78c0.33,0,0.88-0.17,1.16-0.36L19.98,22c0.27-0.18,0.42-0.41,0.44-0.47l-0.01-3.25
		c-0.06-0.02-0.16-0.04-0.29-0.04h-2.87c-0.33,0-0.88,0.17-1.15,0.37l-0.94,0.67c-0.44,0.31-1.18,0.55-1.72,0.55h-2.87
		c-0.54,0-1.28-0.24-1.72-0.55L7.9,18.6c-0.27-0.19-0.81-0.37-1.15-0.37H3.88C3.75,18.23,3.65,18.25,3.59,18.28z"
                />
                <g>
                    <path
                        d="M10.18,7.32c-0.28,0-0.5-0.22-0.5-0.5V5.11c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v1.71
			C10.68,7.09,10.46,7.32,10.18,7.32z"
                    />
                    <path
                        d="M14.14,7.32c-0.28,0-0.5-0.22-0.5-0.5V5.11c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v1.71
			C14.64,7.09,14.41,7.32,14.14,7.32z"
                    />
                </g>
                <path
                    d="M8.16,18.58c-0.28,0-0.5-0.22-0.5-0.5V6.82c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v11.26
		C8.66,18.35,8.44,18.58,8.16,18.58z"
                />
                <path
                    d="M16.16,18.23c-0.28,0-0.5-0.22-0.5-0.5V6.82c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v10.92
		C16.66,18.01,16.43,18.23,16.16,18.23z"
                />
            </svg>
        </SvgIcon>
    )
}
