import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import OptionItem from './OptionItem'

const Extras1 = ({ options, initialOptionsValues, processData }) => {
    const { t } = useTranslation()
    const [selectedOptions, setSelectedOptions] = useState(options)

    return (
        processData && (
            <Box mb={5}>
                <Typography textAlign="left" variant="h2" fontSize={{ xs: 16, lg: 20 }} fontWeight={'bold'} color='secondary' mb={2}>
                    {t('RAC.step_three.heading_1')}
                </Typography>
                <Box border='1px solid #ccc' borderRadius='20px' py={1}>
                    {options
                        // ukrycie 'Zniesienie udziału własnego w szkodzie'
                        ?.filter(el => el.id !== 1)
                        .map((el, i) => (
                            <OptionItem
                                key={el.id}
                                option={el}
                                withBorder={i !== 0}
                                initialOptionsValues={initialOptionsValues}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                            />
                        ))}
                </Box>
            </Box>
        )
    )
}

export default Extras1
