import React from 'react'
import { Box, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import theme from '../../styles/MUI-theme.js'

const Stepper = ({ steps = 5, activeStep }) => {
    const circleSize = '20px'

    return (
        <>
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
                <DoneIcon fontSize="14px" sx={{ color: theme.palette.secondary.main }} />

                {Array.from({ length: steps - 1 }).map((step, index) => (
                    <Box
                        key={`step-${index}`}
                        display="flex"
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={circleSize}
                        height={circleSize}
                    >
                        {index < activeStep - 2 ? (
                            <DoneIcon fontSize="14px" sx={{ color: theme.palette.secondary.main }} />
                        ) : (
                            <Typography
                                fontSize="14px"
                                color={
                                    index === activeStep - 2 || index < activeStep - 1
                                        ? theme.palette.secondary.main
                                        : '#505154'
                                }
                            >{`0${index + 2}`}</Typography>
                        )}
                    </Box>
                ))}
            </Box>
            <Box
                display="flex"
                alignItems={'center'}
                mb={4}
                borderRight="1px solid #c7c7c7"
                borderLeft={activeStep === 1 ? '1px solid #c7c7c7' : ''}
            >
                {Array.from({ length: steps - 1 }).map((step, index) => (
                    <Box
                        key={`step-${index}`}
                        display="flex"
                        alignItems={'center'}
                        width={`calc(100% - ${circleSize})`}
                    >
                        <Box
                            width="100%"
                            height={'5px'}
                            bgcolor={
                                index === activeStep - 2 || index < activeStep - 1
                                    ? theme.palette.secondary.main
                                    : 'white'
                            }
                            borderTop="1px solid #c7c7c7"
                            borderBottom="1px solid #c7c7c7"
                            borderColor={
                                index === activeStep - 2 || index < activeStep - 1
                                    ? theme.palette.secondary.main
                                    : '#c7c7c7'
                            }
                        ></Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default Stepper
