import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconCar({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M22.05,21.48h-2.82c-0.68,0-1.24-0.56-1.24-1.24v-0.87c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v0.87
		c0,0.13,0.11,0.24,0.24,0.24h2.82c0.13,0,0.24-0.11,0.24-0.24v-4.06c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v4.06
		C23.29,20.93,22.73,21.48,22.05,21.48z"
                />
                <path
                    d="M4.67,21.48H1.85c-0.68,0-1.24-0.56-1.24-1.24v-4.06c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v4.06
		c0,0.13,0.11,0.24,0.24,0.24h2.82c0.13,0,0.24-0.11,0.24-0.24v-0.87c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v0.87
		C5.91,20.93,5.35,21.48,4.67,21.48z"
                />
                <path
                    d="M1.91,10.13c-0.11,0-0.22-0.03-0.31-0.11L0.9,9.48C0.45,9.13,0,8.49,0,7.95c0-0.63,0.73-1.09,1.73-1.09h1.23
		c0.28,0,0.5,0.22,0.5,0.5s-0.22,0.5-0.5,0.5H1.73c-0.38,0-0.62,0.1-0.72,0.16c0.05,0.16,0.23,0.47,0.5,0.67l0.7,0.55
		c0.22,0.17,0.26,0.48,0.09,0.7C2.2,10.07,2.05,10.13,1.91,10.13z"
                />
                <path
                    d="M22.04,10.13c-0.15,0-0.3-0.07-0.39-0.19c-0.17-0.22-0.13-0.53,0.09-0.7l0.73-0.57C22.79,8.41,23,8.01,23,7.88
		c-0.03,0.02-0.33-0.11-0.78-0.11h-1.28c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h1.28c1.03,0,1.78,0.47,1.78,1.11
		c0,0.56-0.46,1.22-0.92,1.58l-0.73,0.57C22.25,10.1,22.15,10.13,22.04,10.13z"
                />
                <path
                    d="M16.11,11.38H7.89c-1.72,0-4.53-0.19-5.22-0.73l-0.8-0.62c-0.22-0.17-0.26-0.48-0.09-0.7c0.17-0.22,0.48-0.26,0.7-0.09
		l0.8,0.62c0.3,0.21,2.24,0.52,4.6,0.52h8.22c2.36,0,4.3-0.31,4.6-0.52l0.8-0.62c0.22-0.17,0.53-0.13,0.7,0.09
		c0.17,0.22,0.13,0.53-0.09,0.7l-0.8,0.62C20.65,11.19,17.83,11.38,16.11,11.38z"
                />
                <path
                    d="M16.41,19.49c-0.16,0-0.32-0.08-0.42-0.23l-1.03-1.58H9.04l-1.03,1.58c-0.15,0.23-0.46,0.3-0.69,0.14
		c-0.23-0.15-0.3-0.46-0.14-0.69l1.33-2.03h6.99l1.33,2.03c0.15,0.23,0.09,0.54-0.14,0.69C16.6,19.46,16.5,19.49,16.41,19.49z"
                />
                <g>
                    <path d="M6.91,14.59H4.69c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h2.22c0.28,0,0.5,0.22,0.5,0.5S7.19,14.59,6.91,14.59z" />
                    <path d="M19.31,14.59h-2.22c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h2.22c0.28,0,0.5,0.22,0.5,0.5S19.58,14.59,19.31,14.59z" />
                </g>
                <path
                    d="M20.87,19.49H3.02c-0.65,0-1.25-0.25-1.71-0.71c-0.46-0.46-0.71-1.07-0.71-1.71v-5.35c0-0.28,0.07-0.62,0.19-0.95
		c0.12-0.33,0.29-0.64,0.47-0.85l0.23-0.28c0.14-0.16,0.3-0.39,0.46-0.63c0.15-0.23,0.3-0.48,0.4-0.67l2.42-4.81
		C4.9,3.26,5.15,3,5.46,2.81c0.31-0.19,0.65-0.29,0.96-0.29h11.04c0.31,0,0.66,0.1,0.96,0.29c0.31,0.19,0.56,0.45,0.7,0.73
		l2.42,4.81c0.1,0.19,0.24,0.43,0.4,0.67s0.32,0.46,0.46,0.63l0.23,0.28c0.18,0.21,0.35,0.52,0.47,0.85
		c0.12,0.33,0.19,0.67,0.19,0.95v5.35c0,0.65-0.25,1.25-0.71,1.71C22.12,19.24,21.51,19.49,20.87,19.49z M11.09,18.49h9.78
		c0.38,0,0.74-0.15,1.01-0.42c0.27-0.27,0.42-0.63,0.42-1.01v-5.35c0-0.16-0.05-0.38-0.13-0.61c-0.08-0.22-0.19-0.42-0.29-0.55
		l-0.23-0.28c-0.16-0.19-0.35-0.45-0.53-0.72s-0.34-0.55-0.45-0.77l-2.42-4.81c-0.06-0.11-0.18-0.24-0.33-0.33
		c-0.15-0.09-0.31-0.15-0.44-0.15H6.42c-0.13,0-0.29,0.05-0.44,0.15C5.83,3.75,5.71,3.88,5.65,3.99L3.23,8.8
		c-0.11,0.22-0.28,0.5-0.45,0.77c-0.18,0.27-0.37,0.53-0.53,0.72l-0.23,0.28c-0.11,0.12-0.21,0.33-0.29,0.55
		c-0.08,0.22-0.13,0.45-0.13,0.61v5.35c0,0.38,0.15,0.74,0.42,1.01c0.27,0.27,0.63,0.42,1.01,0.42H11.09z"
                />
            </svg>
        </SvgIcon>
    )
}
