import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import IconCar from '../../svg/IconCar'
import IconPerson from '../../svg/IconPerson'
import IconGearbox from '../../svg/IconGearbox'
import IconFuel from '../../svg/IconFuel'
import IconLuggage from '../../svg/IconLuggage'
import EquipmentItemBoxIcon from './EquipmentItemBoxIcon'

const EquipmentList = ({ fuel, transmission, seats, largeBags, smallBags, fuelTypes, transmissionTypes }) => {
    const { t } = useTranslation()
    const { locale } = useParams()

    return (
        <List
            sx={{
                display: { xs: 'flex', md: 'block' },
                flexWrap: 'wrap',
                flexDirection: 'row',
                px: 0,
                py: {xs: 0, md:1},
                margin: 0,
            }}
        >
            <ListItem sx={{ p: 0, width: '50%' }}>
                <EquipmentItemBoxIcon>
                    <IconPerson />
                </EquipmentItemBoxIcon>
                <ListItemText py={0}>
                    <Typography fontSize={{ xs: 12, md: 16 }}>
                        {seats} {t('RAC.step_two.car_equipment.seats')}
                    </Typography>
                </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, width: '50%' }}>
                <EquipmentItemBoxIcon>
                    <IconGearbox />
                </EquipmentItemBoxIcon>
                <ListItemText>
                    <Typography fontSize={{ xs: 12, md: 16 }}>
                        {transmission
                            .split('/')
                            .map(item => transmissionTypes[item])
                            .join(' / ')}
                    </Typography>
                </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, width: '50%' }}>
                <EquipmentItemBoxIcon>
                    <IconFuel />
                </EquipmentItemBoxIcon>
                <ListItemText>
                    <Typography fontSize={{ xs: 12, md: 16 }}>
                        {fuel
                            .split('/')
                            .map(item => fuelTypes[item])
                            .join(' / ')}
                    </Typography>
                </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0, width: '50%' }}>
                <EquipmentItemBoxIcon>
                    <IconLuggage />
                </EquipmentItemBoxIcon>
                <ListItemText>
                    <Typography fontSize={{ xs: 12, md: 16 }}>
                        {smallBags}{' '}
                        {smallBags === 1 && locale === 'pl' ? 'mała' : t('RAC.step_two.car_equipment.small_bags')}{' '}
                        {t('and')} {largeBags}{' '}
                        {largeBags === 1 && locale === 'pl' ? 'duża' : t('RAC.step_two.car_equipment.large_bags')}
                    </Typography>
                </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
                <EquipmentItemBoxIcon>
                    <IconCar />
                </EquipmentItemBoxIcon>
                <ListItemText>
                    <Typography fontSize={{ xs: 12, md: 16 }}>{t('RAC.step_two.no_limit')}</Typography>
                </ListItemText>
            </ListItem>
        </List>
    )
}

export default EquipmentList
