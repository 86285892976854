import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import theme from '../../../styles/MUI-theme'

const PricesPackages = ({ totalPrice, dayCount }) => {
    const { t } = useTranslation()

    return (
        <Box
            px={{ xs: 2, lg: 3 }}
            py={2}
            sx={{
                backgroundColor: '#f4f4f3',
                color: theme.palette.primary.contrastText,
                borderRadius: '0 0 20px 20px',
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontSize={{ xs: 12, lg: 13 }} fontWeight="bold" color="#626261" maxWidth="120px">
                    {t('RAC.step_three.total_cost')}
                </Typography>
                <Typography fontSize={{ xs: 16, lg: 20 }} fontWeight="bold" color="#626261" textAlign="right" pl={2}>
                    {totalPrice}
                    <Box component="span" sx={{ color: '#626261', fontWeight: 'bold', fontSize: { xs: 10, lg: 10 } }}>
                        {' '}
                        / {dayCount} {t('RAC.step_two.price.days')}
                    </Box>
                </Typography>
            </Box>
        </Box>
    )
}

export default PricesPackages
