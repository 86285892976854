import React from 'react'
import { createTheme } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'

const orange = '#f79226'
// const orange = '#f07d14'
const orangeDark = '#f08512'
const navy = '#003576'
const blue = '#5ec5ed'
const black = '#000000'
const dark = '#3d3d3d'
const grey = '#7a7a79'

let theme = createTheme()
theme = createTheme(theme, {
    palette: {
        mode: 'light',
        primary: {
            main: navy,
            light: blue,
            dark: dark,
            grey: grey,
        },
        secondary: {
            main: orange,
            dark: orangeDark,
        },
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: 14,
    },
    overrides: {
        // MuiInputBase: {
        //     root: {
        //         height: '48px',
        //     },
        // },
    },
    components: {
        MuiSelect: {
            defaultProps: {
                MenuProps: {
                    disableScrollLock: true,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#CBCBCB',
                    '&.Mui-checked': {
                        color: '#f79226',
                    },
                },
            },
            defaultProps: {
                icon: (
                    <span
                        style={{
                            width: 20,
                            height: 20,
                            border: '1px solid #CBCBCB',
                            borderRadius: 4,
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                ),
                checkedIcon: (
                    <span
                        style={{
                            width: 20,
                            height: 20,
                            border: '1px solid #CBCBCB',
                            borderRadius: 4,
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CheckIcon style={{ color: '#f79226', fontSize: 18 }} />
                    </span>
                ),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    color: orange,
                },
                h2: {
                    color: orange,
                },
                h3: {
                    color: orange,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: orange,
                        height: 3,
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: orange,
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal; 
        }
      `,
        },
    },
    // components: {
    //     MuiDatePicker: {
    //         defaultProps: {
    //             slotProps: {
    //                 inputAdornment: {
    //                     position: 'start',
    //                 },
    //             },
    //         },
    //     },
    // },
})

export default theme
