import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import { API, FORM_KEY } from '../../api'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import { formatPrice } from '../../hooks/useFormattedPrice'
import EditButton from '../../components/EditButton'
import IconCarPlus from '../../svg/IconCarPlus'
import ReservationDetailsBoxIcon from '../../../components/ReservationDetailsBoxIcon'


const ReservationDetailsExtras = ({ processData, activeStep }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const EXTRAS_PRICES = processData?.summary_options_eq_names_all_prices || ''

    const EXTRAS_PRICES_ARRAY = EXTRAS_PRICES.split(';')

    const formattedPricesAdditionalServices = EXTRAS_PRICES_ARRAY.map(
        price => formatPrice(parseFloat(price).toFixed(2)).replace('.', ',') || ''
    )

    const formattedPrice =
        useFormattedPrice(parseFloat(processData?.summary_options_eq_names_price).toFixed(2)).replace('.', ',') || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handleClickEditStep3 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step3']}`, 'GET')
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        processData?.summary_options_eq_names &&
        processData?.summary_options_eq_names_price !== '0.0' && (
            <Box width="100%" pl={{ xs: 1, lg: 2 }} pr={2} py={{ xs: 1, lg: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <>
                        <Box display="flex" alignItems="center">
                            <ReservationDetailsBoxIcon>
                                <IconCarPlus />
                            </ReservationDetailsBoxIcon>
                            <Box alignSelf="center">
                                <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                                    {t('RAC.step_three.additional_services')}
                                </Typography>
                                <pre>
                                    <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'normal'}>
                                        {locale === 'pl'
                                            ? processData?.summary_options_eq_names?.replace(/;/g, '\n') || ''
                                            : processData?.summary_options_eq_names_en?.replace(/;/g, '\n') || ''}
                                    </Typography>
                                </pre>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'right', minWidth: '100px' }}>
                            <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'} textAlign={'right'}>
                                {formattedPrice}
                            </Typography>
                            {formattedPricesAdditionalServices.map((formattedPrice, index) => (
                                <Typography
                                    key={`price-${index}`}
                                    fontSize={{ xs: 10, lg: 14 }}
                                    fontWeight={'normal'}
                                    textAlign={'right'}
                                >
                                    {formattedPrice}
                                </Typography>
                            ))}
                        </Box>
                    </>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    {activeStep !== 3 && <EditButton handleOnClick={handleClickEditStep3} isSubmitting={loading} />}
                </Box>
            </Box>
        )
    )
}

export default ReservationDetailsExtras
