import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import NumberSelector from '../../components/NumberSelector'
import Spinner from '../../components/Spinner'

const EquipmentItem = ({ withBorder, equipment, initialEquipmentValues, selectedEquipment, setSelectedEquipment }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()
    const { setFieldValue } = useFormikContext()

    const { id, name, nameEn, perDayPrice, unitPrice, min, max } = equipment

    const [isActive, setIsActive] = useState(false)
    const [value, setvalue] = useState(0)
    const [initialValue, setInitialValue] = useState(0)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (
            (initialEquipmentValues && initialEquipmentValues.length > 0) ||
            (selectedEquipment && selectedEquipment.length > 0)
        ) {
            const index = initialEquipmentValues.findIndex(obj => obj.id === equipment.id)

            index !== -1 && setInitialValue(initialEquipmentValues[index].count)

            index !== -1 && setvalue(initialEquipmentValues[index].count)

            setIsReady(true)

            countSelected()
        }
    }, [initialEquipmentValues])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        value > 0 ? setIsActive(true) : setIsActive(false)

        countSelected()
    }, [value])

    useEffect(() => {
        setFieldValue('equipment', selectedEquipment)
    }, [selectedEquipment])

    const countSelected = async () => {
        // Sprawdź, czy istnieje obiekt o podanym id
        const index = selectedEquipment.findIndex(obj => obj.id === equipment.id)

        if (index !== -1) {
            // Jeśli obiekt o podanym id istnieje, zaktualizuj jego wartość
            const newArray = [...selectedEquipment]
            selectedEquipment[index].count = value
            setSelectedEquipment(newArray)
        } else {
            // Jeśli obiekt o podanym id nie istnieje, dodaj nowy obiekt do tablicy
            setSelectedEquipment([...selectedEquipment, { id: equipment.id, count: value }])
        }
    }

    const handleUpdateValue = newValue => {
        setvalue(newValue)
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            width="100%"
            py={2} 
            px={{xs: 2, md: 4}}
            borderTop = {withBorder ? '1px solid #ccc' : 'none'}
        >
            <Box>
                <Typography fontSize={{ xs: 13, lg: 15 }} whiteSpace="pre-wrap">
                    {locale === 'pl' ? name.replace(/ - /g, '\n') : nameEn}
                </Typography>

                {perDayPrice ? (
                    <Typography fontSize={{ xs: 13, lg: 15 }}>
                        <b>{perDayPrice} zł</b> / {t('RAC.step_two.price.per_day')}
                    </Typography>
                ) : (
                    <Typography fontSize={{ xs: 13, lg: 15 }}>{/* <b>{unitPrice} zł</b> */}</Typography>
                )}
            </Box>
            {isReady ? (
                <NumberSelector initialValue={initialValue} min={min} max={max} handleClick={handleUpdateValue} isActive={isActive} />
            ) : (
                <Spinner size={20} />
            )}
        </Box>
    )
}

export default EquipmentItem
