import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconWallet({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M18.85,15.1c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S19.4,15.1,18.85,15.1z M18.85,14.1l-0.5,0L18.85,14.1
		C18.85,14.1,18.85,14.1,18.85,14.1z"
                />
                <path
                    d="M21.68,21.39H2.32C1.04,21.39,0,20.35,0,19.07V4.93c0-1.28,1.04-2.32,2.32-2.32h19.35c0.28,0,0.5,0.22,0.5,0.5
		s-0.22,0.5-0.5,0.5H2.32C1.59,3.61,1,4.2,1,4.93v14.14c0,0.73,0.59,1.32,1.32,1.32h19.35c0.73,0,1.32-0.59,1.32-1.32V8.44
		c0-0.73-0.59-1.32-1.32-1.32H4.72c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h16.96c1.28,0,2.32,1.04,2.32,2.32v10.63
		C24,20.35,22.96,21.39,21.68,21.39z"
                />
            </svg>
        </SvgIcon>
    )
}
