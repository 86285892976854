import React from 'react'
import Image from '../../components/Image'
import ColorBox from '../../components/ColorBox'
import { Typography } from '@mui/material'

const StepsItem = ({ block, counter, showCounter, secondVariant }) => {
    if (secondVariant) {
        return (
            <div className={'flex align-middle max-w-xs lg:max-w-none mx-auto px-5 py-4 gap-4'}>
                <div className="img-box w-[100px] xl:w-auto">
                    <Image className="w-auto" src={block['Gallery']['link']} alt="Item" />
                </div>

                <div className="flex flex-1 lg:mt-4 info-box text-left">
                    <div className="max-w-[180px] text-box">
                        <h3 className="text-left c-color3 text-4xl font-semibold lg:mb-2">{block['Headline']}</h3>
                        <Typography color="white">{block.Text}</Typography>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={'d-flex fd-column overflow-hidden max-w-xs lg:max-w-none mx-auto px-5 py-4 show-c-' + showCounter}>
            <div className="img-box">
                <Image className="h-[149px] w-auto" src={block['Gallery']['link']} alt="Item" />
            </div>

            <div className="flex flex-1 align-top items-stretch mt-3 lg:mt-4 info-box">
                <div className='line-box'>
                    <ColorBox width="100px" height="5px" className="my-3" />
                    {showCounter === true && <p className="text-2xl font-bold mt-8">{`0${counter}`}</p>}
                </div>
                <div className="mt-12 max-w-[180px] text-box">
                    <h4 className="text-md lg:text-2xl font-regular lg:mb-2">{block['Headline']}</h4>
                </div>
            </div>
        </div>
    )
}

export default StepsItem
