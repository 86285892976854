import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TargeoSearch from '../../components/TargeoSuggester/TargeoSearch'
import GetReleasePlaces from './GetReleasePlaces'
import LocationDetails from './LocationDetails'
import BackButton from '../../components/BackButton'
import { DialogContext } from './SearchField'
import { Typography } from '@mui/material'
import IconSearchResult from '../../components/IconSearchResult'
import theme from '../../../styles/MUI-theme'

const CustomTabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1} mb={2}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const TabsSearchLocation = () => {
    const [value, setValue] = useState(0)

    const [showMoreInfo, setShowMoreInfo] = useState(false)

    const [clickedPlace, setClickedPlace] = useState({})

    const { handleCloseDialog, setAutoHeight } = useContext(DialogContext)

    const { t } = useTranslation()

    useEffect(() => {
        setAutoHeight(false)
    }, [value])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClickBackButton = () => {
        showMoreInfo ? setShowMoreInfo(false) : handleCloseDialog()
    }

    const handleClickTab1 = () => {
        setValue(0)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <Box py={1} sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BackButton handleClick={handleClickBackButton} sx={{ marginRight: '10px' }} />
                {!showMoreInfo ? (
                    <>
                        <Tabs
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            sx={{
                                px: 0,
                                py: { xs: 1, lg: 2 },
                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                },
                                '&.Mui-selected': {
                                    color: theme.palette.primary.main,
                                    fontWeight: '500',
                                },
                            }}
                        >
                            <Tab
                                label={t('RAC.step_one.search_tab.0')}
                                {...a11yProps(0)}
                                sx={{
                                    mr: { xs: 1, lg: 2 },
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    borderRadius: '50px',
                                    color: theme.palette.primary.main,
                                    textTransform: 'none',
                                    fontSize: '15px',
                                    fontWeight: '700',
                                    '&.MuiTab-root.Mui-selected': {
                                        borderColor: theme.palette.secondary.main,
                                        backgroundColor: 'white',
                                        color: theme.palette.secondary.main,
                                        fontWeight: '700',
                                    },
                                    transition: 'border-color 0.2s, color 0.2s',
                                }}
                            />
                            <Tab
                                label={t('RAC.step_one.search_tab.1')}
                                {...a11yProps(1)}
                                sx={{
                                    mr: { xs: 1, lg: 2 },
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    borderRadius: '50px',
                                    color: theme.palette.primary.main,
                                    textTransform: 'none',
                                    fontSize: '15px',
                                    fontWeight: '700',
                                    '&.MuiTab-root.Mui-selected': {
                                        borderColor: theme.palette.secondary.main,
                                        backgroundColor: 'white',
                                        color: theme.palette.secondary.main,
                                        fontWeight: '700',
                                    },
                                    transition: 'border-color 0.2s, color 0.2s',
                                }}
                            />
                        </Tabs>
                    </>
                ) : (
                    <>
                        <IconSearchResult
                            hideBackground
                            icon={
                                clickedPlace.name.includes('PKS')
                                    ? 'Bus'
                                    : clickedPlace.name.includes('Prom')
                                      ? 'Ferry'
                                      : clickedPlace.type
                            }
                            my={2}
                        />
                        <Box>
                            <Typography fontWeight="700">{clickedPlace.name.split(',')[0]}</Typography>
                            <Typography>
                                {clickedPlace.name
                                    .split(',')[1]
                                    .replace(' - 24h', '')
                                    .replace(' - 24h', '')
                                    .replace(' 24h', '')
                                    .replace(' - 24 h', '')}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Box py={0} px={{ xs: 2, lg: 4 }}>
                <CustomTabPanel value={value} index={0}>
                    {!showMoreInfo ? (
                        <Box border="1px solid #cbcbcb" borderRadius="20px" px={2} py={{ xs: 2, lg: 4 }}>
                            <GetReleasePlaces setShowMoreInfo={setShowMoreInfo} setClickedPlace={setClickedPlace} />
                        </Box>
                    ) : (
                        <LocationDetails place={clickedPlace} handleClickBackButton={handleClickBackButton} />
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box border="1px solid #cbcbcb" borderRadius="20px" px={2} py={{ xs: 2, lg: 4 }}>
                        <TargeoSearch handleClickTab1={handleClickTab1} />
                    </Box>
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

export default TabsSearchLocation
