import React, {useState, useEffect} from 'react'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

import { useTranslation } from 'react-i18next'
import { Box, FormControl, Input, InputAdornment, MenuItem, Select, TextField, styled, Slider, FormControlLabel, Checkbox, FormGroup } from '@mui/material'
import { useFormContext } from '../../../forms/LAC/FormContext'
import StepperLac from '../../../forms/LAC/components/StepperLac'
import useTags from '../hooks/useTags';
// import { TAGS_NAMES_LAC } from '../../../constants/tagsLAC';

const color = '#f79226'
const CustomSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-track': {
        backgroundColor: 'white', //color of thumbs
        border: '1px solid #c7c7c7',
    },
    '& .MuiSlider-thumb': {
        backgroundColor: color, //color of thumbs
        height: '12px',
        width: '12px',
    },
    '& .MuiSlider-rail': {
        color: 'white', ////color of the slider outside  teh area between thumbs
        border: '1px solid #c7c7c7',
        opacity: '1',
    },
}))

export default function Filters(attr) {
    const { t } = useTranslation()
    const { tagsData } = useTags()
    const {
        allOffers,
        transmission,
        drive,
        transmissionLabel,
        driveLabel,
        handleChangeDrive,
        handleChangeTransmission,
        handleDetailsChange,
        details,
        handleChangeLocalization,
        localization,
        localizationLabel,
        brands,
        brandsSorted,
        models,
        fuelTypes,
        showUrl,
        filteredOffers,
        rangeValues,
        lowerValue,
        higherValue,
        isDailyCar,
        handleTypeChange,
        mark,
        setMark,
        seatsPlace,
        setSeatsPlace,
        seatsPlaces,
        setSeatsPlaces,
        carClass,
        setCarClass,
        carClasses,
        setCarClasses,
        model,
        engineType,
        isLoading,
        handleSliderChange,
        handleSliderChangeCommitted,
        tempRangeValues,
        handleLowerInputChange,
        handleHigherInputChange,
        handleBrandChange,
        handleChangeModel,
        handleChangeEngineType,
        handleSeatsPlaceChange,
        handleCarClassChange,
        params,
        minSliderPortlet,
        maxSliderPortlet,
        setDriveChecked,
        driveChecked,
        resetPriceFilter,
        filters,
        oldSliderValues,
        handleDriveChecked,
        handleSliderChangeMtrLtr,
        minMtr,
        maxMtr,
        chosenTagId,
        handleChangeTagId
    } = useFormContext()
    let Title = attr.params.block[attr.params.locale].hasOwnProperty('Title') ? attr.params.block[attr.params.locale]['Title'] : '',
        SubTitle = attr.params.block[attr.params.locale].hasOwnProperty('SubTitle') ? attr.params.block[attr.params.locale]['SubTitle'] : '',
        Type = attr.params.block[attr.params.locale].hasOwnProperty('ListType') ? attr.params.block[attr.params.locale]['ListType'] : 'lac';

        const [tagsToDisplay, setTagsToDisplay] = useState()
        
        useEffect(() => {
            // Zbierz wszystkie unikalne ID tagów
            const tagIdsToDisplay = allOffers.flatMap(obj => obj.tagIds).filter(id => id);
            const uniqueTagIdsToDisplay = [...new Set(tagIdsToDisplay)];
            
            // Filtracja tagów do wyświetlenia
            if(tagsData?.pl && (Object.keys(tagsData?.pl).length !== 0)) {
                setTagsToDisplay(
                    uniqueTagIdsToDisplay
                    // 'en'
                    // .map(id => ({ id, name: TAGS_NAMES_LAC[params.locale][id] }))
                    .map(id => ({ id, name: tagsData['pl'][id] }))
                    .filter(tag => tag.name)
                ) 
            }

        }, [allOffers, tagsData])

    
    
    return (
        <div className={'bg-[#f4f4f3] rounded-2xl py-10 px-8 mb-12 type-'+Type}>
            <h3 className="c-color2 font-bold text-left mb-2">{Title}</h3>
            <span>{SubTitle}</span>
            {(Type === 'lac' || Type === 'rac') && <StepperLac activeStep={1} filterView={true} />}
            <div className="mt-6 gap-x-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-[3fr,3fr] grid-rows-[2fr,1fr] sm:grid-rows-1">
                {Type === 'lac' ?
                <div className="flex flex-col gap-y-6 h-fit">
                    <span className="font-bold">{t('LAC.step_one.label_car_type')}</span>
                        <Box
                            sx={{
                                height: '48px',
                                borderRadius: '50px',
                                mb: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: {
                                    xs: 'wrap',
                                    sm: 'nowrap',
                                },
                                justifyContent: 'space-between',
                                gap: '1rem',
                            }}
                        >
                            <button
                                onClick={() => {
                                    handleTypeChange('All')
                                }}
                                className={`px-4 transition border rounded-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-full ${isDailyCar === 'All' ? 'text-orange-500 border-orange' : 'border-[#d1d1d1] c-color1'}`}
                            >
                                <DirectionsCarFilledOutlinedIcon sx={{ marginRight: '5px' }} />
                                {t('LAC.step_one.label_third_button')}
                            </button>
                            <button
                                onClick={() => {
                                    handleTypeChange('Car')
                                }}
                                className={`px-4 transition border rounded-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-full ${isDailyCar === 'Car' ? 'text-orange-500 border-orange' : 'border-[#d1d1d1] c-color1'}`}
                            >
                                <DirectionsCarFilledOutlinedIcon sx={{ marginRight: '5px' }} />
                                {t('LAC.step_one.label_first_button')}
                            </button>
                            <button
                                onClick={() => {
                                    handleTypeChange('Truck')
                                }}
                                className={`px-4 transition border rounded-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-full ${isDailyCar === 'Truck' ? 'text-orange-500 border-orange' : 'border-[#d1d1d1] c-color1'}`}
                            >
                                <LocalShippingOutlinedIcon sx={{ marginRight: '5px' }} />
                                {t('LAC.step_one.label_second_button')}
                            </button>
                            <button
                                onClick={e => {
                                    handleTypeChange('Electric')
                                }}
                                className={`px-4 transition border rounded-[50px] font-bold h-full cursor-pointer flex items-center justify-center w-full ${isDailyCar === 'Electric' ? 'text-[#14d106] border-[#14d106]' : 'border-[#d1d1d1] c-color1'}`}
                            >
                                <ElectricCarIcon sx={{ marginRight: '5px' }} />
                                {t('LAC.step_one.label_fourth_button')}
                            </button>
                        </Box>  
                    </div>
                : null}
                
                <div className="flex flex-col gap-y-6">
                    <span className="font-bold">{t('LAC.step_one.range_title')}</span>
                    <div className="flex items-center gap-4 flex-wrap sm:flex-nowrap">
                        <div className="flex w-full sm:hidden items-center">
                            {Type === 'lac' ? 
                                <CustomSlider
                                    value={tempRangeValues}
                                    key={`slider-${minSliderPortlet}`}
                                    onChange={handleSliderChange}
                                    onChangeCommitted={handleSliderChangeCommitted}
                                    valueLabelDisplay="auto"
                                    min={oldSliderValues[0] !== 0 ? oldSliderValues[0] : rangeValues[0]}
                                    max={oldSliderValues[1] !== 0 ? oldSliderValues[1] : rangeValues[1]}
                                    step={100}
                                /> : null
                            }
                            {(Type === 'ltr' || Type === 'staticltr' || Type === 'staticmtr') ? 
                                <CustomSlider
                                    value={rangeValues}
                                    onChange={handleSliderChangeMtrLtr}
                                    valueLabelDisplay="auto"
                                    min={minMtr}
                                    max={maxMtr}
                                    step={100}
                                /> : null
                            }
                        </div>
                        <Box
                            sx={{
                                height: '48px',
                                border: '1px solid #d1d1d1',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 20px',
                                gap: '15px',
                                justifyContent: 'space-between',
                                minWidth: '160px',
                            }}
                        >
                            <Input
                                disabled
                                disableUnderline
                                value={rangeValues[0]}
                                margin="dense"
                                // onChange={handleLowerInputChange}
                                inputProps={{
                                    step: 100,
                                    min: lowerValue,
                                    max: higherValue - 1,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        {t('LAC.step_one.label_price_from')}{' '}
                                    </InputAdornment>
                                }
                                endAdornment={<InputAdornment position="start"> zł</InputAdornment>}
                            />
                        </Box>
                        <div className="hidden w-full sm:flex items-center">
                        {Type === 'lac' ?
                            <CustomSlider
                                value={tempRangeValues}
                                key={`slider-${minSliderPortlet}`}
                                onChange={handleSliderChange}
                                onChangeCommitted={handleSliderChangeCommitted}
                                valueLabelDisplay="auto"
                                min={oldSliderValues[0] !== 0 ? oldSliderValues[0] : rangeValues[0]}
                                max={oldSliderValues[1] !== 0 ? oldSliderValues[1] : rangeValues[1]}
                                step={100}
                            /> : null }
                            {(Type === 'ltr' || Type === 'staticltr' || Type === 'staticmtr') ? 
                                <CustomSlider
                                    value={rangeValues}
                                    onChange={handleSliderChangeMtrLtr}
                                    valueLabelDisplay="auto"
                                    min={minMtr}
                                    max={maxMtr}
                                    step={100}
                                /> : null
                            }
                        </div>
                        <Box
                            sx={{
                                height: '48px',
                                border: '1px solid #d1d1d1',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 20px',
                                gap: '15px',
                                justifyContent: 'space-between',
                                minWidth: '160px',
                            }}
                        >
                            <Input
                                disabled
                                disableUnderline
                                value={rangeValues[1]}
                                margin="dense"
                                // onChange={handleHigherInputChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        {t('LAC.step_one.label_price_to')}{' '}
                                    </InputAdornment>
                                }
                                endAdornment={<InputAdornment position="start"> zł</InputAdornment>}
                                inputProps={{
                                    step: 100,
                                    min: lowerValue,
                                    max: higherValue - 1,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Box>
                    </div>
                </div>
            </div>
            <div className="mt-6 gap-4 grid grid-cols-[repeat(auto-fill,minmax(140px,1fr))] xl:grid-cols-[1fr,1fr,1fr,1fr,1fr,6fr,1fr]">
                {(Type === 'ltr' || Type === 'staticltr' || Type === 'staticmtr') &&
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.car_class')}</span>
                        <Box
                            sx={{
                                height: '48px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={carClass}
                                    onChange={e => {
                                        handleCarClassChange(e.target.value)
                                        setCarClass(e.target.value)
                                    }}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(carClasses).map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                }
                {(Type === 'ltr' || Type === 'staticltr' || Type === 'staticmtr') &&
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.seats_place')}</span>
                        <Box
                            sx={{
                                height: '48px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={seatsPlace}
                                    onChange={e => {
                                        handleSeatsPlaceChange(e.target.value)
                                        setSeatsPlace(e.target.value)
                                    }}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(seatsPlaces).map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                }
                {(Type === 'lac' || Type === 'rac') && 
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.label_select_brand')}</span>
                        <Box
                            sx={{
                                height: '48px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={mark}
                                    onChange={e => {
                                        handleBrandChange(e.target.value)
                                        setMark(e.target.value)
                                    }}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(brandsSorted).map(([id, name]) => (
                                        <MenuItem key={id.replace("id", "")} value={id.replace("id", "")}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                }
                {(Type === 'lac' || Type === 'rac') &&
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.label_select_model')}</span>
                        <Box
                            sx={{
                                height: '48px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={model}
                                    onChange={handleChangeModel}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {models.hasOwnProperty('pl') || models.hasOwnProperty('en')
                                        ? Object.entries(models['pl']).map(([id, name]) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                }  
                <div className="flex flex-col gap-y-6">
                    <span className="font-bold">{t('LAC.step_one.label_transmission')}</span>
                    <Box
                        sx={{
                            height: '48px',
                        }}
                    >
                        <FormControl fullWidth>
                            <Select
                                IconComponent={ExpandMoreIcon}
                                displayEmpty
                                value={transmissionLabel}
                                disableUnderline
                                onChange={handleChangeTransmission}
                                sx={{
                                    minWidth: '150px',
                                    borderRadius: '100px',
                                    height: '48px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#d1d1d1',
                                    },
                                }}
                            >
                                <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                {Object.entries(transmission).map(([id, name]) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className="flex flex-col gap-y-6">
                    <span className="font-bold">{t('LAC.step_one.label_select_fuel')}</span>
                    <Box
                        sx={{
                            height: '48px',
                        }}
                    >
                        <FormControl fullWidth>
                        {(Type === 'ltr' || Type === 'staticltr' || Type === 'staticmtr') ?
                            <Select
                                IconComponent={ExpandMoreIcon}
                                disableUnderline
                                displayEmpty
                                value={engineType}
                                onChange={handleChangeEngineType}
                                sx={{
                                    minWidth: '150px',
                                    borderRadius: '100px',
                                    height: '48px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#d1d1d1',
                                    },
                                }}
                            >
                                <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                <MenuItem value="PB">PB</MenuItem>
                                <MenuItem value="ON">ON</MenuItem>
                                <MenuItem value="HEV">HEV</MenuItem>
                                <MenuItem value="LPG">LPG</MenuItem>
                            </Select> :
                            <Select
                                IconComponent={ExpandMoreIcon}
                                disableUnderline
                                displayEmpty
                                value={engineType}
                                onChange={handleChangeEngineType}
                                sx={{
                                    minWidth: '150px',
                                    borderRadius: '100px',
                                    height: '48px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#d1d1d1',
                                    },
                                }}
                            >
                                <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                {Object.entries(fuelTypes).map(([id, name]) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select> 
                        }
                        </FormControl>
                    </Box>
                </div>
                {(Type === 'lac' || Type === 'rac') &&
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.label_drive')}</span>
                        <Box
                            sx={{
                                height: '48px',
                            }}
                            
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox disabled={!filters.is4x4Available} checked={driveChecked} onClick={() => handleDriveChecked()} />}
                                    label={<Box sx={{textWrap: "nowrap"}} fontSize={14}>{t('LAC.offer_list.only_4x4')}</Box>}
                                />
                            </FormGroup>
                            {/* <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={driveLabel}
                                    onChange={handleChangeDrive}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(drive).map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Box>
                    </div>
                }
                {(Type === 'lac' || Type === 'rac') &&
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.label_localization')}</span>
                        <Box
                            sx={{
                                height: '48px',
                                mb: '20px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={localizationLabel}
                                    onChange={handleChangeLocalization}
                                    disableUnderline
                                    sx={{
                                        minWidth: '150px',
                                        borderRadius: '100px',
                                        height: '48px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#d1d1d1',
                                        },
                                    }}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {localization?.map((item, id) => (
                                        <MenuItem key={id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                }
                <div className="flex flex-col gap-y-6">
                    {(Type !== 'ltr' && Type !== 'staticltr' && Type !== 'staticmtr') &&
                        <>
                            <span className="font-bold">{t('category')}</span>
                            <Box
                                sx={{
                                    height: '48px',
                                }}
                            >
                                <FormControl fullWidth>
                                
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        disableUnderline
                                        displayEmpty
                                        value={chosenTagId}
                                        onChange={handleChangeTagId}
                                        sx={{
                                            minWidth: '150px',
                                            borderRadius: '100px',
                                            height: '48px',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#d1d1d1',
                                            },
                                        }}
                                    >
                                        <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                        {tagsToDisplay?.map(tag => (
                                            <MenuItem key={tag.id} value={tag.id}>
                                                {tag.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                
                                </FormControl>
                            </Box>
                        </>
                    }
                </div>
                {false && 
                    <div className="flex flex-col gap-y-6">
                        <span className="font-bold">{t('LAC.step_one.label_details')}</span>
                        <Box
                            sx={{
                                height: '48px',
                                border: '1px solid #d1d1d1',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 20px',
                                mb: '20px',
                                gap: '15px',
                                minWidth: {
                                    xl: '300px',
                                    lg: 'none',
                                },
                            }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    placeholder={t('LAC.step_one.details_placeholder')}
                                    id="standard-basic"
                                    variant="standard"
                                    value={details}
                                    onChange={handleDetailsChange}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </FormControl>
                        </Box>
                    </div>
                }
            </div>
        </div>
    )
}
