import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { environment } from '../../environment'
import Image from '../../components/Image'
import Links from '../../components/Links'

export default function TextTabs(params) {
    const [activeTab, setActiveTab] = useState(0)
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TextTabs')
        console.log(params)
    }
    const { t, i18n } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(params.locale)
    }, [params.locale])

    function activeLink(link) {
        let status = false
        let url = window.location.pathname.includes('index.html')
            ? window.location.pathname.replace('index.html', '')
            : window.location.pathname;
        if (link.link.replace('/export/sites/express','') === url) {
            status = true
        }
        return status
    }
    function ButtonLink(param) {
        if (param.item?.TabLink && param.item.TabLink !== '' && param.item.TabLink?.path !== '') {
            return ( 
                <Links
                    key={param.index}
                    line={params.line}
                    url={param.item.TabLink}
                    target={param.item.InNewOpen}
                    class={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${activeLink(param.item.TabLink) ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} `}
                    name={param.item.TabTitle}
                />      
            )
        }
        else { 
            return (
                <button
                    key={param.index}
                    onClick={() => {
                        setActiveTab(param.index)
                    }}
                    type="button"
                    className={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${activeTab === param.index ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} `}
                >
                    {param.item?.TabTitle ? param.item.TabTitle : null}
                </button>
            )
        }
    }

    return (
        <section className={'pickup-box'}>
            <div className="wrapper">
                <div className="relative">
                    {params.block[params.locale].Image && (
                        <Image
                            className="w-auto mx-auto object-cover min-h-[360px]"
                            src={params.block[params.locale].Image}
                            alt="Express Car Rental"
                        />
                    )}
                    {params.block[params.locale]['Title'] && (
                        <h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white m-0">
                            {params.block[params.locale]['Title']}
                        </h3>
                    )}
                </div> 
                    <div>
                        {params.block[params.locale]?.Item &&
                            params.block[params.locale]?.Item.map((item, index) => (
                                <div key={index}>
                                    {activeTab === index && (
                                         
                                           
                                                <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-12 mb-6 mt-2">
                                                    {item.Item.map((image, ind) => (
                                                        <>
                                                            <div key={`image-${ind}`} className='px-3'>
                                                                {image?.Url ? (
                                                                    <a href={image.Url} target="_blank" rel="noreferrer" >
                                                                        <Image 
                                                                            className='max-h-[100px]'
                                                                            src={image.Image}
                                                                            alt="Express Car Rental"
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    <Image 
                                                                            className='max-h-[100px]'
                                                                            src={image.Image}
                                                                            alt="Express Car Rental"
                                                                        />
                                                                )}
                                                            </div>
                                                            {ind === 2 && (
                                                                <>
                                                                    <div className="hidden md:block bg-[#000000] w-[2px] h-[50px] mx-4"></div>
                                                                    <div className="block md:hidden bg-[#000000] w-[200px] h-[2px] my-4"></div>
                                                                </>
                                                            )}
                                                        </>
                                                        
                                                    ))}
                                                </div>
                                            
                                       
                                    )}
                                </div>
                            ))}
                    </div> 
                <div className={'container mx-auto px-8 my-12'}>
                    <div className="flex flex-wrap justify-center gap-4">
                        {params.block[params.locale]?.Item &&
                            params.block[params.locale]?.Item.map((item, index) => (
                                <ButtonLink item={item} index={index} key={index}/>
                            ))}
                    </div>
                    <div>
                        {params.block[params.locale]?.Item &&
                            params.block[params.locale]?.Item.map((item, index) => (
                                <div key={index}>
                                    {activeTab === index && (
                                        <div>
                                            {item?.TextTabsTitle ? (
                                                <h3 className="c-color1 my-12">{item.TextTabsTitle}</h3>
                                            ) : null}

                                            {/* {item?.Item.length > 0 && (
                                                <div className="flex flex-wrap justify-center items-center gap-12 mb-12">
                                                    {item.Item.map((image, ind) => (
                                                        <div key={index}>
                                                            {image?.Url ? (
                                                                <a href={image.Url}>
                                                                    <Image
                                                                        className="max-h-[50px]"
                                                                        src={image.Image}
                                                                        alt="Express Car Rental"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )} */}

                                            {item?.TextTabsText ? (
                                                <div className='ul-custom' dangerouslySetInnerHTML={{ __html: item.TextTabsText }}></div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
