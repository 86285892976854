import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconFixedFee({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        class="st0"
                        d="M23.5,22.49H3.74c-0.28,0-0.5-0.22-0.5-0.5V10.05c0-0.28,0.22-0.5,0.5-0.5H23.5c0.28,0,0.5,0.22,0.5,0.5
			v11.95C24,22.27,23.78,22.49,23.5,22.49z M4.24,21.49H23V10.55H4.24V21.49z"
                    />
                    <path
                        class="st0"
                        d="M13.62,18.99c-1.64,0-2.97-1.33-2.97-2.97s1.33-2.97,2.97-2.97s2.97,1.33,2.97,2.97S15.26,18.99,13.62,18.99z
			 M13.62,14.05c-1.09,0-1.97,0.88-1.97,1.97s0.88,1.97,1.97,1.97s1.97-0.89,1.97-1.97S14.71,14.05,13.62,14.05z"
                    />
                </g>
                <g>
                    <path
                        class="st0"
                        d="M3.59,19.16c-0.22,0-0.42-0.15-0.48-0.37L0.02,7.25C-0.02,7.12,0,6.98,0.07,6.87s0.18-0.2,0.3-0.23
			l19.09-5.11c0.13-0.03,0.26-0.02,0.38,0.05s0.2,0.18,0.23,0.3l2.15,8.04c0.07,0.27-0.09,0.54-0.35,0.61
			c-0.27,0.07-0.54-0.09-0.61-0.35l-2.02-7.56L1.11,7.47l2.96,11.06c0.07,0.27-0.09,0.54-0.35,0.61
			C3.68,19.16,3.64,19.16,3.59,19.16z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
