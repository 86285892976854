import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Spinner from './Spinner'

const EditButton = ({ handleOnClick, isSubmitting, ...props }) => {
    const { t } = useTranslation()

    return isSubmitting ? (
        <Spinner size={20} sx={{ px: 0, pb: 0, pt: 0.5, m: 0 }} />
    ) : (
        <Button
            type="submit"
            mt={props.mt || 1}
            sx={{ paddingRight: 0, fontSize: { xs: 12, md: 16 }, fontWeight: '500', textTransform: 'none' }}
            onClick={handleOnClick}
            {...props}
        >
            {t('btn.edit')} <NavigateNextIcon />
        </Button>
    )
}
export default EditButton
