import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { API } from '../api'
import { Alert, Box, Typography } from '@mui/material'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import theme from '../../styles/MUI-theme'
import useFetch from '../hooks/useFetch'
import useFormattedDate from '../hooks/useFormattedDate'
import useFormattedPrice from '../hooks/useFormattedPrice'
import ReservationDetialsLocation from './Step2/ReservationDetailsLocation'
import Spinner from '../components/Spinner'
import { environment } from '../../environment'
import ErrorBox from './ErrorBox'
import ReservationDetailsCar from './Step3/ReservationDetailsCar'
import ReservationDetailsFixedFeePrice from './Step3/ReservationDetailsFixedFeePrice'
import ReservationDetailsPackage from './Step4/ReservationDetailsPackage'
import ReservationDetailsExtras from './Step4/ReservationDetailsExtras'
import ReservationDetailsPriceRelocation from './Step4/ReservationDetailsPriceRelocation'

const ReservationDetials = ({ activeStep }) => {
    const { t } = useTranslation()
    const { data, loading, error, fetchData } = useFetch()
    const { id } = useParams()

    const formattedPickUpDate = useFormattedDate(data?.variables.pickup_date)
    const formattedReturnDate = useFormattedDate(data?.variables.return_date)

    const formmattedPickupPrice = useFormattedPrice(data?.variables.pickup_location_price)
    const formmattedReturnPrice = useFormattedPrice(data?.variables.return_location_price)

    const formmattedPickupPriceFullCalc =
        useFormattedPrice(data?.variables.full_calculation_price_transport_pickup) || '0 zł'

    const formmattedReturnPriceFullCalc =
        useFormattedPrice(data?.variables.full_calculation_price_transport_dropoff) || '0 zł'

    const formmattedFullCalculationPrice = useFormattedPrice(data?.variables.full_calculation_price)

    const formattedInitPrice = useFormattedPrice(
        (
            parseFloat(data?.variables?.pickup_location_price || 0) +
            parseFloat(data?.variables?.return_location_price || 0)
        )
            .toFixed(2)
            .toString()
    )

    useEffect(() => {
        const url = `${API[`GET_DATA`]}?pid=${id}`
        fetchData(url, 'GET')
    }, [id])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('DATA: ', data)
    }, [data])

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <>
            <Box
                mb={activeStep === 2 ? 2 : 4}
                borderRadius={activeStep === 2 ? '20px' : '20px 20px 0 0'}
                bgcolor="#f4f4f4"
            >
                {(activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5) && (
                    <Box borderBottom="none" overflow="hidden" mb="-1px">
                        <ReservationDetialsLocation
                            isExpress={data?.variables.pickup_location_express_place === 'yes'}
                            icon={data?.variables.pickup_location_type || 'Location'}
                            address={data?.variables.pickup_location_address}
                            pickupDate={formattedPickUpDate}
                            priceInitial={formmattedPickupPrice || '0 zł'}
                            additionalInfo={t('pickup')}
                            activeStep={activeStep}
                            priceFullCalc={formmattedPickupPriceFullCalc}
                            locationName={data?.variables.pickup_location_name}
                            idDropOffLocation={false}
                        />
                        <ReservationDetialsLocation
                            isExpress={data?.variables.pickup_location_express_place === 'yes'}
                            icon={data?.variables.return_location_type || 'Location'}
                            address={data?.variables.return_location_address}
                            returnDate={formattedReturnDate}
                            priceInitial={formmattedReturnPrice || '0 zł'}
                            additionalInfo={t('dropoff')}
                            activeStep={activeStep}
                            priceFullCalc={formmattedReturnPriceFullCalc}
                            locationName={data?.variables.return_location_name}
                            idDropOffLocation={true}
                        />
                    </Box>
                )}
                {(activeStep === 3 || activeStep === 4 || activeStep === 5) && (
                    <Box borderBottom="none" overflow="hidden" mb="-1px">
                        <>
                            {data?.variables?.full_calculation_price_transport_relocation &&
                                data?.variables?.full_calculation_price_transport_relocation !== '0.0' && (
                                    <Box>
                                        <ReservationDetailsPriceRelocation processData={data?.variables} />
                                    </Box>
                                )}
                        </>
                        <Box>
                            <ReservationDetailsCar processData={data?.variables} />
                        </Box>
                        <Box>
                            <ReservationDetailsFixedFeePrice processData={data?.variables} />
                        </Box>
                    </Box>
                )}
                {(activeStep === 4 ||
                    activeStep === 5 ||
                    (activeStep === 3 &&
                        data?.variables?.offer_selected_package &&
                        data?.variables?.extras_packages_count)) && (
                    <>
                        <Box borderBottom="none" overflow="hidden" mb="-1px">
                            <Box>
                                <ReservationDetailsPackage
                                    packagePrice={
                                        data?.variables.offer_selected_package === 'silver'
                                            ? data?.variables.silver_package_price
                                            : ''
                                    }
                                    packageDepositPrice={data?.variables.full_calculation_deposit || ''}
                                    packageIsSilver={data?.variables.offer_selected_package === 'silver'}
                                    activeStep={activeStep}
                                />
                            </Box>
                            <Box>
                                <ReservationDetailsExtras processData={data?.variables} activeStep={activeStep} />
                            </Box>
                        </Box>
                    </>
                )}

                {activeStep > 2 && (
                    <Box
                        width={'100%'}
                        px={{ xs: 2, lg: 4 }}
                        py={{ xs: 2, lg: 3 }}
                        bgcolor={theme.palette.secondary.dark}
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        borderRadius={'0 0 20px 20px'}
                    >
                        <Typography fontSize={{ xs: 14, lg: 16 }} fontWeight={'700'} color={'white'}>
                            {t('RAC.step_two.total_rental_costs')}
                        </Typography>

                        <Typography
                            fontSize={{ xs: 14, lg: 22 }}
                            fontWeight={'700'}
                            textAlign={'right'}
                            color={'white'}
                        >
                            {formmattedFullCalculationPrice}
                        </Typography>
                    </Box>
                )}
            </Box>
            {activeStep === 2 && (
                <Alert
                    severity="info"
                    sx={{ display: { md: 'none' }, mb: 2, fontSize: 12, alignItems: 'center' }}
                    icon={<LocalOfferOutlinedIcon />}
                >
                    {t('RAC.step_two.additional_price_message')}
                </Alert>
            )}
        </>
    )
}

export default ReservationDetials
