import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconMoneyBill({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        class="st0"
                        d="M2.6,12.5c-0.28,0-0.5-0.22-0.5-0.5V5.66c0-0.69,0.27-1.33,0.75-1.82c0.48-0.48,1.13-0.75,1.82-0.75H21.4
			c0.28,0,0.5,0.22,0.5,0.5s-0.22,0.5-0.5,0.5H4.67c-0.42,0-0.81,0.16-1.11,0.46C3.27,4.85,3.1,5.24,3.1,5.66V12
			C3.1,12.28,2.88,12.5,2.6,12.5z"
                    />
                    <path
                        class="st0"
                        d="M19.33,20.91H2.6c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h16.72c0.42,0,0.81-0.16,1.11-0.46
			s0.46-0.69,0.46-1.11V12c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v6.34c0,0.69-0.27,1.33-0.75,1.82
			C20.66,20.64,20.01,20.91,19.33,20.91z"
                    />
                    <path
                        class="st0"
                        d="M2.6,12.5c-0.13,0-0.26-0.05-0.35-0.15l-2.1-2.1c-0.2-0.2-0.2-0.51,0-0.71s0.51-0.2,0.71,0l1.75,1.75
			l1.75-1.75c0.2-0.2,0.51-0.2,0.71,0s0.2,0.51,0,0.71l-2.1,2.1C2.86,12.45,2.73,12.5,2.6,12.5z"
                    />
                    <path
                        class="st0"
                        d="M23.5,14.6c-0.13,0-0.26-0.05-0.35-0.15l-1.75-1.75l-1.75,1.75c-0.2,0.2-0.51,0.2-0.71,0s-0.2-0.51,0-0.71
			l2.1-2.1c0.2-0.2,0.51-0.2,0.71,0l2.1,2.1c0.2,0.2,0.2,0.51,0,0.71C23.76,14.56,23.63,14.6,23.5,14.6z"
                    />
                </g>
                <g>
                    <path
                        class="st0"
                        d="M17,15.52H7c-0.28,0-0.5-0.22-0.5-0.5V8.98c0-0.28,0.22-0.5,0.5-0.5H17c0.28,0,0.5,0.22,0.5,0.5v6.04
			C17.5,15.3,17.27,15.52,17,15.52z M7.5,14.52h8.99V9.48H7.5V14.52z"
                    />
                    <path
                        class="st0"
                        d="M12,13.75c-0.97,0-1.75-0.79-1.75-1.75s0.79-1.75,1.75-1.75s1.75,0.79,1.75,1.75S12.97,13.75,12,13.75z
			 M12,11.25c-0.41,0-0.75,0.34-0.75,0.75s0.34,0.75,0.75,0.75s0.75-0.34,0.75-0.75S12.41,11.25,12,11.25z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
