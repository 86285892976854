import React, { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const NumberSelector = ({ initialValue, min, max, isActive, handleClick }) => {
    const [value, setValue] = useState(initialValue || min)

    const handleIncrement = () => {
        const newValue = value + 1
        if (value < max) {
            setValue(newValue)
            handleClick(newValue)
        }
    }

    const handleDecrement = () => {
        const newValue = value - 1
        if (value > min) {
            setValue(newValue)
            handleClick(newValue)
        }
    }

    return min === 0 && max === 1 ? (
        <Box
            width={{xs: "24px", md: "48px"}}
            minWidth={{xs: "24px", md: "48px"}}
            height={{xs: "22px", md: "44px"}}
            borderRadius="4px" 
            border={ isActive ? '1px solid #f79226' : '1px solid #ccc'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => (value === 1 ? handleDecrement() : handleIncrement())}
        >
            <CheckIcon
                sx={{ transition: 'all 0.3s' }}
                color={value === 1 ? 'secondary' : 'primary'}
                opacity={value === 1 ? 1 : 0}
                fontSize='small'
            />
        </Box>
    ) : (
        <Box
            maxWidth="120px"
            height="48px"
            borderRadius="50px"
            border={ isActive ? '1px solid #f79226' : '1px solid #ccc'}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <IconButton color="primary" aria-label="minus" onClick={handleDecrement} sx={{ backgroundColor: 'white'}}>
                <KeyboardArrowDownOutlinedIcon />
            </IconButton>
            <Box width="48px" height="44px" display="flex" alignItems="center" justifyContent="center" color={value > 0 ? '#f79226' : 'primary'}>
                {value}
            </Box>
            <IconButton color="primary" aria-label="plus" onClick={handleIncrement} sx={{ backgroundColor: 'white'}}>
                <KeyboardArrowUpOutlinedIcon />
            </IconButton>
        </Box>
    )
}

export default NumberSelector
