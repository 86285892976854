import React from 'react' 
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import PhoneInput from 'react-phone-input-2'
import pl from '../constants/phone-input-pl.json' 

// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import '../styles/phoneInput.css'

const MuiPhoneInput = ({ name, required, disabled, helperText, error, locale = 'pl', hideFlag = false, ...props }) => {
    const { t } = useTranslation() 
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name) 

    const lang = (locale === 'en' ? 'gb' : 'pl')  

    const handleChangeInputValue = (value, country) => {
        if (props?.withPlus) {
            if(!value.startsWith('+')) value = `+${value}`
        }

        // if(props?.withoutPrefix) {
        //     value.replace(`+${country.dialCode}`, '')
        // }

        props?.RAC && props?.setValue(value)
        props?.LAC && props?.handleUserDataChange(name, value)

        setFieldValue(name, value)
    }

    return (
        <>
            <PhoneInput
                {...field}
                country={lang || null}
                preferredCountries={['pl', 'gb', 'ua']}
                localization={lang === 'pl' && pl}
                countryCodeEditable={hideFlag ? true : false} // jak false to nie zmienia flagi przy wklejaniu numeru
                // copyNumbersOnly={true}
                // enableSearch={true}
                placeholder={t('RAC.step_five.contact_details.phone_number')}
                specialLabel={t('RAC.step_five.contact_details.phone_number')}
                value={field.value}
                onChange={handleChangeInputValue}
                inputProps={{
                    name: field.name,
                    required: required,
                    disabled: disabled,
                    autoFocus: false,
                }} 
                className={`${meta.touched && meta.error && 'error'} ${props?.disabled || hideFlag ? 'hide-flag' : ''}`}
                {...props}
            />

            {meta.touched && meta.error && <div className="error ml-4 mt-1">{meta.error}</div>}
        </>
    )
}

export default MuiPhoneInput
