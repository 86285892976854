import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconShield({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M12,24c-0.09,0-0.17-0.02-0.25-0.07C9,22.34,6.69,20.05,5.08,17.32c-1.66-2.82-2.54-6.05-2.54-9.34V3.44
	c0-0.22,0.14-0.41,0.34-0.48l8.97-2.94c0.1-0.03,0.21-0.03,0.31,0l8.97,2.94c0.21,0.07,0.34,0.26,0.34,0.48v4.54
	c0,3.29-0.88,6.52-2.54,9.34c-1.61,2.73-3.92,5.02-6.67,6.61C12.17,23.98,12.09,24,12,24z M3.53,3.81v4.18
	c0,3.11,0.83,6.16,2.41,8.83c1.48,2.5,3.57,4.61,6.06,6.11c2.49-1.5,4.58-3.61,6.06-6.11c1.57-2.66,2.41-5.72,2.41-8.83V3.81
	L12,1.03L3.53,3.81z"
                />
            </svg>
        </SvgIcon>
    )
}
