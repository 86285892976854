import React, { useEffect, useState, useCallback } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { IconButton, Typography, Box, Button, FormControlLabel, Checkbox, Divider, Alert } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import * as Yup from 'yup'

import DialogWindow from '../forms/components/DialogWindow'
import useFetchLac from '../forms/hooks/useFetchLac'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import validationSchema from './validationSchema/validationSchema'
import { API } from '../forms/LAC/api'
import FormInd from './PopupContactForms/FormInd'
import FormBusiness from './PopupContactForms/FormBusiness'
import AdditionalOptions from './PopupContactForms/AdditionalOptions'
import Spinner from '../forms/components/Spinner'
import { Data } from '@react-google-maps/api'
import axios from 'axios'
import LabelText from './PopupContactForms/LabelText'
import { environment } from '../environment'

const PopupContact = ({ showAlert, setShowAlert, dialogTitle, idOffer, locale, noPopup = false, buttonId }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { t, i18n } = useTranslation()
    const [clientType, setClientType] = useState(noPopup ? 'ind' : 'business')
    const locale2 = window.location.href.includes('/en/') ? 'en' : 'pl'
    const [postStatus, setPostStatus] = useState('')
    const [postError, setPostError] = useState(false)
    const [isPostLoading, setIsPostLoading] = useState(false)
    const [data, setData] = useState(null)
    const [isActiveInsurance, setIsActiveInsurance] = useState(false)
   
    const [recaptchaToken, setRecaptchaToken] = useState() 
    
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        } 
        const token = await executeRecaptcha('yourAction') 
        setRecaptchaToken(token)
    }, [executeRecaptcha, setRecaptchaToken])
 
    useEffect(() => { 
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify]) 

    // const { data, loading, error, fetchData } = useFetchLac()

    const translatedValidationSchema = validationSchema(t, isActiveInsurance)
    const validationSchema1 = Yup.object().shape({
        userFirstName: Yup.string()
            .required(t('LAC.client_data.validation.name_1'))
            .min(2, t('LAC.client_data.validation.name_2'))
            .max(80, t('LAC.client_data.validation.name_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),

        userLastName: Yup.string()
            .required(t('LAC.client_data.validation.surname_1'))
            .min(2, t('LAC.client_data.validation.surname_2'))
            .max(80, t('LAC.client_data.validation.surname_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.surname_2')),
        userEmail: Yup.string()
            .email(t('LAC.client_data.validation.email'))
            .required(t('LAC.client_data.validation.email')),
        userPhoneNumber: Yup.string()
            .matches(/^(?=(?:.*\d){11,})^[\d+()\- ]{11,17}$/, t('LAC.client_data.validation.phone_3'))
            .required(t('LAC.client_data.validation.phone_2')),
    })

    const [userData, setUserData] = useState({
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userPhoneNumber: '',
        userNip: '',
        companyName: '',
        userAllowedTerms: false,
        userTelemarketing: false,
        MailSMSOffers: false,
        userWithoutProfiling: false,
        userCheckedConsentMarketingProcessingPersonalData: false,
        insurance: 'none',
        fuelCard: '',
        gps: '',
        clientType: 'business',
    })

    const initialValues = {
        userFirstName: userData.userFirstName || '',
        userLastName: userData.userLastName || '',
        userEmail: userData.userEmail || '',
        userPhoneNumber: userData.userPhoneNumber || '',
        userNip: userData.userNip || '',
        companyName: userData.companyName || '',
        userAllowedTerms: userData.userAllowedTerms || false,
        userTelemarketing: userData.userTelemarketing || false,
        MailSMSOffers: userData.MailSMSOffers || false,
        userWithoutProfiling: userData.userWithoutProfiling || false,
        userCheckedConsentMarketingProcessingPersonalData: userData.userCheckedConsentMarketingProcessingPersonalData || false,
        insurance: userData.insurance || 'none',
        fuelCard: userData.fuelCard || '',
        gps: userData.gps || '',
        clientType: userData.clientType || 'business',
    }

    function resetData() {
        handleUserDataChange('userFirstName', '')
        handleUserDataChange('userLastName', '')
        handleUserDataChange('userEmail', '')
        handleUserDataChange('userPhoneNumber', '')
        handleUserDataChange('userNip', '')
        handleUserDataChange('companyName', '')
        handleUserDataChange('userAllowedTerms', false)
        handleUserDataChange('userTelemarketing', false)
        handleUserDataChange('MailSMSOffers', false)
        handleUserDataChange('userWithoutProfiling', false)
        handleUserDataChange('userCheckedConsentMarketingProcessingPersonalData', false)
        handleUserDataChange('insurance', 'none')
        handleUserDataChange('fuelCard', '')
        handleUserDataChange('gps', '')
    }

    const handleUserDataChange = (name, value, id = null) => {
        setUserData(prevState => {
            if (id) {
                if (value) {
                    return {
                        ...prevState,
                        [name]: id,
                    }
                } else {
                    return {
                        ...prevState,
                        [name]: '',
                    }
                }
            } else {
                return {
                    ...prevState,
                    [name]: value,
                }
            }
        })
    }

    async function postData(data) {
        try {
            setIsPostLoading(true)
            const response = await axios.post(`${API['POST_CONTACT_FORM']}`, data)
            if (response.data) {
                setPostStatus(response.data)
                setIsPostLoading(false)
                if (response.data.message === 'success') {
                    // resetData()
                }
            }
        } catch (error) {
            console.error('Error:', error)
            setPostError(true)
        }
    }

    const handleSubmit = async values => {

        if (!recaptchaToken) return

        let data
        let additionalOptionsIds = []

        if (values.insurance !== '' && values.insurance !== 'none') {
            additionalOptionsIds.push(values.insurance)
        }

        if (values.fuelCard !== '') {
            additionalOptionsIds.push(values.fuelCard)
        }

        if (values.gps !== '') {
            additionalOptionsIds.push(values.gps)
        }

        if (clientType === 'business') {
            data = {
                gRecaptchaResponse: recaptchaToken,
                offerId: idOffer,
                carsCount: '1',
                clientData: {
                    email: values.userEmail,
                    phoneNo: values.userPhoneNumber,
                    // consents: {
                        // profiling: true,
                    // },
                },
                companyData: {
                    companyName: values.companyName,
                    nip: values.userNip,
                    contactLanguage: locale2,
                },
                additionalOptionsIds: additionalOptionsIds,
                clientType: 'business',
            }
        }

        if (clientType === 'ind') {
            if (noPopup) {
                data = {
                    gRecaptchaResponse: recaptchaToken,
                    offerId: idOffer,
                    carsCount: '1',
                    clientData: {
                        firstName: values.userFirstName,
                        lastName: values.userLastName,
                        email: values.userEmail,
                        phoneNo: values.userPhoneNumber,
                        contactLanguage: locale2,
                        consents: {
                            profiling: false,
                        },
                    },
                    additionalOptionsIds: [],
                    clientType: 'business',
                }
            } else {
                data = {
                    gRecaptchaResponse: recaptchaToken,
                    offerId: idOffer,
                    carsCount: '1',
                    clientData: {
                        firstName: values.userFirstName,
                        lastName: values.userLastName,
                        email: values.userEmail,
                        phoneNo: values.userPhoneNumber,
                        contactLanguage: locale2,
                        consents: {
                            // profiling: true,
                            ...(values.userWithoutProfiling && { withoutProfiling: true }),
                            ...(values.userTelemarketing && { telemarketing: true }),
                            ...(values.MailSMSOffers && { MailSMSOffers: true }),
                        },
                    },
                    additionalOptionsIds: additionalOptionsIds,
                    clientType: 'ind',
                }
            }
        }

        // await fetchData(`${API['POST_CONTACT_FORM']}`, 'POST', data, locale2)
        postData(data)
    }

    useEffect(() => {
        const url = `${API[`GET_OFFER_ADDITIONAL_OPTIONS`]}`
        fetch(url).then(res => res.json()).then(response => {
            setData(response)
        })
        // fetchData(url, 'GET')
    }, [])

    if (noPopup) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={environment.REACT_APP_RECAPTCHA}>
                <h2 className="text-3xl c-color3 text-left mb-12">{dialogTitle}</h2>

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema1}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <FormInd
                                valuealues={values}
                                userData={userData}
                                touched={touched}
                                errors={errors}
                                handleUserDataChange={handleUserDataChange}
                                noPopup={noPopup}
                            />
                            <div className='flex overflow-hidden mt-4 -ml-2.5'>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Checkbox 
                                        required
                                    />
                                </Box>
                                <Box>
                                    <LabelText />
                                </Box>
                            </div>
                            {postStatus?.message === 'success' ? (
                                <Alert sx={{ marginTop: '12px' }} severity="success">
                                    {t("LAC.popup_contact.success")}
                                </Alert>
                            ) : null}
                            {postStatus?.message === 'error' ? (
                                <Alert sx={{ marginTop: '12px' }} severity="error">
                                    {t("LAC.popup_contact.error")}
                                </Alert>
                            ) : null}
                            {postStatus?.message === 'success' ? null : (
                                <div className="flex justify-center mt-6">
                                    <button type="submit" className="btn-link" id={buttonId} disabled={isPostLoading}>
                                        {/* {t('btn.back')} */}
                                        {isPostLoading ? t("LAC.popup_contact.sending_button") : t("LAC.popup_contact.send_button")}
                                    </button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </GoogleReCaptchaProvider>
        )
    }
    return (
        <DialogWindow isOpen={showAlert} setIsOpen={setShowAlert} dialogTitle={dialogTitle}>
            <IconButton
                aria-label="close"
                onClick={() => setShowAlert(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <Typography variant="h3" mb={4}>
                {dialogTitle}
            </Typography>
            <GoogleReCaptchaProvider reCaptchaKey={environment.REACT_APP_RECAPTCHA}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={translatedValidationSchema}
            >
                {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="flex justify-center gap-4 flex-wrap">
                            <button
                                onClick={() => {
                                    setClientType('business')
                                    handleUserDataChange('clientType', 'business')
                                }}
                                type="button"
                                className={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${clientType === 'business' ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} `}
                            >
                                {t('LAC.client_data.button_1')}
                            </button>
                            <button
                                onClick={() => {
                                    setClientType('ind')
                                    handleUserDataChange('clientType', 'ind')
                                }}
                                type="button"
                                className={`px-5 rounded-full py-3 transition border font-semibold cursor-pointer flex items-center justify-center ${clientType === 'ind' ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} `}
                            >
                                {t('LAC.client_data.button_2')}
                            </button>
                        </div>
                        {clientType === 'ind' ? (
                            <FormInd
                                valuealues={values}
                                userData={userData}
                                touched={touched}
                                errors={errors}
                                handleUserDataChange={handleUserDataChange}
                            />
                        ) : (
                            <FormBusiness
                                valuealues={values}
                                userData={userData}
                                touched={touched}
                                errors={errors}
                                handleUserDataChange={handleUserDataChange}
                            />
                        )}
                        {data ? (
                            <AdditionalOptions
                                data={data}
                                userData={userData}
                                handleUserDataChange={handleUserDataChange}
                                isActive={isActiveInsurance}
                                touched={touched}
                                errors={errors}
                                setIsActive={setIsActiveInsurance}
                            />
                        ) : (
                            <Spinner />
                        )}
                        <Divider sx={{ marginBlock: '10px' }} />
                        
                        {clientType === 'ind' ? (
                            <>
                                <div className="flex flex-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {handleUserDataChange('userCheckedConsentMarketingProcessingPersonalData', e.target.checked)}}
                                                color="primary"
                                                checked={userData.userCheckedConsentMarketingProcessingPersonalData}
                                            />
                                        }
                                        label={
                                            <span className="text-[11px] flex leading-3">
                                                {t('consents.marketing_processing_personal_data')}
                                            </span>
                                        }
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={e => {
                                                    handleUserDataChange('userAllowedTerms', e.target.checked)
                                                    handleUserDataChange('userTelemarketing', false)
                                                    handleUserDataChange('MailSMSOffers', false)
                                                    handleUserDataChange('userWithoutProfiling', false)
                                                }}
                                                color="primary"
                                                checked={userData.userAllowedTerms}
                                            />
                                        }
                                        label={
                                            <span className="text-[11px] flex leading-3">
                                                {t('LAC.client_data.profiling_text')}
                                            </span>
                                        }
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={e =>
                                                    handleUserDataChange('userTelemarketing', e.target.checked)
                                                }
                                                color="primary"
                                                disabled={!userData.userAllowedTerms}
                                                checked={userData.userTelemarketing}
                                            />
                                        }
                                        label={<span className="text-[11px] flex leading-3">{t('LAC.client_data.telemarketing_text')}</span>}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={e =>
                                                    handleUserDataChange('MailSMSOffers', e.target.checked)
                                                }
                                                color="primary"
                                                disabled={!userData.userAllowedTerms}
                                                checked={userData.MailSMSOffers}
                                            />
                                        }
                                        label={<span className="text-[11px] flex leading-3">{t('consents.notification_sms')}</span>}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={e =>
                                                    handleUserDataChange('userWithoutProfiling', e.target.checked)
                                                }
                                                color="primary"
                                                disabled={!userData.userAllowedTerms}
                                                checked={userData.userWithoutProfiling}
                                            />
                                        }
                                        label={<span className="text-[11px] flex leading-3">{t('LAC.client_data.without_profiling_text')}</span>}
                                    />
                                </div>
                            </>
                        ) : null}
                        <div className='flex overflow-hidden mt-4 -ml-2.5'>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Checkbox 
                                        required
                                    />
                                </Box>
                                <Box>
                                    <LabelText />
                                </Box>
                            </div>
                        {postStatus?.message === 'success' ? (
                            <Alert sx={{ marginTop: '12px' }} severity="success">
                                {t("LAC.popup_contact.success")}
                            </Alert>
                        ) : null}
                        {postStatus?.message === 'error' ? (
                            <Alert sx={{ marginTop: '12px' }} severity="error">
                                {t("LAC.popup_contact.error")}
                            </Alert>
                        ) : null}
                        {postStatus?.message === 'success' ? null : (
                            <div className="flex justify-center">
                                <button id={buttonId} type="submit" className="btn-link" disabled={isPostLoading}>
                                    {isPostLoading ? t("LAC.popup_contact.sending_button") : t("LAC.popup_contact.send_button")}
                                </button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            </GoogleReCaptchaProvider>
        </DialogWindow>
    )
}

export default PopupContact
