import React from 'react'
import { Box, Button, Tooltip, Zoom } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const ChooseButton = ({ children, sx, ...props }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const { t } = useTranslation()

    return (
        <Box textAlign="center">
            <Tooltip
                TransitionComponent={Zoom}
                title={!props.showButton ? t('LAC.client_data.tooltip_text') : ''}
                disableHoverListener={props.showButton === undefined ? true : false}
                arrow
            >
                <span className="block my-4">
                    <Button
                        id={props?.id}
                        type={props?.type}
                        disabled={props?.disabled}
                        fullWidth={fullScreen}
                        variant="contained"
                        disableElevation
                        sx={
                            props.blueBtn
                                ? {
                                      px: 3,
                                      py: 2,
                                      color: theme.palette.primary.main,
                                      textTransform: 'none',
                                      fontWeight: '500',
                                      borderRadius: '50px',
                                      backgroundColor: '#67cff0 !important',
                                      ':hover': {
                                          backgroundColor: '#0b4185 !important',
                                          color: 'white',
                                      },
                                      '&[disabled]': {
                                          backgroundColor: '#e9e9e9 !important',
                                          color: '#b0b0b0 !important',
                                          cursor: 'not-allowed !important',
                                      },
                                      ...sx,
                                  }
                                : {
                                      minWidth: '200px',
                                      py: { xs: 2, md: 2 },
                                      color: 'white',
                                      textTransfrom: 'uppercase',
                                      fontWeight: 'bold',
                                      backgroundColor: '#f79226 !important',
                                      ':hover': {
                                          backgroundColor: '#0b4185 !important',
                                          color: 'white',
                                      },
                                      '&[disabled]': {
                                          opacity: 0.3,
                                          cursor: 'not-allowed !important',
                                      },
                                      ...sx,
                                  }
                        }
                        onClick={props?.handleClick}
                    >
                        {children}
                    </Button>
                </span>
            </Tooltip>
        </Box>
    )
}
export default ChooseButton
