import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconTowing({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    class="st0"
                    d="M4.8,21.33c-1.52,0-2.76-1.24-2.76-2.76s1.24-2.76,2.76-2.76s2.76,1.24,2.76,2.76S6.32,21.33,4.8,21.33z
		 M4.8,16.81c-0.97,0-1.76,0.79-1.76,1.76c0,0.97,0.79,1.76,1.76,1.76s1.76-0.79,1.76-1.76C6.56,17.6,5.77,16.81,4.8,16.81z"
                />
                <path
                    class="st0"
                    d="M18.73,21.33c-1.52,0-2.76-1.24-2.76-2.76s1.24-2.76,2.76-2.76s2.76,1.24,2.76,2.76S20.25,21.33,18.73,21.33z
		 M18.73,16.81c-0.97,0-1.76,0.79-1.76,1.76c0,0.97,0.79,1.76,1.76,1.76c0.97,0,1.76-0.79,1.76-1.76
		C20.49,17.6,19.7,16.81,18.73,16.81z"
                />
                <path
                    class="st0"
                    d="M16.47,19.07h-2.01c-0.71,0-1.28-0.58-1.28-1.28v-4.97c0-0.71,0.58-1.28,1.28-1.28h9.04
		c0.28,0,0.5,0.22,0.5,0.5s-0.22,0.5-0.5,0.5h-9.04c-0.15,0-0.28,0.13-0.28,0.28v4.97c0,0.15,0.13,0.28,0.28,0.28h2.01
		c0.28,0,0.5,0.22,0.5,0.5S16.75,19.07,16.47,19.07z"
                />
                <path
                    class="st0"
                    d="M9.6,19.07H7.06c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5H9.6c0.15,0,0.28-0.13,0.28-0.28v-2.96
		c0-0.23-0.2-0.53-0.41-0.62L6,12.8c-0.5-0.2-1.09-0.71-1.36-1.18L2.87,8.65c-0.13-0.22-0.5-0.43-0.75-0.43H0.5
		C0.22,8.22,0,8,0,7.72s0.22-0.5,0.5-0.5h1.62c0.61,0,1.3,0.39,1.61,0.92l1.77,2.97c0.17,0.28,0.59,0.64,0.88,0.76l3.47,1.42
		c0.59,0.24,1.03,0.9,1.03,1.54v2.96C10.88,18.5,10.31,19.07,9.6,19.07z"
                />
                <path
                    class="st0"
                    d="M5.77,12.68H0.8c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h4.97c0.28,0,0.5,0.22,0.5,0.5
		S6.04,12.68,5.77,12.68z"
                />
                <path
                    class="st0"
                    d="M11.16,12.88c-1.08,0-1.95-0.88-1.95-1.95c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5
		c0,0.52,0.43,0.95,0.95,0.95c0.53,0,0.95-0.43,0.95-0.95c0-0.53-0.43-0.95-0.95-0.95c-0.28,0-0.5-0.22-0.5-0.5V3.17
		c0-0.22,0.14-0.41,0.35-0.48c0.21-0.06,0.43,0.01,0.56,0.19l6.25,8.88c0.16,0.23,0.1,0.54-0.12,0.7c-0.23,0.16-0.54,0.11-0.7-0.12
		l-5.34-7.59v4.3c0.84,0.22,1.45,0.98,1.45,1.89C13.11,12,12.24,12.88,11.16,12.88z"
                />
                <path
                    class="st0"
                    d="M20.99,12.54c-0.12,0-0.24-0.04-0.33-0.13l-9.83-8.88c-0.21-0.19-0.22-0.5-0.04-0.71
		c0.19-0.21,0.5-0.22,0.71-0.04l9.83,8.88c0.21,0.19,0.22,0.5,0.04,0.71C21.27,12.49,21.13,12.54,20.99,12.54z"
                />
                <path
                    class="st0"
                    d="M2.54,19.07H0.5c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h2.04c0.28,0,0.5,0.22,0.5,0.5
		S2.81,19.07,2.54,19.07z"
                />
            </svg>
        </SvgIcon>
    )
}
