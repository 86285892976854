import React, { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

const NumberSelector2 = ({ value, setValue, min, max }) => {
    
    const handleIncrement = () => { 
        const newValue = value + 1
        if (value < max) {
            setValue(newValue) 
        }
    }

    const handleDecrement = () => { 
        const newValue = value - 1
        if (value > min) {
            setValue(newValue) 
        }
    }

    return (
        <Box
            maxWidth="120px"
            height="48px"
            borderRadius="50px"
            border='1px solid #ccc'
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <IconButton color="primary" aria-label="minus" onClick={handleDecrement} sx={{ backgroundColor: 'white' }}>
                <KeyboardArrowDownOutlinedIcon />
            </IconButton>
            <Box
                width="48px"
                height="44px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color='primary'
            >
                {value}
            </Box>
            <IconButton color="primary" aria-label="plus" onClick={handleIncrement} sx={{ backgroundColor: 'white' }}>
                <KeyboardArrowUpOutlinedIcon />
            </IconButton>
        </Box>
    )
}

export default NumberSelector2
