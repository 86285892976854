import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
    TextField,
    Button,
    Typography,
    Box,
    CircularProgress,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { object, string, boolean } from 'yup'
import { API, FORM_KEY } from '../../api'

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined'

import useFetch from '../../hooks/useFetch'
import MuiCheckbox from '../../../components/MuiCheckbox'
import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import { isValidPESEL } from './Schema'
import ErrorBox from '../ErrorBox'
import TabsClient from './TabsClient'
import ChooseButton from '../../components/ChooseButton'
import CountriesSelect from '../../components/CountriesSelect'
import PopupEmailExist from './PopupEmailExist'
import KrdVeryfication from './KrdVeryfication'
import MuiPhoneInput from '../../../components/MuiPhoneInput'


const Form4 = ({ processData, processDataIsLoading }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const [isLogged, setIsLogged] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [userFirstName, setUserFirstName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhoneNumber, setUserPhoneNumber] = useState('')
    const [userNationality, setUserNationality] = useState('')

    const [userDataInSystem, setUserDataInSystem] = useState(null)
    // const [userDataInSystem, setUserDataInSystem] = useState(MOCKED_USER_DATA)

    const [accountExists, setAccountExists] = useState(false)
    const [showDialogAccountExist, setShowDialogAccountExist] = useState(false)

    const [loadingCheckIfAccountExist, setLoadingCheckIfAccountExist] = useState(false)

    const [userCheckedConsentRegulations, setUserCheckedConsentRegulations] = useState(false)
    const [userCheckedconsentInformationClause, setUserCheckedConsentInformationClause] = useState(false)
    const [userCheckedWantToCreateAccount, setUserCheckedWantToCreateAccount] = useState(false)
    const [userCheckedConsentNewsletter, setUserCheckedConsentNewsletter] = useState(false)
    const [userCheckedConsentNewsletterEmail, setUserCheckedConsentNewsletterEmail] = useState(false)
    const [userCheckedConsentNewsletterSms, setUserCheckedConsentNewsletterSms] = useState(false)
    const [userCheckedConsentNewsletterPhone, setUserCheckedConsentNewsletterPhone] = useState(false)
    const [userCheckedConsentMarketingProcessingPersonalData, setUserCheckedConsentMarketingProcessingPersonalData] = useState(false)

    const [companyData, setCompanyData] = useState(null)
    const [companyDataIsLoading, setCompanyDataIsLoading] = useState(false)
    const [errorCompanyData, setErrorCompanyData] = useState(null)
    const [chosenCompanyIndex, setChosenCompanyIndex] = useState(0)

    const [showAllConsentInformationClause, setShowAllConsentInformationClause] = useState(false)

    const [extraDriversCount, setExtraDriversCount] = useState(0)

    const [hideButton, setHideButton] = useState(false)

    const [data, setData] = useState()

    const [haveToCheckKRD, setHaveToCheckKRD] = useState(false)
    const [krdVeryficationIsSuccess, setKrdVeryficationIsSuccess] = useState(false)

    useEffect(() => {
        checkIsLogged()
    }, [])

    useEffect(() => {
        processData && setData(processData?.variables)
    }, [processData])

    useEffect(() => {
        isLogged && getUserDataInSystem()
    }, [isLogged])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('userDataInSystem:', userDataInSystem)

        setUserFirstName(userDataInSystem?.firstName || '')
        setUserLastName(userDataInSystem?.lastName || '')
        setUserEmail(userDataInSystem?.email || '')
        setUserPhoneNumber(userDataInSystem?.phoneNo || '')
    }, [userDataInSystem])

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        accountExists && setShowDialogAccountExist(true)
    }, [accountExists])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('PROCESS DATA:', data)

        data && setExtraDriversCount(Number(data?.extra_drivers_count) || 0)

        data && data?.client_first_name && setEditMode(true)

        data && setUserFirstName(data?.client_first_name || '')
        data && setUserLastName(data?.client_last_name || '')
        data && setUserEmail(data?.client_email || '')
        data && setUserPhoneNumber(data?.client_phone_number || '')

        data && setUserCheckedWantToCreateAccount(data?.client_want_to_create_account === 'yes' ? true : false)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('companyData:', companyData)

        setCompanyDataIsLoading(false)
    }, [companyData])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('companyData index:', chosenCompanyIndex)
    }, [chosenCompanyIndex])

    const validationSchema = object().shape({
        userFirstName: string()
            .required(t('LAC.client_data.validation.name_1'))
            .min(2, t('LAC.client_data.validation.name_2'))
            .max(80, t('LAC.client_data.validation.name_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),
        userLastName: string()
            .required(t('LAC.client_data.validation.surname_1'))
            .min(2, t('LAC.client_data.validation.surname_2'))
            .max(80, t('LAC.client_data.validation.surname_3'))
            .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s'\-]+$/, t('LAC.client_data.validation.surname_2')),
        userEmail: string()
            .email(t('LAC.client_data.validation.email'))
            .required(t('LAC.client_data.validation.email')),
        userPhoneNumber: string().when([], {
            is: () => !isLogged,
            then: schema =>
                schema
                    .matches(/^(?=(?:.*\d){11,})^[\d+()\- ]{11,17}$/, t('LAC.client_data.validation.phone_3'))
                    .required(t('LAC.client_data.validation.phone_2')),
                    otherwise: schema => schema.optional(),
        }),
        PESEL: string().when(['nationality', 'haveToCheckKRD'], {
            is: (nationality, haveToCheckKRD) => nationality === 'PL' && haveToCheckKRD === true,
            then: schema =>
                schema
                    .length(11, t('LAC.client_data.validation.PESEL_1'))
                    .test('isValidPESEL', t('LAC.client_data.validation.PESEL_2'), value => isValidPESEL(value))
                    .required(t('LAC.client_data.validation.PESEL_2')),
            otherwise: schema => schema.optional(),
        }),
        nip: string().when(['companyCountry', 'userWantInvoice'], {
            is: (companyCountry, userWantInvoice) => companyCountry === 'PL' && userWantInvoice === true,
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.nip_3'))
                    .matches(/^\d{10}$/, t('LAC.client_data.validation.nip_1'))
                    .test('isValidNIP', t('LAC.client_data.validation.nip_2'), value => {
                        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
                        const digits = value.toString().split('').map(Number)
                        const checksum =
                            digits.reduce((acc, digit, index) => {
                                if (index === 9) return acc
                                return acc + digit * weights[index]
                            }, 0) % 11
                        return checksum === digits[9]
                    }),
            otherwise: schema => schema.optional(),
        }),
        nationality: string().optional().max(50),
        flightNumber: string().optional().max(50),
        companyCountry: string().optional().max(50),
        companyName: string().when('userWantInvoice', {
            is: value => value === true,
            then: schema =>
                schema.required(t('errors.schema.required')).max(200, t('errors.schema.invalid_max_length')),
            otherwise: schema => schema.optional(),
        }),
        companyStreet: string().when('userWantInvoice', {
            is: value => value === true,
            then: schema =>
                schema.required(t('errors.schema.required')).max(200, t('errors.schema.invalid_max_length')),
            otherwise: schema => schema.optional(),
        }),
        companyHouse: string().when('userWantInvoice', {
            is: value => value === true,
            then: schema =>
                schema.required(t('errors.schema.required')).max(200, t('errors.schema.invalid_max_length')),
            otherwise: schema => schema.optional(),
        }),
        companyAdditionalData: string().when(['companyCountry', 'userWantInvoice'], {
            is: (companyCountry, userWantInvoice) => companyCountry !== 'PL' && userWantInvoice === true,
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.nip_3')),
            otherwise: schema => schema.optional(),
        }),
        companyLocal: string().max(200, t('errors.schema.required')).optional(),
        companyPostalCode: string().when(['companyCountry', 'userWantInvoice'], {
            is: (companyCountry, userWantInvoice) => companyCountry === 'PL' && userWantInvoice === true,
            then: schema =>
                schema.matches(/^\d{2}-\d{3}$/, t('errors.schema.required')).required(t('errors.schema.required')),
            otherwise: schema => schema.optional(),
        }),
        companyCity: string().when('userWantInvoice', {
            is: value => value === true,
            then: schema =>
                schema
                    .required(t('errors.schema.required'))
                    .min(2, t('errors.schema.required'))
                    .max(200, t('errors.schema.invalid_max_length')),
            otherwise: schema => schema.optional(),
        }),
        userWantInvoice: boolean().optional(),
        userWantToCreateAccount: boolean().optional(),
        consentRegulations: boolean().optional(),
        consentInformationClause: boolean().optional(),
        consentNewsletter: boolean().optional(),
        consentNewsletterEmail: boolean().optional(),
        consentNewsletterSms: boolean().optional(),
        consentNewsletterPhone: boolean().optional(),
        consentKrd: boolean().optional(),
        ...Array.from({ length: extraDriversCount }, (_, index) => ({
            [`extra_driver_first_name_${index + 1}`]: string().required(t('errors.schema.required')),
            [`extra_driver_last_name_${index + 1}`]: string().required(t('errors.schema.required')),
            [`extra_driver_driving_license_${index + 1}`]: string().required(t('errors.schema.required')),
            [`extra_driver_country_${index + 1}`]: string().required(t('errors.schema.required')),
            [`extra_driver_phone_number_${index + 1}`]: string().required(t('errors.schema.required')),
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    })

    const initialValues = {
        userFirstName: userFirstName || '',
        userLastName: userLastName || '',
        userEmail: userEmail || '',
        userPhoneNumber: userPhoneNumber || '',
        userCheckedConsentMarketingProcessingPersonalData: data?.consent_marketing_processing_personal_data === 'yes' ? true : Boolean(userCheckedConsentMarketingProcessingPersonalData),
        userWantToCreateAccount:
            data?.client_want_to_create_account === 'yes' ? true : Boolean(userCheckedWantToCreateAccount),
        consentRegulations: data?.consent_regulations === 'yes' ? true : Boolean(userCheckedConsentRegulations),
        consentInformationClause:
            data?.consent_information_clause === 'yes' ? true : Boolean(userCheckedconsentInformationClause),
        consentNewsletter: data?.consent_newsletter === 'yes' ? true : Boolean(userCheckedConsentNewsletter),
        consentNewsletterEmail:
            data?.consent_newsletter_email === 'yes' ? true : Boolean(userCheckedConsentNewsletterEmail),
        consentNewsletterSms:
            data?.MailSMSOffers === 'yes' ? true : Boolean(userCheckedConsentNewsletterSms),
        consentNewsletterPhone:
            data?.consent_newsletter_phone === 'yes' ? true : Boolean(userCheckedConsentNewsletterPhone),
        consentKrd: false,
        PESEL: data?.PESEL || '',
        nationality: data?.nationality || userNationality || (locale === 'pl' ? 'PL' : 'GB'),
        companyCountry: data?.company_country || companyData?.contactLanguage || 'PL',
        nip: data?.company_nip || companyData?.nip || '',
        userWantInvoice: data?.client_want_invoice === 'yes' ? true : companyData !== null || false,
        companyId: data?.company_id || companyData?.id || '',
        companyAdditionalData: data?.company_id || '',
        companyName: data?.company_name || companyData?.companyName || companyData?.name || '',
        companyStreet: data?.company_street || companyData?.address?.street || '',
        companyHouse: data?.company_house || companyData?.address?.house || '',
        companyLocal: data?.company_local || companyData?.address?.local || '',
        companyPostalCode:
            data?.company_postal_code || companyData?.address?.postCode || companyData?.address?.postalCode || '',
        companyCity: data?.company_city || companyData?.address?.city || companyData?.address?.place || '',
        flightNumber: data?.flight_number || '',
        ...Array.from({ length: extraDriversCount }, (_, index) => ({
            [`extra_driver_first_name_${index + 1}`]: data?.[`extra_driver_first_name_${index + 1}`] || '',
            [`extra_driver_last_name_${index + 1}`]: data?.[`extra_driver_last_name_${index + 1}`] || '',
            [`extra_driver_driving_license_${index + 1}`]: data?.[`extra_driver_driving_license_${index + 1}`] || '',
            [`extra_driver_country_${index + 1}`]:
                data?.[`extra_driver_country_${index + 1}`] || locale === 'pl' ? 'PL' : 'GB',
            [`extra_driver_phone_number_${index + 1}`]: data?.[`extra_driver_phone_number_${index + 1}`] || '',
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    }

    const checkIsLogged = async () => {
        try {
            const response = await fetch(API['USER_IS_LOGGED'])
            if (response.ok) {
                const res = await response.json()
                setIsLogged(res.logged)
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    const handleCheckIfAccountExists = async email => {
        try {
            setLoadingCheckIfAccountExist(true)
            const response = await fetch(`${API['ACCOUNT_EXISTS']}?email=${email}`)
            if (response.ok) {
                const res = await response.json()
                setAccountExists(res.accountExists)
                setLoadingCheckIfAccountExist(false)
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    const handleClickGUS = nip => {
        getCompanyData(nip)
    }

    const getCompanyData = async nip => {
        setCompanyDataIsLoading(true)
        setErrorCompanyData('')
        try {
            const response = await fetch(`${API['GET_COMPANY_DATA']}?nip=${nip}`, {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                setCompanyData(res)

                if (res.status === 404) setErrorCompanyData(t('errors.gus_company_data_not_found'))
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    const getUserDataInSystem = async email => {
        setUserDataInSystem(null)
        try {
            const response = await fetch(`${API['USER_GET_DATA']}`, {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                setUserDataInSystem(res)
            }
        } catch (error) {
            console.error('Error fetching data', error)
        }
    }

    const handleClickEditStep3 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step3']}`, 'GET')
    }

    return (
        !processDataIsLoading &&
        data && (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => {
                    setHideButton(true)

                    if (!values.consentNewsletter) {
                        values.consentNewsletterEmail = false
                        values.consentNewsletterSms = false
                        values.consentNewsletterPhone = false
                    }

                    if (isLogged) {
                        values.consentRegulations = true
                        values.consentInformationClause = true
                        values.consentNewsletter = ''
                        values.consentNewsletterEmail = ''
                        values.consentNewsletterSms = ''
                        values.consentNewsletterPhone = ''
                    }

                    if (!values.userWantInvoice) {
                        values.companyCountry = ''
                        values.nip = ''
                        values.companyId = ''
                        values.companyAdditionalData = ''
                        values.companyName = ''
                        values.companyStreet = ''
                        values.companyHouse = ''
                        values.companyLocal = ''
                        values.companyPostalCode = ''
                        values.companyCity = ''
                    }

                    const data = {
                        locale: locale,
                        client_first_name: values.userFirstName,
                        client_last_name: values.userLastName,
                        client_email: values.userEmail,
                        client_phone_number: values.userPhoneNumber,
                        client_want_to_create_account: values.userWantToCreateAccount === true ? 'yes' : 'no',
                        client_want_invoice: values.userWantInvoice === true ? 'yes' : 'no',
                        consent_regulations: values.consentRegulations === true ? 'yes' : 'no',
                        consent_information_clause: values.consentInformationClause === true ? 'yes' : 'no',
                        consent_newsletter: values.consentNewsletter === true ? 'yes' : 'no',
                        consent_newsletter_email: values.consentNewsletterEmail === true ? 'yes' : 'no',
                        MailSMSOffers: values.consentNewsletterSms === true ? 'yes' : 'no',
                        consent_newsletter_phone: values.consentNewsletterPhone === true ? 'yes' : 'no',
                        consent_krd: values.consentKrd === true ? 'yes' : 'no',
                        PESEL: values.PESEL,
                        nationality: values.nationality,
                        company_country: values.companyCountry,
                        company_nip: values.nip,
                        company_id: values.companyId,
                        company_additional_data: values.companyAdditionalData,
                        company_name: values.companyName,
                        company_street: values.companyStreet,
                        company_house: values.companyHouse,
                        company_local: values.companyLocal,
                        company_postal_code: values.companyPostalCode,
                        company_city: values.companyCity,
                        flight_number: values.flightNumber,
                        ...Array.from({ length: extraDriversCount }, (_, index) => ({
                            [`extra_driver_first_name_${index + 1}`]: values[`extra_driver_first_name_${index + 1}`],
                            [`extra_driver_last_name_${index + 1}`]: values[`extra_driver_last_name_${index + 1}`],
                            [`extra_driver_driving_license_${index + 1}`]:
                                values[`extra_driver_driving_license_${index + 1}`],
                            [`extra_driver_country_${index + 1}`]: values[`extra_driver_country_${index + 1}`],
                            [`extra_driver_phone_number_${index + 1}`]:
                                values[`extra_driver_phone_number_${index + 1}`],
                        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                    }

                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
                        console.log('SUBMIT : ', data)

                    fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
                }}
            >
                {({ errors, touched, values, setFieldValue, setFieldTouched, isSubmitting }) => (
                    <Form noValidate>
                        <section id="substep1">
                            {/* -------------------- Twoje dane -------------------- */}
                            <Box mb={4}>
                                <TabsClient
                                    isLogged={isLogged}
                                    tabPanel1={
                                        <>
                                            <Box mb={4}>
                                                <Typography variant="h2" fontSize={20} fontWeight="bold" textAlign="left" mb={2}>
                                                    {t('RAC.step_five.your_data')}
                                                </Typography>
                                                <Box mb={2}>
                                                    <Field
                                                        required
                                                        disabled={isLogged}
                                                        as={TextField}
                                                        name="userFirstName"
                                                        onChange={e => setUserFirstName(e.target.value)}
                                                        value={userFirstName}
                                                        label={t('RAC.step_five.contact_details.first_name')}
                                                        error={touched.userFirstName && Boolean(errors.userFirstName)}
                                                        helperText={touched.userFirstName && errors.userFirstName}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineOutlinedIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Field
                                                        required
                                                        disabled={isLogged}
                                                        as={TextField}
                                                        name="userLastName"
                                                        value={userLastName}
                                                        onChange={e => setUserLastName(e.target.value)}
                                                        label={t('RAC.step_five.contact_details.last_name')}
                                                        error={touched.userLastName && Boolean(errors.userLastName)}
                                                        helperText={touched.userLastName && errors.userLastName}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineOutlinedIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Field
                                                        required
                                                        disabled={isLogged && userEmail}
                                                        as={TextField}
                                                        name="userEmail"
                                                        value={userEmail}
                                                        onChange={e => setUserEmail(e.target.value)}
                                                        label={t('RAC.step_five.contact_details.email_address')}
                                                        error={touched.userEmail && Boolean(errors.userEmail)}
                                                        helperText={touched.userEmail && errors.userEmail}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MailOutlineIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {loadingCheckIfAccountExist && (
                                                                        <CircularProgress size={20} />
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onBlur={e => {
                                                            handleCheckIfAccountExists(e.target.value)
                                                            setFieldTouched("userEmail", true)
                                                        }}
                                                    />
                                                </Box>
                                                <Box mb={2}> 
                                                    {/* <Tooltip placement="bottom-start" TransitionComponent={Zoom} title={t('LAC.client_data.tooltip_text_2')}>
                                                        <div> */} 
                                                    <MuiPhoneInput
                                                        name='userPhoneNumber'
                                                        required
                                                        hideFlag={isLogged && userPhoneNumber}
                                                        disabled={isLogged && userPhoneNumber}
                                                        disableDropdown={isLogged && userPhoneNumber}
                                                        autoFormat={!(isLogged && userPhoneNumber)}
                                                        disableCountryCode={isLogged && userPhoneNumber}
                                                        error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                                                        helperText={touched.userPhoneNumber && errors.userPhoneNumber} 
                                                        setValue={setUserPhoneNumber}
                                                        RAC
                                                        withPlus
                                                        locale={locale}
                                                    /> 
                                                        {/* </div>
                                                    </Tooltip>   */}
                                                </Box> 
                                                <MuiCheckbox
                                                               name="userCheckedConsentMarketingProcessingPersonalData"
                                                               label={t('consents.marketing_processing_personal_data')}
                                                               mb={1}
                                                               onChange={e => {
                                                                   setFieldValue('consent_marketing_processing_personal_data', e.target.checked)
                                                                   setUserCheckedConsentMarketingProcessingPersonalData(e.target.checked) 
                                                               }}
                                                       /> 
                                                {!isLogged && (
                                                    <Box mb={2}>
                                                        {!accountExists && (
                                                            <Box mb={1}>
                                                                <MuiCheckbox
                                                                    name="userWantToCreateAccount"
                                                                    label={t('RAC.step_five.question')}
                                                                    mb={0}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'userWantToCreateAccount',
                                                                            e.target.checked
                                                                        )
                                                                        setUserCheckedWantToCreateAccount(
                                                                            e.target.checked
                                                                        )
                                                                    }}
                                                                />
                                                                <Typography fontSize={{ xs: 12, lg: 14 }} pl={4}>
                                                                    {t('RAC.step_five.description')}
                                                                </Typography>
                                                            </Box>
                                                        )} 
                                                        {values.userWantToCreateAccount && (
                                                            <MuiCheckbox
                                                                name="consentNewsletter"
                                                                label={t('consents.marketing')}
                                                                mb={1}
                                                                onChange={e => {
                                                                    setFieldValue('consentNewsletter', e.target.checked)
                                                                    setUserCheckedConsentNewsletter(e.target.checked)

                                                                    // setFieldValue(
                                                                    //     'consentNewsletterEmail',
                                                                    //     e.target.checked
                                                                    // )
                                                                    // setUserCheckedConsentNewsletterEmail(
                                                                    //     e.target.checked
                                                                    // )

                                                                    // setFieldValue(
                                                                    //     'consentNewsletterPhone',
                                                                    //     e.target.checked
                                                                    // )
                                                                    // setUserCheckedConsentNewsletterPhone(
                                                                    //     e.target.checked
                                                                    // )
                                                                }}
                                                            />
                                                        )}
                                                        {values.userWantToCreateAccount && values.consentNewsletter && (
                                                            <>
                                                                <MuiCheckbox
                                                                    name="consentNewsletterEmail"
                                                                    label={t('consents.notification_email')}
                                                                    mb={1}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'consentNewsletterEmail',
                                                                            e.target.checked
                                                                        )
                                                                        setUserCheckedConsentNewsletterEmail(
                                                                            e.target.checked
                                                                        )
                                                                    }}
                                                                />
                                                                <MuiCheckbox
                                                                    name="consentNewsletterSms"
                                                                    label={t('consents.notification_sms')}
                                                                    mb={1}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'consentNewsletterSms',
                                                                            e.target.checked
                                                                        )
                                                                        setUserCheckedConsentNewsletterSms(
                                                                            e.target.checked
                                                                        )
                                                                    }}
                                                                />
                                                                <MuiCheckbox
                                                                    name="consentNewsletterPhone"
                                                                    label={t('consents.notification_phone')}
                                                                    mb={0}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'consentNewsletterPhone',
                                                                            e.target.checked
                                                                        )
                                                                        setUserCheckedConsentNewsletterPhone(
                                                                            e.target.checked
                                                                        )
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        </>
                                    }
                                    tabPanel2={<Spinner />}
                                />
                                {/* <TabsClient setClickedTabHaveAccount={setClickedTabHaveAccount} /> */}
                            </Box>
                        </section>

                        <section id="krd">
                            {/* -------------------- Dane identyfikacyjne: obywatelstwo, PESEL -------------------- */}
                            <KrdVeryfication
                                processData={data}
                                haveToCheckKRD={haveToCheckKRD}
                                setHaveToCheckKRD={setHaveToCheckKRD}
                                krdVeryficationIsSuccess={krdVeryficationIsSuccess}
                                setKrdVeryficationIsSuccess={setKrdVeryficationIsSuccess}
                                userNationality={userNationality}
                                setUserNationality={setUserNationality}
                            />
                        </section>

                        {((values.consentRegulations && values.consentInformationClause) || isLogged || editMode) && (
                            <section id="substep2">
                                {/* -------------------- Faktura VAT -------------------- */}
                                <Box mb={4}>
                                    <Typography variant="h2" fontSize={20} fontWeight="bold" textAlign="left">
                                        {t('RAC.step_five.invoice')}
                                    </Typography>

                                    <Box mb={2}>
                                        <MuiCheckbox
                                            name="userWantInvoice"
                                            label={t('RAC.step_five.checkbox_invoice')}
                                            errors={errors['userWantInvoice']}
                                            touched={touched['userWantInvoice']}
                                            mb={0}
                                        />
                                    </Box>
                                    {values.userWantInvoice && (
                                        <>
                                            {isLogged && userDataInSystem?.companies && (
                                                <Box mb={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="company-select-label">Firma</InputLabel>
                                                        <Select
                                                            name="companyId"
                                                            labelId="company-select-label"
                                                            id="company-select"
                                                            label="company-select"
                                                            value={values.companyId}
                                                            onChange={e => {
                                                                setFieldValue('companyId', e.target.value)
                                                            }}
                                                        >
                                                            {userDataInSystem?.companies?.map((company, index) => (
                                                                <MenuItem
                                                                    key={`company-${company.id}`}
                                                                    value={company.id}
                                                                    onClick={() => {
                                                                        setErrorCompanyData(false)
                                                                        setCompanyData(company)
                                                                    }}
                                                                >
                                                                    {company.companyName}
                                                                </MenuItem>
                                                            ))}
                                                            <MenuItem
                                                                key={`company-new`}
                                                                value="new"
                                                                onClick={() => {
                                                                    setCompanyData('')
                                                                }}
                                                            >
                                                                {t('new_company')}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            )}
                                            <Box mb={2}>
                                                <CountriesSelect
                                                    locale={locale}
                                                    name={'companyCountry'}
                                                    label={t('RAC.step_four.country')}
                                                    value={values.companyCountry}
                                                    setValue={() => {}}
                                                />
                                            </Box>
                                            <Box mb={2}>
                                                {values.companyCountry === 'PL' ? (
                                                    <> 
                                                        <Field
                                                            as={TextField}
                                                            name="nip"
                                                            label="NIP *"
                                                            error={touched.nip && Boolean(errors.nip)}
                                                            helperText={touched.nip && errors.nip}
                                                            fullWidth
                                                            onBlur={() => setErrorCompanyData(false)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CorporateFareOutlinedIcon />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Button 
                                                                            variant="text"
                                                                            size="small"
                                                                            sx={{
                                                                                maxWidth: '100px',
                                                                                whiteSpace: 'normal',
                                                                                fontSize: 12,
                                                                                fontWeight: 'bold',
                                                                                lineHeight: 1.5,
                                                                            }} 
                                                                            disabled={values.nip === '' || values.nip.length < 10} 
                                                                            onClick={() => handleClickGUS(values.nip)}>{t('load_data_from')}&nbsp;GUS
                                                                        </Button>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <Field
                                                        as={TextField}
                                                        name="companyAdditionalData"
                                                        label={t('RAC.step_four.company_tax_id')}
                                                        fullWidth
                                                        error={
                                                            touched.companyAdditionalData &&
                                                            Boolean(errors.companyAdditionalData)
                                                        }
                                                        helperText={
                                                            touched.companyAdditionalData &&
                                                            errors.companyAdditionalData
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <CorporateFareOutlinedIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                {errorCompanyData && (
                                                    <ErrorBox error={errorCompanyData} sx={{ mt: 2 }} />
                                                )}
                                            </Box>
                                            {!companyDataIsLoading ? (
                                                <Box>
                                                    <Field
                                                        as={TextField}
                                                        name="companyName"
                                                        label={`${t('LAC.client_data.company_name')}*`}
                                                        fullWidth
                                                        error={touched.companyName && Boolean(errors.companyName)}
                                                        helperText={touched.companyName && errors.companyName}
                                                        sx={{
                                                            mb: 2,
                                                        }}
                                                    />
                                                    <Field
                                                        as={TextField}
                                                        name="companyStreet"
                                                        label={`${t('LAC.client_data.street')}*`}
                                                        fullWidth
                                                        error={touched.companyStreet && Boolean(errors.companyStreet)}
                                                        helperText={touched.companyStreet && errors.companyStreet}
                                                        sx={{
                                                            mb: 2,
                                                        }}
                                                    />
                                                    <Box display="flex" gap={2}>
                                                        <Field
                                                            as={TextField}
                                                            name="companyHouse"
                                                            label={`${t('LAC.client_data.house')}*`}
                                                            fullWidth
                                                            error={touched.companyHouse && Boolean(errors.companyHouse)}
                                                            helperText={touched.companyHouse && errors.companyHouse}
                                                            sx={{
                                                                mb: 2,
                                                            }}
                                                        />
                                                        <Field
                                                            as={TextField}
                                                            name="companyLocal"
                                                            label={`${t('LAC.client_data.local')}`}
                                                            fullWidth
                                                            error={touched.companyLocal && Boolean(errors.companyLocal)}
                                                            helperText={touched.companyLocal && errors.companyLocal}
                                                            sx={{
                                                                mb: 2,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box display="flex" gap={2}>
                                                        <Field
                                                            as={TextField}
                                                            name="companyPostalCode"
                                                            label={`${t('LAC.client_data.postcode')}*`}
                                                            fullWidth
                                                            error={
                                                                touched.companyPostalCode &&
                                                                Boolean(errors.companyPostalCode)
                                                            }
                                                            helperText={
                                                                touched.companyPostalCode && errors.companyPostalCode
                                                            }
                                                        />
                                                        <Field
                                                            as={TextField}
                                                            name="companyCity"
                                                            label={`${t('LAC.client_data.city')}*`}
                                                            fullWidth
                                                            error={touched.companyCity && Boolean(errors.companyCity)}
                                                            helperText={touched.companyCity && errors.companyCity}
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Spinner />
                                            )}
                                        </>
                                    )}
                                </Box>
                                {/* -------------------- Dodatkowy kierowca -------------------- */}
                                {extraDriversCount > 0 && (
                                    <Box mb={4}>
                                        {Array.from({ length: extraDriversCount }).map((_, index) => (
                                            <Box key={`extraDriver${index + 1}`} mb={4}>
                                                <Box mb={2}>
                                                    <Typography
                                                        variant="h2"
                                                        fontSize={20}
                                                        fontWeight="bold"
                                                        textAlign="left"
                                                    >
                                                        {t('additional_driver')}{' '}
                                                        {extraDriversCount !== 1 && <>nr {index + 1}</>}
                                                    </Typography>
                                                </Box>
                                                <Box mb={2}>
                                                    <Field name={`extra_driver_first_name_${index + 1}`}>
                                                        {({ field, form }) => (
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                label="Imię"
                                                                {...field}
                                                                error={
                                                                    form.touched[field.name] &&
                                                                    Boolean(form.errors[field.name])
                                                                }
                                                                helperText={
                                                                    form.touched[field.name] && form.errors[field.name]
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Box>
                                                <Box mb={2}>
                                                    <Field name={`extra_driver_last_name_${index + 1}`}>
                                                        {({ field, form }) => (
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                label="Nazwisko"
                                                                {...field}
                                                                error={
                                                                    form.touched[field.name] &&
                                                                    Boolean(form.errors[field.name])
                                                                }
                                                                helperText={
                                                                    form.touched[field.name] && form.errors[field.name]
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Box>
                                                <Box mb={2}>
                                                    <Field name={`extra_driver_driving_license_${index + 1}`}>
                                                        {({ field, form }) => (
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                label="Numer prawa jazdy"
                                                                {...field}
                                                                error={
                                                                    form.touched[field.name] &&
                                                                    Boolean(form.errors[field.name])
                                                                }
                                                                helperText={
                                                                    form.touched[field.name] && form.errors[field.name]
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Box>

                                                <Box mb={2}>
                                                    <CountriesSelect
                                                        required
                                                        locale={locale}
                                                        name={`extra_driver_country_${index + 1}`}
                                                        label={t('RAC.step_four.country')}
                                                        setValue={() => {}}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Field name={`extra_driver_phone_number_${index + 1}`}>
                                                        {({ field, form }) => (
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                label="Numer telefonu"
                                                                {...field}
                                                                error={
                                                                    form.touched[field.name] &&
                                                                    Boolean(form.errors[field.name])
                                                                }
                                                                helperText={
                                                                    form.touched[field.name] && form.errors[field.name]
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {/* -------------------- Informacje dodatkowe: numer lotu -------------------- */}
                                {data?.pickup_location_type === 'Airport' && (
                                    <Box mb={4}>
                                        <Box mb={3}>
                                            <Typography variant="h2" fontSize={20} fontWeight="bold" textAlign="left">
                                                {t('RAC.step_five.info')}
                                            </Typography>
                                        </Box>
                                        <Field
                                            as={TextField}
                                            name="flightNumber"
                                            label={t('RAC.step_five.flight_number')}
                                            error={touched.flightNumber && Boolean(errors.flightNumber)}
                                            helperText={touched.flightNumber && errors.flightNumber}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FlightOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                )}
                            </section>
                        )}

                        {/* -------------------- Regulamin -------------------- */}
                        {!isLogged && (
                            <Box mb={2}>
                                <Typography variant="h3" fontSize={20} fontWeight="bold" textAlign="left" mb={1}>
                                    {t('RAC.step_five.regulations')}
                                </Typography>
                                <MuiCheckbox
                                    name="consentRegulations"
                                    label={
                                        <div>
                                            <span style={{ color: 'red' }}>* </span>
                                            <span>{t('consents.accept_regulations.0')} </span>
                                            <a
                                                href={
                                                    locale === 'pl'
                                                        ? API['GET_FILE_REGULATIONS']
                                                        : API['GET_FILE_REGULATIONS_EN']
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#0b4185',
                                                }}
                                            >
                                                {t('consents.accept_regulations.1')}
                                            </a>
                                            <span> {t('consents.accept_regulations.2')} </span>
                                        </div>
                                    }
                                    errors={errors['consentRegulations']}
                                    touched={touched['consentRegulations']}
                                    mb={0}
                                    onChange={e => {
                                        setFieldValue('consentRegulations', e.target.checked)
                                        setUserCheckedConsentRegulations(e.target.checked)
                                    }}
                                />
                                {/* -------------------- klauzula informacyjna -------------------- */}
                                <Box>
                                    <MuiCheckbox
                                        name="consentInformationClause"
                                        label={
                                            <div>
                                                <span style={{ color: 'red' }}>* </span>{' '}
                                                {t('consents.information_clause_header')}
                                            </div>
                                        }
                                        errors={errors['consentInformationClause']}
                                        touched={touched['consentInformationClause']}
                                        mb={0}
                                        onChange={e => {
                                            setFieldValue('consentInformationClause', e.target.checked)
                                            setUserCheckedConsentInformationClause(e.target.checked)
                                        }}
                                    />
                                    <Typography>
                                        {t('consents.information_clause.0')}
                                        <br />
                                        {t('consents.information_clause.1')}
                                    </Typography>
                                    {showAllConsentInformationClause && (
                                        <Typography>
                                            {t('consents.information_clause.2')}
                                            <br />
                                            {t('consents.information_clause.3')}
                                            <br />
                                            {t('consents.information_clause.4')}
                                            <br />
                                            {t('consents.information_clause.5')}
                                            <br />
                                            {t('consents.information_clause.6')}
                                        </Typography>
                                    )}
                                    {!showAllConsentInformationClause && <Box>...</Box>}
                                    <Button
                                        sx={{ padding: 0, fontWeight: 'bold', mb: 1 }}
                                        onClick={() =>
                                            setShowAllConsentInformationClause(!showAllConsentInformationClause)
                                        }
                                    >
                                        {showAllConsentInformationClause ? t('btn.collapse') : t('btn.expand')}
                                    </Button>
                                </Box>
                            </Box>
                        )}

                        {/* -------------------- SUBMIT -------------------- */}
                        {
                            ((values.consentRegulations && values.consentInformationClause) || isLogged) &&
                                ((haveToCheckKRD && values.consentKrd && values.PESEL && krdVeryficationIsSuccess) ||
                                    !haveToCheckKRD ||
                                    (haveToCheckKRD && values.nationality !== 'PL') ||
                                    (haveToCheckKRD && krdVeryficationIsSuccess)) &&
                                values.userFirstName &&
                                values.userLastName &&
                                values.userEmail &&
                                values.userPhoneNumber &&
                                (!hideButton ? (
                                    <ChooseButton blueBtn id="R4" type="submit" sx={{ mt: 5 }} disabled={isSubmitting}>
                                        {t('btn.go_further')}
                                    </ChooseButton>
                                ) : (
                                    <Spinner />
                                ))
                            // <Box textAlign="center">
                            //     <button
                            //         type="submit"
                            //         disabled={isSubmitting}
                            //         style={{
                            //             width: '200px',
                            //             padding: '16px',
                            //             color: 'white',
                            //             textTransfrom: 'uppercase',
                            //             fontWeight: 'bold',
                            //             backgroundColor: '#f79226 ',
                            //             '&:hover': {
                            //                 backgroundColor: '#0b4185',
                            //                 color: 'white',
                            //             },
                            //         }}
                            //     >
                            //         {t('btn.pay')}
                            //     </button>
                            // </Box>
                        }
                        {/* <BackButtonInForm handleClick={handleClickEditStep3}>{t('btn.back')}</BackButtonInForm> */}

                        {/* -------------------- DialogWindow -------------------- */}
                        {showDialogAccountExist === true && (
                            <PopupEmailExist
                                showAlert={showDialogAccountExist}
                                setShowAlert={setShowDialogAccountExist}
                                dialogTitle="Masz już konto w Express.pl?"
                            />
                        )}
                        {/* <Box mt={5} sx={{ wordBreak: 'normal' }}>
                        <pre>{JSON.stringify(values, null, '\t')}</pre>
                    </Box> */}
                    </Form>
                )}
            </Formik>
        )
    )
}

export default Form4
