import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import PackageDetails from './PackageDetails'

const Extras3 = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()
    const { setFieldValue } = useFormikContext()

    const [selectedPackageID, setSelectedPackageID] = useState()

    const ownContributnionToDamagePrice =
        processData?.car_class_name === 'E' || processData?.car_class_name === 'F' ? '10 000 zł' : '2 500 zł'
        
    const depositPrice =
        processData?.car_class_name === 'E' || processData?.car_class_name === 'F' ? '5 000 zł' : '2 500 zł'

    const formmattedSilverPackagePrice = useFormattedPrice(processData?.silver_package_price)

    const handleClick = value => {
        setSelectedPackageID(value)
        setFieldValue('offer_selected_package_id', value)
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        const packageID = processData.offer_selected_package === 'brown' ? 1 : 2
        processData && setSelectedPackageID(packageID)
    }, [processData])

    return (
        <Box my={5}>
            <Typography textAlign="left" variant="h2" fontSize={{ xs: 16, lg: 20 }} fontWeight={'bold'} color='secondary'>
                {t('RAC.step_three.heading_3')}
            </Typography>
            <Box display="flex" gap={2} mt={2} sx={{ overflowX: { xs: 'scroll', lg: 'auto' } }}>
                <PackageDetails
                    id={1}
                    name={t('RAC.step_three.car_insurance.0')}
                    ownContributnionToDamage={ownContributnionToDamagePrice}
                    thiefProtection={ownContributnionToDamagePrice}
                    widthTowing="true"
                    withGlassProtection="false"
                    withTiresProtection="false"
                    withUpholsteryProtection="false"
                    withPlatesProtextion="false"
                    depositPrice={depositPrice}
                    totalPrice="0 zł"
                    dayCount={processData?.silver_package_days}
                    selectedPackageID={selectedPackageID}
                    handleClick={handleClick}
                />
                <PackageDetails
                    id={2}
                    name={t('RAC.step_three.car_insurance.1')}
                    ownContributnionToDamage="0 zł"
                    thiefProtection="0 zł"
                    widthTowing="true"
                    withGlassProtection="true"
                    withTiresProtection="true"
                    withUpholsteryProtection="false"
                    withPlatesProtextion="false"
                    depositPrice="500 zł"
                    totalPrice={formmattedSilverPackagePrice}
                    dayCount={processData?.silver_package_days}
                    selectedPackageID={selectedPackageID}
                    handleClick={handleClick}
                    bestseller
                />
                {/* <PackageDetails
                    id={3}
                    name={t('RAC.step_three.car_insurance.2')}
                    ownContributnionToDamage="true"
                    thiefProtection="true"
                    widthTowing="true"
                    withGlassProtection="true"
                    withTiresProtection="true"
                    withUpholsteryProtection="true"
                    withPlatesProtextion="true"
                    depositPrice="1 zł"
                    totalPrice="2 999 zł"
                    dayCount="5"
                    selectedPackageID={selectedPackageID}
                    handleClick={handleClick}
                /> */}
            </Box>
        </Box>
    )
}

export default Extras3
