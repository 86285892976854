import React from 'react'

const AccordionItem = ({ counter, question, answer }) => {
    const [isOpen, setOpen] = React.useState(false)

    return (
        <div role="region" aria-labelledby={`accordion-${counter}`} className="block max-w-[840px] mx-auto">
            <button
                type="button"
                className="flex justify-between items-center w-full text-left font-semibold"
                aria-expanded={isOpen}
                aria-controls={`item-${counter}`}
                onClick={() => setOpen(!isOpen)}
            >
                <span className="shrink-0 text-lg lg:text-2xl font-bold w-12 sm:w-[72px] px-3 pt-2">
                    {counter < 10 ? `0${counter}` : counter}
                </span>
                <h4 id={`accordion-${counter}`} className="text-lg lg:text-2xl font-normal pr-4 pt-2">
                    {question}
                </h4>
                <div className="ml-auto p-[18px] border border-navyIcon rounded-full">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        className="fill-navyIcon"
                    >
                        <rect x="0" y="4" width="10" height="2"></rect>
                        <rect
                            x="4"
                            y="0"
                            width="2"
                            height="10"
                            className={`transform origin-center transition-all duration-200 ease-out ${
                                !isOpen ? 'scale-y-1' : 'scale-y-0'
                            }`}
                        ></rect>
                    </svg>
                </div>
            </button>
            <div
                id={`item-${counter}`}
                aria-hidden={!isOpen}
                role="region"
                tabIndex="-1"
                className={`py-4 text-sm lg:text-lg grid text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                    isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                }`}
            >
                <div className="overflow-hidden ul-custom text-justify" dangerouslySetInnerHTML={{ __html: answer }}></div>
            </div>
        </div>
    )
}

export default AccordionItem
