import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

const BackButtonInForm = ({ handleClick, sx, ...props }) => {
    const { t } = useTranslation()

    return (
        <Box textAlign={props?.center ? 'center' : 'left'} mt={0} mb={0.5}>
            <Button
                onClick={handleClick}
                sx={{ paddingLeft: 0, fontSize: { xs: 12, md: 14 }, textTransform: 'none', fontWeight: '500', ...sx }}
            >
                <NavigateBeforeIcon color="main" />
                {props.children || t('btn.back')}
            </Button>
        </Box>
    )
}

export default BackButtonInForm
