import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconUmbrella({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M23.5,14.91c-0.28,0-0.5-0.22-0.5-0.5c0-1.84-1.5-3.33-3.33-3.33s-3.33,1.5-3.33,3.33c0,0.28-0.22,0.5-0.5,0.5
		s-0.5-0.22-0.5-0.5c0-1.84-1.5-3.33-3.33-3.33s-3.33,1.5-3.33,3.33c0,0.28-0.22,0.5-0.5,0.5s-0.5-0.22-0.5-0.5
		c0-1.84-1.5-3.33-3.33-3.33S1,12.57,1,14.41c0,0.28-0.22,0.5-0.5,0.5S0,14.69,0,14.41c0-6.62,5.38-12,12-12s12,5.38,12,12
		C24,14.69,23.78,14.91,23.5,14.91z M12,10.08c1.66,0,3.11,0.94,3.83,2.31c0.73-1.37,2.17-2.31,3.83-2.31c1.08,0,2.08,0.4,2.84,1.06
		c-1.4-4.47-5.58-7.73-10.5-7.73S2.89,6.67,1.5,11.14c0.76-0.66,1.75-1.06,2.84-1.06c1.66,0,3.11,0.94,3.83,2.31
		C8.89,11.02,10.34,10.08,12,10.08z"
                />
                <path
                    d="M9.48,23.52c-1.76,0-3.19-1.43-3.19-3.19c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5c0,1.21,0.98,2.19,2.19,2.19
		s2.19-0.98,2.19-2.19V10.6c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v9.72C12.68,22.09,11.24,23.52,9.48,23.52z"
                />
                <path d="M12,3.41c-0.28,0-0.5-0.22-0.5-0.5V0.98c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v1.93C12.5,3.19,12.28,3.41,12,3.41z" />
            </svg>
        </SvgIcon>
    )
}
