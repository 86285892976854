import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconPerson({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        d="M14.35,24H9.65c-0.28,0-0.5-0.22-0.5-0.5v-7.84c-1.33-0.24-2.35-1.4-2.35-2.8V8.87c0-1.66,1.35-3,3-3h4.38
		c1.66,0,3,1.35,3,3v3.99c0,1.4-1.01,2.57-2.35,2.8v7.84C14.85,23.78,14.62,24,14.35,24z M10.15,23h3.69v-7.79
		c0-0.28,0.22-0.5,0.5-0.5c1.02,0,1.85-0.83,1.85-1.85V8.87c0-1.1-0.9-2-2-2H9.81c-1.1,0-2,0.9-2,2v3.99c0,1.02,0.83,1.85,1.85,1.85
		c0.28,0,0.5,0.22,0.5,0.5V23z"
                    />
                    <path d="M12,24c-0.28,0-0.5-0.22-0.5-0.5v-8.29c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v8.29C12.5,23.78,12.28,24,12,24z" />
                    <path
                        d="M9.65,15.71c-0.28,0-0.5-0.22-0.5-0.5V8.79c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v6.42
		C10.15,15.48,9.93,15.71,9.65,15.71z"
                    />
                    <path
                        d="M14.35,15.71c-0.28,0-0.5-0.22-0.5-0.5V8.79c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v6.42
		C14.85,15.48,14.62,15.71,14.35,15.71z"
                    />
                    <path
                        d="M12,5.85c-1.61,0-2.93-1.31-2.93-2.93S10.39,0,12,0c1.61,0,2.92,1.31,2.92,2.93S13.61,5.85,12,5.85z M12,1
		c-1.06,0-1.93,0.86-1.93,1.93S10.94,4.85,12,4.85s1.92-0.86,1.92-1.93S13.06,1,12,1z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
