import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconTheft({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M11.71,18.81c-4.24,0-7.68-3.45-7.68-7.69C4.02,7.22,7.19,0,11.71,0c4.52,0,7.69,7.22,7.69,11.12
		C19.39,15.36,15.95,18.81,11.71,18.81z M11.71,1C8.15,1,5.02,7.31,5.02,11.12c0,3.69,3,6.69,6.68,6.69c3.69,0,6.69-3,6.69-6.69
		C18.39,7.31,15.27,1,11.71,1z"
                />
                <path
                    d="M11.71,15.55c-3.07,0-5.22-4.85-5.22-7.48c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5c0,2.44,1.97,6.48,4.22,6.48
		s4.22-4.04,4.22-6.48c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5C16.93,10.7,14.78,15.55,11.71,15.55z"
                />
                <path
                    d="M18.89,12.06c-0.28,0-0.5-0.22-0.5-0.5c0-2.43-3-4.4-6.69-4.4c-3.69,0-6.68,1.97-6.68,4.4c0,0.28-0.22,0.5-0.5,0.5
		s-0.5-0.22-0.5-0.5c0-2.98,3.45-5.4,7.68-5.4c4.24,0,7.69,2.42,7.69,5.4C19.39,11.84,19.17,12.06,18.89,12.06z"
                />
                <g>
                    <path
                        d="M16.1,17.22c-0.12,0-0.25-0.05-0.34-0.14l-2.5-2.37c-0.2-0.19-0.21-0.51-0.02-0.71c0.19-0.2,0.51-0.21,0.71-0.02l2.5,2.37
			c0.2,0.19,0.21,0.51,0.02,0.71C16.37,17.17,16.24,17.22,16.1,17.22z"
                    />
                    <path
                        d="M7.32,17.22c-0.13,0-0.26-0.05-0.36-0.16c-0.19-0.2-0.18-0.52,0.02-0.71l2.5-2.37c0.2-0.19,0.52-0.18,0.71,0.02
			c0.19,0.2,0.18,0.52-0.02,0.71l-2.5,2.37C7.56,17.17,7.44,17.22,7.32,17.22z"
                    />
                </g>
                <path
                    d="M23.32,24c-0.23,0-0.43-0.15-0.49-0.38c-0.03-0.12-0.71-2.95-0.86-3.93c-0.17-1.04-0.97-1.46-1.01-1.48l-3.78-1.7
		c-0.25-0.11-0.36-0.41-0.25-0.66c0.11-0.25,0.41-0.36,0.66-0.25l3.79,1.71c0.07,0.03,1.32,0.66,1.57,2.23
		c0.15,0.94,0.84,3.82,0.85,3.85c0.06,0.27-0.1,0.54-0.37,0.6C23.4,24,23.36,24,23.32,24z"
                />
                <path
                    d="M0.68,24c-0.04,0-0.08,0-0.12-0.01c-0.27-0.06-0.43-0.33-0.37-0.6c0.01-0.03,0.7-2.92,0.85-3.85
		c0.25-1.57,1.5-2.19,1.56-2.22L6.4,15.6c0.25-0.11,0.55,0,0.66,0.25c0.11,0.25,0,0.55-0.25,0.66l-3.79,1.71
		C3,18.23,2.19,18.65,2.03,19.69c-0.16,0.97-0.83,3.81-0.86,3.93C1.11,23.85,0.9,24,0.68,24z"
                />
            </svg>
        </SvgIcon>
    )
}
