import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useFormContext } from '../FormContext'
import ChooseButton from '../../components/ChooseButton'

const Filters = ({handleClick}) => {
    const {
        transmission,
        drive,
        transmissionLabel,
        driveLabel,
        handleChangeDrive,
        handleChangeTransmission,
        handleDetailsChange,
        details,
        handleChangeLocalization,
        localization,
        localizationLabel,
        setDriveChecked,
        handleDriveChecked,
        driveChecked,
        filters
    } = useFormContext()
    const { t } = useTranslation()
    return (
        <Stack spacing={2}>
            <Box>
                <Box
                    sx={{
                        marginBottom: '15px',
                    }}
                >
                    <span className="font-bold">{t('LAC.step_one.label_localization')}</span>
                </Box>
                <Box
                    sx={{
                        height: '56px',
                        border: '1px solid #d1d1d1',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px',
                        mb: '20px',
                        gap: '15px',
                    }}
                >
                    <FormControl fullWidth>
                        <Select
                            IconComponent={ExpandMoreIcon}
                            displayEmpty
                            value={localizationLabel}
                            onChange={handleChangeLocalization}
                            variant="standard"
                            disableUnderline
                        >
                            <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                            {localization?.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Box>
                <Stack direction="row" spacing={2}>
                    <Box>
                        <Box sx={{ marginBottom: '15px' }}>
                            <span className="font-bold">{t('LAC.step_one.label_transmission')}</span>
                        </Box>
                        <Box
                            sx={{
                                height: '56px',
                                border: '1px solid #d1d1d1',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 20px',
                                mb: '20px',
                                gap: '15px',
                                minWidth: '200px',
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={transmissionLabel}
                                    variant="standard"
                                    disableUnderline
                                    onChange={handleChangeTransmission}
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(transmission).map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ marginBottom: '15px' }}>
                            <span className="font-bold">{t('LAC.step_one.label_drive')}</span>
                        </Box>
                        <Box
                            sx={{
                                height: '56px',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 0',
                                mb: '20px',
                                gap: '15px',
                                minWidth: '200px',
                            }}
                        >
                            {/* <FormControl fullWidth>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    displayEmpty
                                    value={driveLabel}
                                    onChange={handleChangeDrive}
                                    variant="standard"
                                    disableUnderline
                                >
                                    <MenuItem value="">{t('LAC.step_one.label_all')}</MenuItem>
                                    {Object.entries(drive).map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox disabled={!filters.is4x4Available} checked={driveChecked} onClick={() => handleDriveChecked()} />}
                                label={<Box sx={{textWrap: "nowrap"}} fontSize={16}>{t('LAC.offer_list.only_4x4')}</Box>}
                            />
                        </FormGroup>
                        </Box>
                    </Box>
                </Stack>
                <ChooseButton blueBtn handleClick={handleClick} type="button">{t('LAC.step_one.choose_button')}</ChooseButton>
            </Box>
            {false &&
            <Box>
                <Box
                    sx={{
                        marginBottom: '15px',
                    }}
                >
                    <span className="font-bold">{t('LAC.step_one.label_details')}</span>
                </Box>
                <Box
                    sx={{
                        height: '56px',
                        border: '1px solid #d1d1d1',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px',
                        mb: '20px',
                        gap: '15px',
                        minWidth: '200px',
                    }}
                >
                    <FormControl fullWidth>
                        <TextField
                            placeholder={t('LAC.step_one.details_placeholder')}
                            id="standard-basic"
                            variant="standard"
                            value={details}
                            onChange={handleDetailsChange}
                            InputProps={{ disableUnderline: true }}
                        />
                    </FormControl>
                </Box>
            </Box>
            }
        </Stack>
    )
}

export default Filters
