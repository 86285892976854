import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { ListItem, ListItemButton, ListItemText, Typography, IconButton, Box } from '@mui/material'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { DialogContext } from './SearchField'
import IconSearchResult from '../../components/IconSearchResult'

const PlaceListItem = ({ place, setClickedPlace, setShowMoreInfo }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext()

    const { handleCloseDialog, name } = useContext(DialogContext)

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{
                    px: 0,
                }}
                onClick={event => {
                    setFieldValue(name, place.name.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', ''))
                    setFieldValue(`${name}Place`, place)
                    setTimeout(() => setFieldTouched(name, true))
                    handleCloseDialog()
                }}
            >
                <IconSearchResult
                    icon={place.name.includes('PKS') ? 'Bus' : place.name.includes('Prom') ? 'Ferry' : place.type}
                    type="firstType"
                />
                <ListItemText
                    primary={place.city}
                    primaryTypographyProps={{ fontWeight: '700' }}
                    secondary={place.name.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', '')}
                    sx={{
                        pr: 2,
                    }}
                />

                <Box sx={{ display: { xs: 'block', lg: 'flex' }, alignItems: 'center', textAlign: 'right' }}>
                    <Typography fontSize="small" fontWeight="700" pr={1} sx={{ textWrap: 'nowrap' }}>
                        {place.priceIOH !== null ? `od ${place.priceIOH}zł` : ''}
                    </Typography>

                    <IconButton
                        aria-label="info"
                        color="secondary"
                        onClick={event => {
                            event.stopPropagation()
                            setClickedPlace(place)
                            setShowMoreInfo(true)
                        }}
                    >
                        <InfoOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </ListItemButton>
        </ListItem>
    )
}

export default PlaceListItem
