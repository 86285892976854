import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { List, TextField, InputAdornment, Divider } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { API } from '../../api'
import { environment } from '../../../environment'
import Spinner from '../../components/Spinner'
import PlaceListItem from './PlaceListItem'

const replaceDiacritics = text => {
    const diacriticsMap = {
        ą: 'a',
        ć: 'c',
        ę: 'e',
        ł: 'l',
        ń: 'n',
        ó: 'o',
        ś: 's',
        ź: 'z',
        ż: 'z',
    }

    return text.toLowerCase().replace(/[ąćęłńóśźż]/g, match => diacriticsMap[match] || match)
}

const GetReleasePlaces = ({ setShowMoreInfo, setClickedPlace }) => {
    const { t } = useTranslation()

    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        getReleasePlaces()
    }, [])

    useEffect(() => {
        data.length > 0 && setSearchResult(data)
    }, [data])

    useEffect(() => {
        setSearchResult(data.filter(item => replaceDiacritics(item.name).includes(replaceDiacritics(searchQuery))))
    }, [searchQuery])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('releasePlaces data:', data)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('releasePlaces searchResult:', searchResult)
    }, [searchResult])

    const getReleasePlaces = async () => {
        try {
            const response = await fetch(API['GET_RELEASE_PLACES'], {
                method: 'GET',
            })
            if (response.ok) {
                const res = await response.json()
                setData(
                    res.releasePlaces
                        .filter(item => !item.name.includes('Autopunkt'))
                        .filter(item => !item.name.includes('Miasto'))
                        .sort((placeA, placeB) =>
                            placeA.city.toLowerCase().trim().localeCompare(placeB.city.toLowerCase().trim())
                        )
                )
            } else {
                console.error('Error fetching data')
            }
        } catch (error) {
            console.error('Error fetching data', error)
            setError(error)
        }
    }

    return (
        <>
            <>
                {data.length > 0 ? (
                    <>
                        <div className="searchField">
                            <TextField
                                label={t('RAC.step_one.label_search')}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={e => setSearchQuery(e.target.value)}
                                sx={{ width: '100%', mb: 1 }}
                            />
                        </div>
                        <List>
                            {searchResult
                                .filter(place => place.priceIOH === 0)
                                .map(place => (
                                    <PlaceListItem
                                        key={place.id}
                                        place={place}
                                        setClickedPlace={setClickedPlace}
                                        setShowMoreInfo={setShowMoreInfo}
                                    />
                                ))}

                            {/* <Divider /> */}

                            {searchResult
                                .filter(place => place.priceIOH > 0 && place.priceIOH < 85)
                                .map(place => (
                                    <PlaceListItem
                                        key={place.id}
                                        place={place}
                                        setClickedPlace={setClickedPlace}
                                        setShowMoreInfo={setShowMoreInfo}
                                    />
                                ))}

                            {/* <Divider /> */}

                            {searchResult
                                .filter(place => place.priceIOH >= 85)
                                .map(place => (
                                    <PlaceListItem
                                        key={place.id}
                                        place={place}
                                        setClickedPlace={setClickedPlace}
                                        setShowMoreInfo={setShowMoreInfo}
                                    />
                                ))}

                            {/* <Divider /> */}

                            {searchResult
                                .filter(place => place.priceIOH === null)
                                .map(place => (
                                    <PlaceListItem
                                        key={place.id}
                                        place={place}
                                        setClickedPlace={setClickedPlace}
                                        setShowMoreInfo={setShowMoreInfo}
                                    />
                                ))}
                        </List>
                    </>
                ) : (
                    <Spinner />
                )}

                {error && <p className="error">{t('errors.try_again')}</p>}
            </>
        </>
    )
}

export default GetReleasePlaces
