import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import IconFixedFee from '../../svg/IconFixedFee'
import ReservationDetailsBoxIcon from '../../../components/ReservationDetailsBoxIcon'

const ReservationDetailsFixedFeePrice = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { locale } = useParams()

    const priceFixedFee = useFormattedPrice(parseFloat(processData?.summary_car_preparing_price).toFixed(2)) || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    return (
        <Box
            width="100%"
            pl={{ xs: 1, lg: 2 }}
            pr={2}
            py={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid #d1d1d1`}
            alignItems="center"
        >
            <>
                <Box display="flex" alignItems="center">
                    <ReservationDetailsBoxIcon>
                        <IconFixedFee />
                    </ReservationDetailsBoxIcon>
                    <Box alignSelf="center">
                        <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                            {t('RAC.step_three.fixed_fee')}
                        </Typography>
                        <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'normal'}>
                            {t('RAC.step_three.prepering')}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'} textAlign={'right'}>
                        {priceFixedFee}
                    </Typography>
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsFixedFeePrice
