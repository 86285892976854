import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import { API, FORM_KEY } from '../../api'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import EditButton from '../../components/EditButton'
import IconCar from '../../svg/IconCar'
import ReservationDetailsBoxIcon from '../../../components/ReservationDetailsBoxIcon'

const ReservationDetailsCar = ({ processData }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const formattedRentPriceFullCalc = useFormattedPrice(processData?.full_calculation_price_rent)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        console.log(processData)
    }, [])

    const handleClickEditStep2 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step2']}`, 'GET')
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            width="100%"
            pl={{ xs: 1, lg: 2 }}
            pr={2}
            py={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid #d1d1d1`}
            alignItems="center"
        >
            <>
                <Box display="flex" alignItems="center">
                    <ReservationDetailsBoxIcon>
                        <IconCar />
                    </ReservationDetailsBoxIcon>
                    {processData ? (
                        <Box alignSelf="center">
                            <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                                {t('RAC.step_two.class')} {processData?.car_class_name || ''}
                            </Typography>
                            <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'normal'}>
                                {processData?.model?.split(' ').slice(0, 2).join(' ') || processData?.model || ''}{' '}
                                {t('RAC.step_two.others')}
                            </Typography>
                            {/* <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'normal'}>
                                {processData?.transmission}, {processData?.seats}{' '}
                                {t('RAC.step_two.car_equipment.seats')} 
                            </Typography> */}
                        </Box>
                    ) : (
                        <Spinner size={20} sx={{ m: 0 }} />
                    )}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'} textAlign={'right'}>
                        {formattedRentPriceFullCalc}
                    </Typography>
                    <EditButton handleOnClick={handleClickEditStep2} isSubmitting={loading} />
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsCar
