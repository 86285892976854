import React, { useEffect, useState } from 'react'

const useFormattedPrice = price => {
    const [formattedPrice, setFormattedPrice] = useState('')

    useEffect(() => {
        if (price === undefined || price === null || isNaN(price)) {
            setFormattedPrice('')
            return
        }

        const priceParts = price.split('.')
        const integerPart = priceParts[0]
        let decimalPart = priceParts[1]

        if (parseInt(decimalPart, 10) === 0) {
            decimalPart = ''
        }

        const formatted = `${integerPart}${decimalPart !== '' ? `,${decimalPart}` : ''} zł`
        setFormattedPrice(formatted)
    }, [price])

    return formattedPrice
}

export default useFormattedPrice


export const formatPrice = value => {
    if (value === undefined || value === null || isNaN(value)) {
        return ''
    }

    const price = parseFloat(value).toFixed(2).replace('.', ',')

    const priceParts = price.split(',')
    const integerPart = priceParts[0]
    let decimalPart = priceParts[1]

    if (parseInt(decimalPart, 10) === 0) {
        decimalPart = ''
    }

    const formatted = `${integerPart}${decimalPart !== '' ? `,${decimalPart}` : ''} zł`

    return formatted
}
