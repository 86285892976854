import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { Field } from 'formik'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { useTranslation } from 'react-i18next'
import MuiPhoneInput from '../MuiPhoneInput'

export default function FormInd({ userData, handleUserDataChange, touched, errors, noPopup = false }) {
    const { t } = useTranslation()
    const locale = window.location.href.includes('/en/') ? 'en' : 'pl'

    return (
        <div className="mt-8 ">
            <div className={`grid ${noPopup ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2'} gap-6`}>
                <Field
                    as={TextField}
                    value={userData.userFirstName}
                    name="userFirstName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userFirstName && Boolean(errors.userFirstName)}
                    helperText={touched.userFirstName && errors.userFirstName}
                    fullWidth
                    label={t('LAC.client_data.name')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userLastName}
                    name="userLastName"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userLastName && Boolean(errors.userLastName)}
                    helperText={touched.userLastName && errors.userLastName}
                    fullWidth
                    label={t('LAC.client_data.surname')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Field
                    as={TextField}
                    value={userData.userEmail}
                    name="userEmail"
                    onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                    error={touched.userEmail && Boolean(errors.userEmail)}
                    helperText={touched.userEmail && errors.userEmail}
                    fullWidth
                    label={t('LAC.client_data.email')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <Tooltip title={t('LAC.client_data.validation.prefix')}>
                    <Field
                        as={TextField}
                        value={userData.userPhoneNumber}
                        name="userPhoneNumber"
                        onChange={e => handleUserDataChange(e.target.name, e.target.value)}
                        error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                        helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                        fullWidth
                        label={t('LAC.client_data.phone')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneInTalkOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip> */}
                <MuiPhoneInput
                    name="userPhoneNumber"
                    error={touched.userPhoneNumber && Boolean(errors.userPhoneNumber)}
                    helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                    handleUserDataChange={handleUserDataChange}
                    LAC
                    locale={locale}
                    withPlus
                />
            </div>
        </div>
    )
}
