import React, { createContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import InputAdornment from '@mui/material/InputAdornment'
import { Box, Button } from '@mui/material'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import MuiTextField from '../../../components/MuiTextField'
import DialogLocation from './DialogLocation'
import theme from '../../../styles/MUI-theme'
import GeolocationSearchfieldRac from './GeolocationSearchfieldRac'

export const DialogContext = createContext({
    openDialog: false,
    autoHeight: false,
    setAutoHeight: () => {},
    handleClickOpenDialog: () => {},
    handleCloseDialog: () => {},
})

const SearchField = ({ name, placeholder, locale, ...props }) => {
    const { t } = useTranslation()
    const [meta] = useField(name)
    const [openDialog, setOpenDialog] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [autoHeight, setAutoHeight] = useState(false)

    useEffect(() => {
        meta.value !== '' && setIsDisabled(true)
    }, [meta])

    const handleClickOpenDialog = () => {
        setOpenDialog(true)
        setIsDisabled(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const contextValue = {
        name,
        openDialog,
        handleClickOpenDialog,
        handleCloseDialog,
        locale,
        autoHeight,
        setAutoHeight
    }

    return (
        <DialogContext.Provider value={contextValue}>
            <Box className="searchField">
                <MuiTextField
                    disabled={isDisabled}
                    name={name}
                    label={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PlaceOutlinedIcon sx={{ color: theme.palette.primary.dark, mx: 1 }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {isDisabled && (
                                    <Button
                                        onClick={handleClickOpenDialog}
                                        sx={{ fontSize: { xs: 12, lg: 14 }, fontWeight: 'bold' }}
                                    >
                                        {t('btn.edit')}
                                    </Button>
                                )}
                                <GeolocationSearchfieldRac />
                            </InputAdornment>
                        ),
                    }}
                    onClick={handleClickOpenDialog}
                    {...props}
                />
            </Box>
            <DialogLocation />
        </DialogContext.Provider>
    )
}

export default SearchField
