import React from 'react'
import setLoginLink from '../RAC/setLoginLink'
import { useTranslation } from 'react-i18next'
import ChooseButton from './ChooseButton'

const LoginButton = ({ ...props }) => {
    const { t } = useTranslation()
    return (
        <ChooseButton
            blueBtn
            handleClick={e => {
                e.preventDefault()
                window.location.href = setLoginLink()
            }}
            {...props}
        >
            {t('btn.login')}
        </ChooseButton>
    )
}

export default LoginButton
