import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconLuggage({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        class="st0"
                        d="M17.91,22.51H6.09c-1.34,0-2.43-1.09-2.43-2.43V8.83c0-1.34,1.09-2.43,2.43-2.43h11.82
		c1.34,0,2.43,1.09,2.43,2.43v11.25C20.34,21.42,19.25,22.51,17.91,22.51z M6.09,7.4c-0.79,0-1.43,0.64-1.43,1.43v11.25
		c0,0.79,0.64,1.43,1.43,1.43h11.82c0.79,0,1.43-0.64,1.43-1.43V8.83c0-0.79-0.64-1.43-1.43-1.43H6.09z"
                    />
                    <path
                        class="st0"
                        d="M16.95,6.9h-1V2.43c0-0.79-0.64-1.43-1.43-1.43H9.48C8.69,1,8.05,1.64,8.05,2.43V6.9h-1V2.43
		C7.05,1.09,8.14,0,9.48,0h5.04c1.34,0,2.43,1.09,2.43,2.43V6.9z"
                    />
                    <path d="M6.09,24c-0.28,0-0.5-0.22-0.5-0.5v-1.49c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v1.49C6.59,23.78,6.37,24,6.09,24z" />
                    <path d="M17.95,24c-0.28,0-0.5-0.22-0.5-0.5v-1.49c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v1.49C18.45,23.78,18.22,24,17.95,24z" />
                    <g>
                        <path d="M15.71,12.92H8.29c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h7.41c0.28,0,0.5,0.22,0.5,0.5S15.98,12.92,15.71,12.92z" />
                        <path d="M15.71,16.99H8.29c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h7.41c0.28,0,0.5,0.22,0.5,0.5S15.98,16.99,15.71,16.99z" />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
