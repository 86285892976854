import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, Button, Typography, Alert } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../../../components/ErrorBox'
import { API } from '../../api'
import PaymentVerification from './PaymentVerification'

const CheckPaymentStatusRAC = ({ setAfterPayment, setRetryToPay }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetch()

    const [status, setStatus] = useState('pending') 
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [checkingIsStopped, setCheckingIsStopped] = useState(false)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        status === 'success' && setCheckingIsStopped(true)
    }, [status])

    useEffect(() => {
        const interval = setInterval(() => { 

            !checkingIsStopped &&
                fetch(`${API[`GET_PAYMENT_STATUS`]}?pid=${id}`)
                    .then(response => response.json())
                    .then(data => {
                        const { status } = data
                        setStatus(status)

                        if (status === 'success') {
                            clearInterval(interval)
                            fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`)

                        } else if (status === 'expired') {
                            clearInterval(interval) 
                            setOpenErrorDialog(true)
                            setCheckingIsStopped(true) 
                            return
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching payment status:', error)
                        clearInterval(interval)
                    }) 
        }, 5000)

        return () => clearInterval(interval)
    }, [id, checkingIsStopped])

    const handleClose = () => {
        setOpenErrorDialog(false)
        setAfterPayment(false)
        setRetryToPay(true)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return !loading ? (
        <>
            {status === 'pending' && (
                <PaymentVerification />
            )}
           <Dialog open={openErrorDialog} onClose={handleClose}>
                <DialogTitle>{t('errors.payment_error_title')}</DialogTitle>
                <DialogContent sx={{ maxWidth: '500px'}}><Alert severity='error'>{t('errors.payment_error_message')}</Alert></DialogContent>
                <Button onClick={handleClose}>{t('btn.close')}</Button>
            </Dialog>
        </>
    ) : (
        <Spinner />
    )
}

export default CheckPaymentStatusRAC
