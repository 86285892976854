import Box from '@mui/material/Box'
import { useState } from 'react'
import useHostname from '../../../portlets/offersList/hooks/useHostname'

function ImageCar({ example }) {
    const origin = useHostname()
    const [imgSrc, setImgSrc] = useState(example?.imagePaths[0])

    return (
        <Box
            component="img"
            alt={example.model}
            src={imgSrc}
            onError={() => setImgSrc(`${origin}/images/car-rent/test-car.jpg`)}
            sx={{ width: '100%', maxWidth: '350px', height: 'auto', borderRadius: '20px', mt: 1, mb: 2 }}
        />
    )
}

export default ImageCar
