import { Box } from '@mui/material'
import React from 'react'

const EquipmentItemBoxIcon = ({ children, ...sx }) => (
    <Box p={{ xs: 0.5, lg: 1 }} sx={{ ...sx }}>
        {children}
    </Box>
)

export default EquipmentItemBoxIcon
