import * as Yup from 'yup'

const validationSchema = (t, isActiveInsurance) => {
    return Yup.object().shape({
        userFirstName: Yup.string().when('clientType', {
            is: clientType => clientType === 'ind',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.name_1'))
                    .min(2, t('LAC.client_data.validation.name_2'))
                    .max(80, t('LAC.client_data.validation.name_3'))
                    .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.name_2')),
            otherwise: schema => schema.optional(),
        }),
        userLastName: Yup.string().when('clientType', {
            is: clientType => clientType === 'ind',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.surname_1'))
                    .min(2, t('LAC.client_data.validation.surname_2'))
                    .max(80, t('LAC.client_data.validation.surname_3'))
                    .matches(/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, t('LAC.client_data.validation.surname_2')),
            otherwise: schema => schema.optional(),
        }),
        userEmail: Yup.string()
            .email(t('LAC.client_data.validation.email'))
            .required(t('LAC.client_data.validation.email')),
        userPhoneNumber: Yup.string()
            .matches(/^(?=(?:.*\d){11,})^[\d+()\- ]{11,17}$/, t('LAC.client_data.validation.phone_3'))
            .required(t('LAC.client_data.validation.phone_2')),
        companyName: Yup.string().when('clientType', {
            is: value => value === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.company_name'))
                    .max(200, t('LAC.client_data.validation.company_name')),
            otherwise: schema => schema.optional(),
        }),
        userNip: Yup.string().when('clientType', {
            is: clientType => clientType === 'business',
            then: schema =>
                schema
                    .required(t('LAC.client_data.validation.nip_3'))
                    .matches(/^\d{10}$/, t('LAC.client_data.validation.nip_1'))
                    .test('isValidNIP', t('LAC.client_data.validation.nip_2'), value => {
                        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
                        const digits = value.toString().split('').map(Number)
                        const checksum =
                            digits.reduce((acc, digit, index) => {
                                if (index === 9) return acc
                                return acc + digit * weights[index]
                            }, 0) % 11
                        return checksum === digits[9]
                    }),
            otherwise: schema => schema.optional(),
        }),
        insurance: Yup.string().when([], {
            is: () => isActiveInsurance,
            then: schema => 
                schema
                    .required(t('LAC.popup_contact.protection_error'))
                    .matches(/^\d+$/, t("LAC.popup_contact.protection_error")),
            otherwise: schema => schema.optional(),
        }),
    })
}

export default validationSchema
