import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconCarPlus({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        d="M21.68,24h-2.69c-0.66,0-1.21-0.54-1.21-1.21v-0.83c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v0.83
			c0,0.11,0.09,0.21,0.21,0.21h2.69c0.11,0,0.21-0.09,0.21-0.21v-3.87c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v3.87
			C22.89,23.46,22.34,24,21.68,24z"
                    />
                    <path
                        d="M5.01,24H2.32c-0.66,0-1.21-0.54-1.21-1.21v-3.87c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v3.87
			c0,0.11,0.09,0.21,0.21,0.21h2.69c0.11,0,0.21-0.09,0.21-0.21v-0.83c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v0.83
			C6.21,23.46,5.67,24,5.01,24z"
                    />
                    <path
                        d="M20.55,22.15H3.45c-1.29,0-2.33-1.05-2.33-2.33v-5.1c0-0.56,0.27-1.3,0.63-1.73l0.22-0.26c0.26-0.31,0.63-0.87,0.81-1.23
			L5.09,6.9C5.38,6.34,6.07,5.91,6.7,5.91h5.64c0.28,0,0.5,0.22,0.5,0.5s-0.22,0.5-0.5,0.5H6.7c-0.25,0-0.6,0.21-0.71,0.44
			l-2.31,4.58c-0.22,0.43-0.63,1.05-0.94,1.42l-0.22,0.26c-0.21,0.25-0.4,0.77-0.4,1.09v5.1c0,0.74,0.6,1.33,1.33,1.33h17.1
			c0.74,0,1.33-0.6,1.33-1.33v-5.1c0-0.32-0.19-0.85-0.4-1.09l-0.21-0.25c-0.31-0.38-0.71-1.01-0.92-1.45l-0.47-1.03
			c-0.12-0.25-0.01-0.55,0.24-0.66c0.25-0.12,0.55-0.01,0.66,0.24l0.47,1.03c0.17,0.36,0.52,0.92,0.78,1.23l0.21,0.25
			c0.36,0.43,0.63,1.18,0.63,1.74v5.1C22.89,21.1,21.84,22.15,20.55,22.15z"
                    />
                    <path
                        d="M2.37,13.17c-0.11,0-0.22-0.03-0.31-0.11l-0.67-0.52c-0.43-0.34-0.86-0.95-0.86-1.48C0.53,10.45,1.23,10,2.2,10h1.18
			c0.28,0,0.5,0.22,0.5,0.5S3.66,11,3.38,11H2.2c-0.34,0-0.56,0.08-0.66,0.14c0.05,0.15,0.22,0.43,0.46,0.62l0.67,0.52
			c0.22,0.17,0.26,0.48,0.09,0.7C2.67,13.11,2.52,13.17,2.37,13.17z"
                    />
                    <path
                        d="M21.58,13.17c-0.15,0-0.3-0.07-0.39-0.19c-0.17-0.22-0.13-0.53,0.09-0.7l0.7-0.55c0.26-0.2,0.44-0.51,0.49-0.67
			c-0.1-0.06-0.34-0.16-0.71-0.16h-1.22c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h1.22c1,0,1.72,0.46,1.72,1.08
			c0,0.54-0.45,1.18-0.89,1.52l-0.7,0.55C21.79,13.14,21.68,13.17,21.58,13.17z"
                    />
                    <path
                        d="M15.92,14.36H8.08c-1.42,0-4.28-0.15-4.99-0.7l-0.76-0.6c-0.22-0.17-0.26-0.48-0.09-0.7c0.17-0.22,0.49-0.25,0.7-0.09
			l0.76,0.6c0.29,0.2,2.14,0.49,4.38,0.49h7.85c2.24,0,4.09-0.29,4.38-0.49l0.76-0.59c0.21-0.17,0.53-0.13,0.7,0.09
			s0.13,0.53-0.09,0.7l-0.76,0.6C20.21,14.22,17.34,14.36,15.92,14.36z"
                    />
                    <path
                        d="M16.21,22.15c-0.17,0-0.33-0.08-0.42-0.23l-0.98-1.54H9.2l-0.98,1.54c-0.15,0.23-0.46,0.3-0.69,0.15
			c-0.23-0.15-0.3-0.46-0.15-0.69l1.28-2.01h6.71l1.28,2.01c0.15,0.23,0.08,0.54-0.15,0.69C16.39,22.12,16.3,22.15,16.21,22.15z"
                    />
                    <g>
                        <path d="M7.14,17.42H5.03c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h2.12c0.28,0,0.5,0.22,0.5,0.5S7.42,17.42,7.14,17.42z" />
                        <path d="M18.97,17.42h-2.12c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h2.12c0.28,0,0.5,0.22,0.5,0.5S19.25,17.42,18.97,17.42z" />
                    </g>
                </g>
                <path
                    d="M17.65,11.63c-3.21,0-5.82-2.61-5.82-5.82S14.44,0,17.65,0s5.82,2.61,5.82,5.82S20.86,11.63,17.65,11.63z M17.65,1
		c-2.66,0-4.82,2.16-4.82,4.82s2.16,4.82,4.82,4.82c2.66,0,4.82-2.16,4.82-4.82S20.3,1,17.65,1z"
                />
                <path
                    class="st1"
                    d="M17.71,8.69c-0.28,0-0.5-0.22-0.5-0.5V3.44c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v4.75
		C18.21,8.47,17.99,8.69,17.71,8.69z"
                />
                <path
                    class="st1"
                    d="M20.08,6.32h-4.75c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h4.75c0.28,0,0.5,0.22,0.5,0.5
		S20.36,6.32,20.08,6.32z"
                />
            </svg>
        </SvgIcon>
    )
}
