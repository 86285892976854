import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { environment, REACT_APP_RECAPTCHA } from './environment'
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/MUI-theme.js'
import i18n from './i18n'
import App from './Main'
import Header from './Header'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import FormStep1 from './forms/RAC/FormStep1'
import FormStep2 from './forms/RAC/FormStep2'
import FormStep3 from './forms/RAC/FormStep3'
import FormStep4 from './forms/RAC/FormStep4'
import FormStep5 from './forms/RAC/FormStep5.js'
import FormSummary from './forms/RAC/FormSummary'
import Notify from './forms/RAC/Notify'

import OffersListDetail from './portlets/offersList/detailsView/OffersListDetail.js'
import Step3Lac from './forms/LAC/Step3/Step3Lac'
import Step4Lac from './forms/LAC/Step4/Step4Lac.js'
import Step5Lac from './forms/LAC/Step5/Step5Lac'
import SummaryLac from './forms/LAC/SummaryLac'

import RemoveLoader from './RemoveLoader.js'

import './styles/styles.css'

const container = document.getElementById('page-wrapper')
const root = createRoot(container)

const View = ({ children, ...props }) => {
    return (
        <>
            <header id="header" className={props.noAbsoluteHeader && 'noAbsoluteHeader'}>
                <div id="infobar"></div>
                <Header line="online" />
            </header>

            <main>{children}</main>
            <footer id="footer">
                <Footer line="online" />
            </footer>
            <RemoveLoader />
        </>
    )
}

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ScrollToTop />

            <Routes>
                <Route path="/" element={<Navigate to={environment.mainPage} />} />
                <Route path="/en/" element={<Navigate to={environment.enMainPage} />} />
                <Route path="/o-nas/" element={<Navigate to={environment.aboutPage} />} />
                <Route path="/en/about-us/" element={<Navigate to={environment.enAboutPage} />} />
                <Route
                    path="*"
                    element={
                        <View>
                            <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA}>
                                <App />
                            </GoogleReCaptchaProvider>
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step1/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep1 edit={true} />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step2/b/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep1 versionB={true} />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step2/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep2 />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step3/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep3 />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step4/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep4 />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/step5/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormStep5 />
                        </View>
                    }
                />
                <Route
                    path="/:locale/RAC/summary/:id"
                    element={
                        <View noAbsoluteHeader>
                            <FormSummary />
                        </View>
                    }
                />
                <Route
                    path="/auto-dla-firmy/wynajem-elastyczny/:brand/:model/:id"
                    element={
                        <View noAbsoluteHeader>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                                <OffersListDetail />
                            </GoogleReCaptchaProvider>
                        </View>
                    }
                />
                <Route
                    path="/:locale/car-for-company/flexible-rental/:brand/:model/:id"
                    element={
                        <View noAbsoluteHeader>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                                <OffersListDetail />
                            </GoogleReCaptchaProvider>
                        </View>
                    }
                />
                <Route
                    path="/:locale/auto-dla-firmy/wynajem-elastyczny/krok-3/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step3Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/car-for-company/flexible-rental/step-3/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step3Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/auto-dla-firmy/wynajem-elastyczny/krok-4/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step4Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/car-for-company/flexible-rental/step-4/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step4Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/auto-dla-firmy/wynajem-elastyczny/krok-5/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step5Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/car-for-company/flexible-rental/step-5/:id"
                    element={
                        <View noAbsoluteHeader>
                            <Step5Lac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/auto-dla-firmy/wynajem-elastyczny/krok-6/:id"
                    element={
                        <View noAbsoluteHeader>
                            <SummaryLac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/car-for-company/flexible-rental/step-6/:id"
                    element={
                        <View noAbsoluteHeader>
                            <SummaryLac />
                        </View>
                    }
                />
                <Route
                    path="/:locale/krd_negative_verification"
                    element={
                        <View noAbsoluteHeader>
                            <Notify formKey="krd_negative_verification" />
                        </View>
                    }
                />
            </Routes>
        </ThemeProvider>
    </BrowserRouter>
    // </React.StrictMode>
)
