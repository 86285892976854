import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import EquipmentList from './EquipmentList'
import Prices from './Prices'
import theme from '../../../styles/MUI-theme'
import ImageCar from './ImageCar'

const CarClassItem = ({ carClass, example, offer, fuelTypes, transmissionTypes }) => {
    const { t } = useTranslation()

    return (
        <>
            {offer.map(
                calculation =>
                    calculation.classId === carClass.id.toString() && (
                        <Box
                            key={carClass.id}
                            p={{ xs: 2, sm: 4 }}
                            sx={{ border: '1px solid #ccc', borderRadius: '20px', bgcolor: 'white' }}
                            mb={6}
                        >
                            <Typography
                                variant="h2"
                                fontSize={{ xs: 14, md: 18 }}
                                fontWeight="bold"
                                textAlign="left"
                                textTransform="uppercase"
                                mb={1.5}
                                color="#505154"
                            >
                                {t('RAC.step_two.class')} {carClass.name}
                            </Typography>
                            <Box display={{ xs: 'block', md: 'flex' }}>
                                <Box width={{ xs: '100%', md: '50%' }} pr={2}>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 16, md: 28 },
                                            lineHeight: 1,
                                            color: theme.palette.secondary.main,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {example?.model.split(' ').slice(0, 2).join(' ') || example?.model}{' '}
                                    </Typography>
                                    <Box
                                        component="span"
                                        fontWeight="normal"
                                        color="#505154"
                                        sx={{ fontSize: { xs: 12, md: 18 } }}
                                    >
                                        {t('RAC.step_two.others')}
                                    </Box>
                                    <Box width={{ xs: '100%', md: '50%' }} height={'auto'}>
                                        {example.imagePaths[0] && <ImageCar example={example} />}
                                    </Box>
                                </Box>

                                <Box width={{ xs: '100%', md: '50%' }} maxWidth="350px">
                                    <EquipmentList
                                        fuel={carClass.fuelType}
                                        transmission={carClass.transmissionType}
                                        seats={carClass.seats}
                                        largeBags={carClass.largeBags}
                                        smallBags={carClass.smallBags}
                                        fuelTypes={fuelTypes}
                                        transmissionTypes={transmissionTypes}
                                    />
                                </Box>
                            </Box>
                            {offer.map(
                                calculation =>
                                    calculation.classId === carClass.id.toString() && (
                                        <Prices
                                            key={calculation.classId}
                                            item={calculation}
                                            carClass={carClass}
                                            model={example.model}
                                        />
                                    )
                            )}
                        </Box>
                    )
            )}
        </>
    )
}

export default CarClassItem
