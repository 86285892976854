import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconGearbox({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <g>
                    <path
                        d="M2.54,5.08c-1.4,0-2.54-1.14-2.54-2.54C-0.01,1.14,1.13,0,2.54,0s2.54,1.14,2.54,2.54C5.08,3.94,3.94,5.08,2.54,5.08z
		 M2.54,1C1.69,1,0.99,1.69,0.99,2.54c0,0.85,0.69,1.54,1.54,1.54s1.54-0.69,1.54-1.54C4.08,1.69,3.38,1,2.54,1z"
                    />
                    <path
                        d="M12,5.08c-1.4,0-2.54-1.14-2.54-2.54C9.46,1.14,10.6,0,12,0s2.54,1.14,2.54,2.54C14.54,3.94,13.4,5.08,12,5.08z M12,1
		c-0.85,0-1.54,0.69-1.54,1.54c0,0.85,0.69,1.54,1.54,1.54s1.54-0.69,1.54-1.54C13.54,1.69,12.85,1,12,1z"
                    />
                    <path
                        d="M2.54,24c-1.4,0-2.54-1.14-2.54-2.54c0-1.4,1.14-2.54,2.54-2.54s2.54,1.14,2.54,2.54C5.08,22.86,3.94,24,2.54,24z
		 M2.54,19.92c-0.85,0-1.54,0.69-1.54,1.54c0,0.85,0.69,1.54,1.54,1.54s1.54-0.69,1.54-1.54C4.08,20.61,3.38,19.92,2.54,19.92z"
                    />
                    <path
                        d="M12,24c-1.4,0-2.54-1.14-2.54-2.54c0-1.4,1.14-2.54,2.54-2.54s2.54,1.14,2.54,2.54C14.54,22.86,13.4,24,12,24z M12,19.92
		c-0.85,0-1.54,0.69-1.54,1.54c0,0.85,0.69,1.54,1.54,1.54s1.54-0.69,1.54-1.54C13.54,20.61,12.85,19.92,12,19.92z"
                    />
                    <path
                        d="M21.46,5.08c-1.4,0-2.54-1.14-2.54-2.54c0-1.4,1.14-2.54,2.54-2.54s2.54,1.14,2.54,2.54C24.01,3.94,22.87,5.08,21.46,5.08z
		 M21.46,1c-0.85,0-1.54,0.69-1.54,1.54c0,0.85,0.69,1.54,1.54,1.54s1.54-0.69,1.54-1.54C23.01,1.69,22.31,1,21.46,1z"
                    />
                    <path
                        d="M2.54,19.92c-0.28,0-0.5-0.22-0.5-0.5V4.58c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v14.83
		C3.04,19.69,2.81,19.92,2.54,19.92z"
                    />
                    <path
                        d="M12,19.92c-0.28,0-0.5-0.22-0.5-0.5V4.58c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v14.83C12.5,19.69,12.28,19.92,12,19.92
		z"
                    />
                    <path
                        d="M21.46,12.5H2.54c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h18.43V4.58c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V12
		C21.96,12.28,21.74,12.5,21.46,12.5z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
