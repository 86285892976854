import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, LinearProgress, Typography } from '@mui/material'
import useHostname from '../../../portlets/offersList/hooks/useHostname'

const PaymentVeryfication = () => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const origin = useHostname()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    return (
        <Box
            sx={{
                backgroundImage: `url('${origin}/images/bg-payment-veryfication-p24.png')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                height: { xs: '400px', md: '800px' },
            }}
            borderBottom="1px solid #ccc"
        >
            <Box sx={{ opacity: 0.7 }}>
                <LinearProgress />
            </Box>
            <Typography
                fontSize={{xs: 16, md: 22}}
                color="#fff" 
                textAlign="center"
                textTransform="uppercase"
                sx={{ opacity: 0.8 }}
                mt={{xs: 4, md: 8}}
                px={4}
            >
                {t('payment_verification')}
            </Typography>
        </Box>
    )
}

export default PaymentVeryfication
