import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconWindowDamage({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    d="M22.89,18.61c-0.28,0-0.5-0.22-0.5-0.5v-3.52c0-0.34-0.2-0.9-0.42-1.16l-0.22-0.27c-0.32-0.39-0.74-1.05-0.96-1.51
		l-2.21-4.78c-0.11-0.24-0.47-0.47-0.74-0.47H6.44c-0.27,0-0.65,0.23-0.77,0.47l-2.42,4.81c-0.23,0.45-0.66,1.1-0.98,1.49
		l-0.23,0.27c-0.22,0.26-0.42,0.81-0.42,1.16v3.52c0,0.28-0.22,0.5-0.5,0.5s-0.5-0.22-0.5-0.5v-3.52c0-0.58,0.28-1.36,0.66-1.8
		l0.23-0.28c0.27-0.32,0.66-0.91,0.85-1.29l2.42-4.81c0.29-0.58,1.01-1.02,1.66-1.02h11.39c0.66,0,1.37,0.45,1.64,1.05l2.21,4.78
		c0.18,0.38,0.55,0.97,0.82,1.29l0.22,0.27c0.37,0.44,0.65,1.22,0.65,1.8v3.52C23.39,18.39,23.16,18.61,22.89,18.61z"
                />
                <path
                    d="M1.91,12.95c-0.11,0-0.22-0.03-0.31-0.11L0.9,12.3C0.45,11.95,0,11.31,0,10.76c0-0.63,0.73-1.09,1.73-1.09h1.23
		c0.28,0,0.5,0.22,0.5,0.5s-0.22,0.5-0.5,0.5H1.73c-0.38,0-0.62,0.1-0.72,0.16c0.05,0.16,0.23,0.47,0.5,0.67l0.7,0.55
		c0.22,0.17,0.26,0.48,0.09,0.7C2.2,12.89,2.05,12.95,1.91,12.95z"
                />
                <path
                    d="M22.04,12.95c-0.15,0-0.29-0.07-0.39-0.19c-0.17-0.22-0.13-0.53,0.09-0.7l0.73-0.57C22.79,11.23,23,10.82,23,10.7
		c-0.03,0.03-0.33-0.11-0.78-0.11h-1.28c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h1.28c1.03,0,1.78,0.47,1.78,1.11
		c0,0.56-0.46,1.22-0.92,1.58l-0.73,0.57C22.25,12.92,22.15,12.95,22.04,12.95z"
                />
                <path
                    d="M16.11,14.2H7.89c-1.72,0-4.53-0.19-5.22-0.73l-0.8-0.62c-0.22-0.17-0.26-0.48-0.09-0.7c0.17-0.22,0.48-0.26,0.7-0.09
		l0.8,0.62c0.3,0.21,2.24,0.52,4.6,0.52h8.22c2.36,0,4.3-0.31,4.6-0.52l0.8-0.62c0.21-0.17,0.53-0.13,0.7,0.09
		c0.17,0.22,0.13,0.53-0.09,0.7l-0.8,0.62C20.64,14.01,17.83,14.2,16.11,14.2z"
                />
                <path
                    d="M11.39,11.49c-0.13,0-0.26-0.05-0.35-0.15c-0.2-0.2-0.2-0.51,0-0.71l0.72-0.72h-0.72c-0.2,0-0.38-0.12-0.46-0.31
		c-0.08-0.19-0.03-0.4,0.11-0.54l1.46-1.46c0.2-0.2,0.51-0.2,0.71,0s0.2,0.51,0,0.71l-0.61,0.61h0.72c0.2,0,0.38,0.12,0.46,0.31
		c0.08,0.19,0.03,0.4-0.11,0.54l-1.57,1.57C11.65,11.44,11.52,11.49,11.39,11.49z"
                />
            </svg>
        </SvgIcon>
    )
}
