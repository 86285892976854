import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useHostname from './hooks/useHostname'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import { environment } from '../../environment'
import Image from '../../components/Image'
import TextLinks from '../../components/TextLinks'
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import PopupContact from '../../components/PopupContact'
import Svg from '../../components/Svg.js'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

export default function OffersListLTRItem({ item, listAsGrid, params, tags, fuelTypes, transmission, button, carClasses }) {
    const { t } = useTranslation()
    const origin = useHostname()
    let urlToDetails = '/auto-dla-firmy/wynajem-dlugoterminowy/'
    let imgUrl = item.hasOwnProperty('Image')
            ? item['Image'].hasOwnProperty('link')
                ? item['Image']['link']
                : environment.defaultImage
            : environment.defaultImage;
    imgUrl = imgUrl;
    let Name = item.hasOwnProperty('Name') ? item['Name'] : '';
    let AltText = item.hasOwnProperty('AltText') 
        ? (item['AltText'] === ''
            ? (Name === '' ? 'Express Offers Image' : Name)
            : item['AltText'])
        : (Name === '' ? 'Express Offers Image' : Name);        

    let PriceBrutto = item.hasOwnProperty('PriceBrutto') ? item['PriceBrutto'] : '',
        PriceNetto = item.hasOwnProperty('PriceNetto') ? item['PriceNetto'] : '',
        InitialBrutto = item.hasOwnProperty('InitialBrutto') ? item['InitialBrutto'] : '',
        InitialNetto = item.hasOwnProperty('InitialNetto') ? item['InitialNetto'] : '';
    //params?.locale === 'pl' ? `/auto-dla-firmy/wynajem-elastyczny/${item.makeName}/${item.modelName}/${item?.id}` : `/en/car-for-company/flexible-rental/${item.makeName}/${item.modelName}/${item?.id}`
    const [showDialogAccountExist, setShowDialogAccountExist] = useState(false)
 
    function Tag() {
        if (tags && tags[0] !== '' && tags[1] !== '') {
            return (
                <span className={'super-item b-color'+tags[2]}>
                    <IconTag />
                    {tags[1]}
                </span>
            )
        }
        else {
            return ''
        }
    }
    function IconTag() {
        switch (tags[0]) {
            case '1':
                return (
                   <Svg name="fewcars" />
                )
            case '2':
                return (
                    <LocalOfferOutlinedIcon/>
                )
            case '3':
                return (
                    <LocalOfferOutlinedIcon />
                )
            case '4':
                return (
                    <CardMembershipOutlinedIcon/>
                )
            case '5':
                return (
                    <AllInclusiveIcon/>
                )
            case '6':
                return (
                    <AdsClickOutlinedIcon/>
                )
            default:
                return (
                    <LocalOfferOutlinedIcon/>
                )
        }
    }
    return (
        <div
            className={`border border-[#c7c7c7] rounded-[20px] overflow-hidden flex ${listAsGrid ? 'flex-col' : 'flex-col lg:flex-row'}`}
        >   
            <Tag />
            
            <div
                className={`p-6 flex ${listAsGrid ? 'flex-col h-full' : 'gap-8 w-full justify-between flex-wrap lg:flex-nowrap'}`}
            >
                <div className='offboxes'>
                    <h4 className="c-color1 font-semibold text-2xl">{t('LAC.offer_list.label_class')} {item?.Class}</h4>
                    {showDialogAccountExist && 
                        <PopupContact
                            showAlert={showDialogAccountExist}
                            setShowAlert={setShowDialogAccountExist}
                            dialogTitle={t("LAC.popup_contact.title")}
                            idOffer={item?.Id}
                            buttonId="W2"
                        />
                    }
                    <span className="opacity-60 c-color1">{item?.Name}</span>
                    <div className="opacity-60 description mt-4">
                        <TextLinks text={item?.Description} line={params.line} />
                    </div>
                    <div className="flex flex-wrap flex-shrink-0 gap-4 mt-4">
                        {/*<CarDetails
                            numberDoors={item.Doors}
                            numberSeats={item.Seats}
                            fuel={item.Fuel}
                            transmission={item.Gearbox}
                            fuelTypes={fuelTypes}
                            transmissionArray={transmission}
                        />*/}
                        <>
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <DirectionsCarFilledOutlinedIcon fontSize="small" />
                                <span>
                                    {item?.Doors}
                                </span>
                            </div>
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <PersonOutlineOutlinedIcon fontSize="small" />
                                <span>
                                    {item?.Seats}
                                </span>
                            </div>
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <LocalGasStationOutlinedIcon  fontSize="small" />
                                <span>{item?.Fuel}</span>
                            </div>
                            <div className="border text-[10px] text-[#919191] border-[#c7c7c7] rounded-full px-2 py-1 flex items-center gap-x-1.5">
                                <SettingsOutlinedIcon fontSize="small" />
                                <span>{item?.Gearbox}</span>
                            </div>
                            
                        </>
                    </div>
                </div>
                <div className='offboxes'>
                    <Image className={`${!listAsGrid ? '' : ''} object-cover`} src={imgUrl} alt={AltText} />
                </div>
                <div className={`${listAsGrid ? 'mt-auto offboxes' : 'flex flex-col offboxes items-start lg:items-center'}`}>
                    <h4 className="opacity-80 ml-2 text-center">{t('LAC.offer_list.label_price_loan')}</h4>
                    <div className="mt-4 text-left lg:text-center">
                        <span className="c-color3 text-3xl font-bold">
                            {PriceNetto.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-lg"> zł</small>
                        </span>
                        <span className="opacity-50 ml-2">{t('LAC.offer_list.label_netto2_price')}</span>
                        &nbsp;/&nbsp;
                        <span className="c-color3 text-3xl font-bold">
                            {PriceBrutto.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-lg"> zł</small>
                        </span>
                        <span className="opacity-50 ml-2">{t('LAC.offer_list.label_brutto2_price')}</span>
                        <br />
                        <span className="opacity-50 lg:ml-2">{t('LAC.offer_list.label_monthly')}</span>
                    </div>
                    {/* <div className="mt-4 text-left lg:text-center">
                        <span className="opacity-50 ml-0 lg:ml-2">{t('LAC.offer_list.label_initial')}</span>
                        <br />
                        <span className="text-[#919191] text-xs font-bold">
                            {InitialNetto.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-xs"> zł {t('LAC.offer_list.label_netto2_price')}</small>
                        </span>
                        &nbsp;/&nbsp;
                        <span className="text-[#919191] text-xs font-bold">
                            {InitialBrutto.toLocaleString('pl-PL', { useGrouping: true })}
                            <small className="text-xs"> zł {t('LAC.offer_list.label_brutto2_price')}</small>
                        </span>
                    </div> */}

                    <div className={`flex items-center justify-between ${listAsGrid ? 'mt-4' : 'mt-4 flex-col mb-4'}`}>
                        <a
                            className={'btn-link'}
                            href={'/'}
                            id="Z2"
                            onClick={e => {
                                e.preventDefault()
                                setShowDialogAccountExist(true)
                            }}
                        >
                            {t('LAC.offer_list.label_ask_offer')}
                        </a>
                    </div>
                    <div className="opacity-60 after-text">
                        <TextLinks text={item?.MoreInfo} line={params.line} />
                    </div>
                </div>
            </div>
        </div>
    )
}
