import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { API } from './api'
import axios from 'axios'

const FormContext = createContext()

function getUrlParameter(name) {
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

let filterObj = {}

export const FormProvider = ({ children, params, noApi = false }) => {
    const prevValuesRef = useRef({});
    const minSliderPortlet = params.block?.[params.locale]?.SliderMin
        ? parseInt(params.block[params.locale].SliderMin)
        : ''
    const maxSliderPortlet = params.block?.[params.locale]?.SliderMax
        ? parseInt(params.block[params.locale].SliderMax)
        : ''
    const brandUrl = getUrlParameter('brand') || ''
    const modelUrl = getUrlParameter('model') || ''
    const engineTypeUrl = getUrlParameter('engineType') || ''
    const transmissionUrl = getUrlParameter('transmission') || ''
    const driveUrl = getUrlParameter('drive') || ''
    const carTypeUrl = getUrlParameter('carType') || 'All'
    let driveUrlCheck
    if (getUrlParameter('drive')) {
        driveUrlCheck = getUrlParameter('drive') === 'Drive_2x4' ? false : true
    } else {
        driveUrlCheck = false
    }
    const minPriceUrl = getUrlParameter('minPrice') ? parseInt(getUrlParameter('minPrice')) : 0
    const maxPriceUrl = getUrlParameter('maxPrice') ? parseInt(getUrlParameter('maxPrice')) : 10000
    const localizationUrl = getUrlParameter('localization') || ''
    const seats = { "3":"3",
                    "4":"4",
                    "5":"5",
                    "6":"6",
                    "7":"7",
                    "8":"8",
                    "9":"9"
                }
    const productLine = ''
    /*const productLine = getUrlParameter('productLine') 
        ? getUrlParameter('productLine') 
        : (params.block?.[params.locale]?.ListType === 'ltr' || params.block?.[params.locale]?.ListType === 'mtr')
            ? (params.block?.[params.locale]?.ListType).toUpperCase()
            : '';*/
    const listType = params.block?.[params.locale]?.ListType ? params.block?.[params.locale]?.ListType : 'lac';
    const seatsPlaceUrl = getUrlParameter('seatsPlace') || '';
    const carClassUrl = getUrlParameter('carClass') || '';

    const [sortingLabel, setSortingLabel] = useState('newest')

    const [brands, setBrands] = useState([])
    const [brandsSorted, setBrandsSorted] = useState([])
    const [brandsFilter, setBrandsFilter] = useState([])
    const [models, setModels] = useState([])
    const [modelsFilter, setModelsFilter] = useState([])

    const [fuelTypes, setFuelTypes] = useState([])
    const [fuelTypesFilter, setFuelTypesFilter] = useState([])

    const [transmission, setTransmission] = useState([])
    const [transmissionFilter, setTransmissionFilter] = useState([])

    const [carClasses, setCarClasses] = useState([])
    const [carClassFilter, setCarClassFilter] = useState([])

    const [seatsPlaces, setSeatsPlaces] = useState([])
    const [seatsPlacesFilter, setSeatsPlacesFilter] = useState([])

    const [transmissionLabel, setTransmissionLabel] = useState(transmissionUrl)
    const [drive, setDrive] = useState([])
    const [driveLabel, setDriveLabel] = useState(driveUrl)
    const [driveChecked, setDriveChecked] = useState(driveUrlCheck)

    const [localization, setLocalization] = useState([])
    const [localizationFilter, setLocalizationFilter] = useState([])

    const [localizationLabel, setLocalizationLabel] = useState(localizationUrl)
    const [details, setDetails] = useState('')
    const [allOffers, setAllOffers] = useState([])
    const [filteredOffers, setFilteredOffers] = useState(allOffers)

    const [filters, setFilters] = useState({})
    const [offerCount, setOfferCount] = useState(0)
    const [objToFilter, setObjectToFilter] = useState({})

    const [productLineType, setProductLineType] = useState('')

    // New states
    const [rangeValues, setRangeValues] = useState([minPriceUrl, maxPriceUrl])
    const [tempRangeValues, setTempRangeValues] = useState([minPriceUrl, maxPriceUrl])
    const [lowerValue, setLowerValue] = useState(rangeValues[0])
    const [higherValue, setHigherValue] = useState(rangeValues[1])
    const [isDailyCar, setIsDailyCar] = useState(carTypeUrl)
    const [mark, setMark] = useState(brandUrl)
    const [model, setModel] = useState(modelUrl)
    const [engineType, setEngineType] = useState(engineTypeUrl)
    const [isLoading, setIsLoading] = useState(true)
    const [seatsPlace, setSeatsPlace] = useState(seatsPlaceUrl)
    const [carClass, setCarClass] = useState(carClassUrl)

    const [showUrl, setShowUrl] = useState('')

    const [error, setError] = useState(true)

    const [filtersLoaded, setFiltersLoaded] = useState(false)

    const [oldSliderValues, setOldSliderValues] = useState([0, 0])
    const [isBrandChange, setIsBrandChange] = useState(false);
    

    // states for mtr ltr
    const [isLoadedList, setIsLoadedList] = useState(false)
    const [minMtr, setMinMtr] = useState(500)
    const [maxMtr, setMaxMtr] = useState(10000)

    const [rerender, setRerender] = useState(1)

    const [chosenTagId, setChosenTagId] = useState('')
    const [tags, setTags] = useState([])
    const [tagsFilter, setTagsFilter] = useState([])

    const [brandsAreSorted, setBrandsAreSorted] = useState(false)

    function getMinMaxNetto(offers, isMtr) {
        
        const limitNettoValues = isMtr ? offers.map(offer => parseFloat(offer.LimitNetto)) : offers.map(offer => parseFloat(offer.PriceNetto));
    
        const minLimitNetto = Math.min(...limitNettoValues);
        const maxLimitNetto = Math.max(...limitNettoValues);
        setMinMtr(minLimitNetto)
        setMaxMtr(maxLimitNetto)
        setRangeValues([minLimitNetto,maxLimitNetto])
    }

    // New functions
    const handleSliderChange = (event, newValue) => {
        setTempRangeValues(newValue);
    };
    
    const handleSliderChangeCommitted = (event, newValue) => {
        if(oldSliderValues[0] === 0 && oldSliderValues[1] === 0) {
            setOldSliderValues(rangeValues)
        }
        filterObj.resetPrice = "true";
        setRangeValues(newValue);
        setLowerValue(newValue[0]);
        setHigherValue(newValue[1]);

    };

    function handleSliderChangeMtrLtr(event, newValue) {
        setRangeValues(newValue);
        setLowerValue(newValue[0]);
        setHigherValue(newValue[1]);
    }
    

    const handleLowerInputChange = event => {
        const value = event.target.value === '' ? '' : Number(event.target.value)
        setLowerValue(value)
        setRangeValues([value, rangeValues[1]])
    }

    const handleHigherInputChange = event => {
        const value = event.target.value === '' ? '' : Number(event.target.value)
        setHigherValue(value)
        setRangeValues([rangeValues[0], value])
    }

    const handleSortingChange = e => {
        setSortingLabel(e.target.value)
    }

    async function getAllOffersPOST(obj) {
        if (listType !== 'staticltr' && listType !== 'staticmtr') {
            const response = await axios.post(API['GET_ALL_OFFERS'], obj)
            const data = response.data
            if (data) {
                setAllOffers(data.offers)
                setFilteredOffers(data.offers)
                setFilters(data.filters)
                setFiltersLoaded(true)
                setOfferCount(data.offerCount)
                setDriveChecked(data.filters['is4x4'])
                if (data.filters)
                    setRangeValues([data.filters.minPrice, data.filters.maxPrice])
                    if(data.filters.constantMaxPrice !== 0 && data.filters.constantMinPrice !== 0) {
                        setOldSliderValues([data.filters.constantMinPrice, data.filters.constantMaxPrice])
                    } else {
                        setOldSliderValues([0, 0])
                    }
                    // setLowerValue(data.filters.minPrice)
                    // setHigherValue(data.filters.maxPrice)

                    setIsLoading(false)
            }
            if (data.status === 500) {
                setAllOffers([])
                setFilters({})
                setOfferCount(0)
                setError(true)
            }
        }
        else if (listType === 'staticltr' && !isLoadedList) {
            const listItems = params.block?.[params.locale]?.Item ? params.block?.[params.locale]?.Item : [];
            setAllOffers(listItems)
            setFilteredOffers(listItems)
            setFilters([])
            setFiltersLoaded(true)
            setOfferCount(14)
            getMinMaxNetto(listItems)
            setIsLoadedList(true)
            
            //setDriveChecked([]) trigger effect
            
                // setRangeValues([100, 10000])
                // setLowerValue(data.filters.minPrice)
                // setHigherValue(data.filters.maxPrice)

                setIsLoading(false)
        }
        else if (listType === 'staticmtr' && !isLoadedList) {
            const listItems = params.block?.[params.locale]?.Item ? params.block?.[params.locale]?.Item : [];
            setAllOffers(listItems)
            setFilteredOffers(listItems)
            setFilters([])
            setFiltersLoaded(true)
            setOfferCount(12)
            getMinMaxNetto(listItems, true)
            setIsLoadedList(true)
            //setDriveChecked([]) trigger effect
            
                // setRangeValues([100, 10000])
                // setLowerValue(data.filters.minPrice)
                // setHigherValue(data.filters.maxPrice)

                setIsLoading(false)
        }
        else if(listType !== 'staticmtr' && listType !== 'staticltr') {
            setAllOffers([])
            setFilters({})
            setOfferCount(0)
            setError(true)
        }
        // delete filterObj.resetPrice // delete resetFlag from request
    }

    useEffect(() => {
        // Fetch car brands
        getAllOffersPOST(filterObj).then(() => {
            if (filtersLoaded) {

                fetch(API['GET_BRANDS'])
                    .then(response => response.json())
                    .then(data => {
                        setBrands(
                            data['pl']
                                ? filters.makeIds == null
                                    ? data['pl']
                                    : filters.makeIds.map(id => data['pl'][id]).filter(Boolean)
                                : []
                        )
                    setBrandsFilter(data['pl'] ? data['pl'] : [])
                })
                //if (productLine === 'LTR') { temp ???
                    fetch(API['GET_CLASSES'])
                        .then(response => response.json())
                        .then(data => {
                            setCarClasses(
                                data['pl']
                                    ? filters.carClasses == null
                                        ? data['pl']
                                        : filters.carClasses.map(id => data['pl'][id]).filter(Boolean)
                                    : []
                            )
                            setSeatsPlaces(
                                filters.seatsPlaces == null
                                    ? seats
                                    : filters.seatsPlaces.map(id => seats[id]).filter(Boolean)
                            )
                        setCarClassFilter(data['pl'] ? data['pl'] : [])
                        setSeatsPlacesFilter(seats)
                    })
                //}
                
                if (brandUrl && (Object.keys(filters).length !== 0)) {
                    fetch(`${API['GET_MODELS']}${brandUrl}.json`)
                        .then(response => response.json())
                        .then(data => {
                            if(filters.modelIds != null) {

                                const filteredModelIds = {}
                                filteredModelIds['pl'] = {}
                                filters.modelIds.forEach(id => {
                                    if (data['pl'][id]) {
                                        filteredModelIds['pl'][id] = data['pl'][id]
                                    }
                                })
                                
                                setModels(filteredModelIds)
                                setModelsFilter(filteredModelIds)
                            } else {
                                setModels(data)
                                setModelsFilter(data)
                            }
                        })
                }
                // Fetch fuel types
                fetch(API['GET_FUEL_TYPES'])
                    .then(response => response.json())
                    .then(data => {
                        if (filters.fuels == null) {
                            return [
                                setFuelTypes(data[params.locale] ? data[params.locale] : []),
                                setFuelTypesFilter(data[params.locale] ? data[params.locale] : []),
                            ]
                        }
                        const filteredFuelsObject = Object.fromEntries(
                            Object.entries(data[params.locale]).filter(([key, value]) => filters.fuels.includes(key))
                        )
                        setFuelTypes(data[params.locale] ? filteredFuelsObject : [])
                        setFuelTypesFilter(data[params.locale] ? data[params.locale] : [])
                    })

                fetch(API['GET_TRANSMISSION_TYPES'])
                    .then(response => response.json())
                    .then(data => {
                        if (filters.transmission == null) {
                            return [
                                setTransmission(data[params.locale] ? data[params.locale] : []),
                                setTransmissionFilter(data[params.locale] ? data[params.locale] : []),
                            ]
                        }
                        const filteredTransmissionObject = Object.fromEntries(
                            Object.entries(data[params.locale]).filter(([key, value]) =>
                                filters.transmissions.includes(key)
                            )
                        )
                        setTransmission(data[params.locale] ? filteredTransmissionObject : [])
                        setTransmissionFilter(data[params.locale] ? data[params.locale] : [])
                    })

                fetch(API['GET_DRIVE_TYPES'])
                    .then(response => response.json())
                    .then(data => setDrive(data[params.locale] ? data[params.locale] : []))

                fetch(API['GET_LOCATIONS'])
                    .then(response => response.json())
                    .then(data => {
                        if (filters.locationIds == null) {
                            return [setLocalization(data ? data : []), setLocalizationFilter(data ? data : [])]
                        }
                        const filteredLocationObject = data.filter(location => filters.locationIds.includes(location.id))
                        setLocalization(data ? filteredLocationObject : [])
                        setLocalizationFilter(data ? data : [])
                })
            }
        })
        
        // getAllOffers()
    }, [filtersLoaded])


    useEffect(() => { 



    // ZMIANA w pokazywaniu brands: pokazujemy wszystkie marki z dostępnych ofert
    // posortuj tablicę i już jej nie zmieniaj przed kolejnym wyświetleniem

        if(!brandsAreSorted) {
        // Dodawanie przedrostka "id" do każdego klucza w obiekcie
        const prefixedData = Object.fromEntries(
            Object.entries(brands).map(([key, value]) => [`id${key}`, value])
        )

        // Konwersja obiektu do tablicy par klucz-wartość, sortowanie i konwersja z powrotem do obiektu
        const sortedEntries = Object.entries(prefixedData).sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB));

        // Nowy obiekt z posortowanymi wpisami 
        const sortedObject = Object.fromEntries(sortedEntries);

        setBrandsSorted(sortedObject) 

        }
    }, [brands, brandsFilter])

    useEffect(() => { 
        // posortuj tablicę i już jej nie zmieniaj przed kolejnym wyświetleniem
        if(Object.entries(brandsSorted).length !== 0) { 
            setBrandsAreSorted(true) 
        }
    }, [brandsSorted])

    useEffect(() => {
        let newFilteredOffers = allOffers ? allOffers : []
        const urlParams = []
        let baseUrl = ''

        if (mark) {
            baseUrl += `${encodeURIComponent(brands[mark])}/`;
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.makeId === parseInt(mark))
            urlParams.push(`brand=${encodeURIComponent(mark)}`)
        }

        if (model) {
            baseUrl += `${encodeURIComponent(models?.['pl']?.[model])}/`;
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.modelId === parseInt(model))
            urlParams.push(`model=${encodeURIComponent(model)}`)
        }

        if (engineType) {
            if(listType === 'staticmtr' || listType === 'staticltr' || listType === 'ltr') {
                newFilteredOffers = newFilteredOffers.filter(offer => offer.Fuel.includes(engineType))
                }
            urlParams.push(`engineType=${encodeURIComponent(engineType)}`)
        }

        if(carClass) {
            if(listType === 'staticmtr' || listType === 'staticltr' || listType === 'ltr') {
                newFilteredOffers = newFilteredOffers.filter(offer => offer.Class === carClassFilter[carClass])
            }
        }

        if(seatsPlace) {
            if(listType === 'staticmtr' || listType === 'staticltr' || listType === 'ltr') {
                newFilteredOffers = newFilteredOffers.filter(offer => offer.Seats === seatsPlace)
            }
        }

        if(listType === 'staticmtr') {
            newFilteredOffers = newFilteredOffers.filter(offer => {
                const lowerPrice = parseInt(offer.LimitNetto)
                const higherPrice = parseInt(offer.LimitBrutto)
                return (lowerPrice >= lowerValue && lowerPrice <= higherValue) ||
                (higherPrice >= lowerValue && higherPrice <= higherValue);
                // return offer.rents.some(rent => rent.rent >= lowerValue && rent.rent <= higherValue)
            })
        }

        if(listType === 'staticltr' || listType === 'ltr') {
            newFilteredOffers = newFilteredOffers.filter(offer => {
                const lowerPrice = parseInt(offer.InitialNetto)
                const higherPrice = parseInt(offer.PriceNetto)
                return (lowerPrice >= lowerValue && lowerPrice <= higherValue) ||
                (higherPrice >= lowerValue && higherPrice <= higherValue);
                // return offer.rents.some(rent => rent.rent >= lowerValue && rent.rent <= higherValue)
            })
        }

        if (transmissionLabel) {
            if(listType === 'staticmtr' || listType === 'staticltr' || listType === 'ltr') {
                let label = ''
                if(params.locale === 'pl') {
                    if(transmissionLabel === 'Automatic') {
                        label = 'Automat'
                    } else {
                        label = transmissionLabel
                    }
                }
                newFilteredOffers = newFilteredOffers.filter(offer => offer.Gearbox === label)
            }
            urlParams.push(`transmission=${encodeURIComponent(transmissionLabel)}`)
        }

        //filter drive with SELECT
        // if (driveLabel) {
        //     newFilteredOffers = newFilteredOffers.filter(offer => offer.driveType === driveLabel)
        //     urlParams.push(`drive=${encodeURIComponent(driveLabel)}`)
        // }

        //filter drive with checkbox
        if(driveChecked) {
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.driveType === 'Drive_4x4')
            urlParams.push(`drive=${encodeURIComponent('Drive_4x4')}`)
        } else {
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.driveType === 'Drive_2x4')
            urlParams.push(`drive=${encodeURIComponent('Drive_2x4')}`)
        }
        if (seatsPlace) {
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.fuel === engineType)
            urlParams.push(`seatsPlace=${encodeURIComponent(seatsPlace)}`)
        }
        if (carClass) {
            // newFilteredOffers = newFilteredOffers.filter(offer => offer.fuel === engineType)
            urlParams.push(`carClass=${encodeURIComponent(carClass)}`)
        }
        urlParams.push(`minPrice=${encodeURIComponent(rangeValues[0])}`)
        urlParams.push(`maxPrice=${encodeURIComponent(rangeValues[1])}`)
        // newFilteredOffers = newFilteredOffers.filter(offer => {
        //     return offer.rents.some(rent => rent.rent >= lowerValue && rent.rent <= higherValue)
        // })
        
        if (isDailyCar === 'Car') {
            urlParams.push(`carType=${encodeURIComponent('Car')}`)
        } else if (isDailyCar === 'Truck') {
            urlParams.push(`carType=${encodeURIComponent('Truck')}`)
        } else if(isDailyCar === 'Electric') {
            urlParams.push(`carType=${encodeURIComponent('Electric')}`)
        } else if(isDailyCar === 'All') {
            urlParams.push(`carType=${encodeURIComponent('All')}`)
        } else {
            urlParams.push(`carType=${encodeURIComponent('All')}`)
        }

        if (localizationLabel) {
            urlParams.push(`localization=${encodeURIComponent(localizationLabel)}`)
            // newFilteredOffers = newFilteredOffers.filter(offer => {
            //     return offer.locationIds.includes(localizationLabel)
            // })
        }

        const queryParams = urlParams.length > 0 ? `?${urlParams.join('&')}` : '';
        const newShowUrl = `${baseUrl}${queryParams}`;

        setShowUrl(newShowUrl);

        if(sortingLabel === 'newest') {
            newFilteredOffers = newFilteredOffers.sort((a, b) => {
                if (a.carsCount === 0 && b.carsCount === 0) {
                    return 0;
                } else if (a.carsCount === 0) {
                    return 1;
                } else if (b.carsCount === 0) {
                    return -1; 
                }
                
                return b.id - a.id;
            });
            setRerender(rerender => rerender + 1)
        }

        if (sortingLabel === 'minPrice') {
            newFilteredOffers = newFilteredOffers.sort((a, b) => a.minPrice - b.minPrice)
            newFilteredOffers = newFilteredOffers.sort((a, b) => {
                if (a.carsCount === 0 && b.carsCount === 0) {
                    return 0;
                } else if (a.carsCount === 0) {
                    return 1;
                } else if (b.carsCount === 0) {
                    return -1; 
                }
                
                return a.minPrice - b.minPrice;
            });
            setRerender(rerender => rerender + 1)
        }

        if (sortingLabel === 'maxPrice') {
            newFilteredOffers = newFilteredOffers.sort((a, b) => b.minPrice - a.minPrice)
            newFilteredOffers = newFilteredOffers.sort((a, b) => {
                if (a.carsCount === 0 && b.carsCount === 0) {
                    return 0;
                } else if (a.carsCount === 0) {
                    return 1;
                } else if (b.carsCount === 0) {
                    return -1; 
                }
                
                return b.minPrice - a.minPrice;
            });
            setRerender(rerender => rerender + 1)
        }

        if(listType === 'staticmtr' || listType === 'staticltr') {
            setFilteredOffers(newFilteredOffers)
        }

        // if(listType === 'staticmtr') {
        //     getMinMaxNetto(newFilteredOffers, true)
        // }
        // if(listType === 'staticltr') {
        //     getMinMaxNetto(newFilteredOffers)
        // }
    }, [
        allOffers,
        mark,
        model,
        engineType,
        lowerValue,
        higherValue,
        isDailyCar,
        transmissionLabel,
        driveLabel,
        localizationLabel,
        sortingLabel,
        driveChecked,
        seatsPlace,
        carClass
    ])

    useEffect(() => {
        if (isBrandChange) {
            return; 
        }
        const prevValues = prevValuesRef.current;

        let driveTypeVal;
        if (driveChecked) {
            driveTypeVal = 'Drive_4x4';
        } else {
            driveTypeVal = '';
        }

        if (prevValues.lowerValue !== lowerValue || prevValues.higherValue !== higherValue) {
            filterObj['constantMinPrice'] = oldSliderValues[0] !== 0 ? oldSliderValues[0] : null;
            filterObj['constantMaxPrice'] = oldSliderValues[1] !== 0 ? oldSliderValues[1] : null;
            filterObj['minPrice'] = lowerValue;
            filterObj['maxPrice'] = higherValue;
        } else {
            filterObj['constantMinPrice'] = null;
            filterObj['constantMaxPrice'] = null;
            // filterObj['minPrice'] = 0;
            // filterObj['maxPrice'] = 10000;
        }

        filterObj['driveType'] = driveTypeVal;
        filterObj['fuel'] = engineType;
        filterObj['transmission'] = transmissionLabel;
        filterObj['locationIds'] = localizationLabel.toString();
        filterObj['makeId'] = mark;
        filterObj['modelId'] = model;
        // filterObj['minPrice'] = lowerValue;
        // filterObj['maxPrice'] = higherValue;
        filterObj['approvalType'] = isDailyCar === 'All' ? '' : isDailyCar;
        filterObj['productLine'] = productLine;
        filterObj['seatsPlace'] = seatsPlace;
        filterObj['carClass'] = carClass;
        filterObj['tag'] = chosenTagId;

        getAllOffersPOST(filterObj);
        setOldSliderValues([0, 0]);

        prevValuesRef.current = {
            engineType,
            transmissionLabel,
            localizationLabel,
            model,
            driveChecked,
            lowerValue,
            higherValue,
            isDailyCar,
            fuelTypesFilter,
            seatsPlace,
            carClass
        };
    }, [
        engineType,
        transmissionLabel,
        localizationLabel,
        model,
        driveChecked,
        lowerValue,
        higherValue,
        isDailyCar,
        fuelTypesFilter,
        seatsPlace,
        carClass,
        mark,
        productLine,
        chosenTagId
    ]);

    useEffect(() => {
        setTempRangeValues(rangeValues);
    }, [rangeValues]);

    function resetPriceFilter() {
        delete filterObj.resetPrice
        filterObj.minPrice = 0
        filterObj.maxPrice = 10000
        getAllOffersPOST(filterObj)
    }

    const handleBrandChange = (brandId, isReset) => {
        setOldSliderValues([0, 0])
        filterObj['constantMinPrice'] = null;
        filterObj['constantMaxPrice'] = null;
        if(brandId === ''){
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
        setModel('')
        filterObj['makeId'] = brandId
        filterObj['modelId'] = ''
        if(!isReset){
            setIsBrandChange(true);
            getAllOffersPOST(filterObj).then(() => {
                if (brandId) {
                    fetch(`${API['GET_MODELS']}${brandId}.json`)
                        .then(response => response.json())
                        .then(data => {
                            setModels(data)
                            setModelsFilter(data)
                            setIsBrandChange(false); // Reset the flag
                        })
                } else {
                    setModels([])
                    setModelsFilter([])
                    setIsBrandChange(false); // Reset the flag
                }
            })
        }
    }

    useEffect(() => {
        if (allOffers.length > 0) {
            if (filters.fuels == null) {
                setFuelTypes(fuelTypesFilter)
            } else {
                const filteredFuelsObject = Object.fromEntries(
                    Object.entries(fuelTypesFilter).filter(([key, value]) => filters.fuels.includes(key))
                )
                setFuelTypes(fuelTypesFilter ? filteredFuelsObject : [])
            }
        }

        if (allOffers.length > 0) {
            if (filters.transmissions == null) {
                setTransmission(transmissionFilter)
            } else {
                const filteredTransmissionObject = Object.fromEntries(
                    Object.entries(transmissionFilter).filter(([key, value]) => filters.transmissions.includes(key))
                )
                setTransmission(transmissionFilter ? filteredTransmissionObject : [])
            }
        }

        if (allOffers.length > 0) {
            if (filters.makeIds == null) {
                setBrands(brandsFilter)
            } else {
                const filteredCarBrands = Object.fromEntries(
                    Object.entries(brandsFilter).filter(([key, value]) => filters.makeIds.includes(parseInt(key)))
                )
                setBrands(brandsFilter ? filteredCarBrands : [])
            }
        }

        if (allOffers.length > 0) {
            if (filters.carClasses == null) {
                setCarClasses(carClassFilter)
            } else {
                const filteredCarClass = Object.fromEntries(
                    Object.entries(carClassFilter).filter(([key, value]) => filters.carClasses.includes(parseInt(key)))
                )
                setCarClasses(carClassFilter ? filteredCarClass : [])
            }
        }

        if (allOffers.length > 0) {
            if (filters.seatsPlaces == null) {
                setSeatsPlaces(seatsPlacesFilter)
            } else {
                const filteredSeatsPlaces = Object.fromEntries(
                    Object.entries(seatsPlacesFilter).filter(([key, value]) => filters.seatsPlaces.includes(parseInt(key)))
                )
                setSeatsPlaces(seatsPlacesFilter ? filteredSeatsPlaces : [])
            }
        }

        if (modelsFilter['pl']) {
            if (allOffers.length > 0) {
                if (filters.modelIds == null) {
                    setModels(modelsFilter)
                } else {
                    const filteredCarModels = {
                        pl: Object.fromEntries(//create pl object
                            Object.entries(modelsFilter['pl']).filter(([key, value]) => filters.modelIds.includes(parseInt(key)))
                        )
                    }
                    setModels(modelsFilter ? filteredCarModels : [])
                }
            }
        }

        if (allOffers.length > 0) {
            if (filters.locationIds == null) {
                setLocalization(localizationFilter)
            } else {
                const filteredLocations = localizationFilter.filter(location => filters.locationIds.includes(location.id));

                setLocalization(localizationFilter ? filteredLocations : [])
            }
        }

        if (allOffers.length > 0) {
            if (filters.tag == null) {
                setTags(tagsFilter)
            } else {
                const filteredTags = tagsFilter.filter(tag => filters.tag.includes(tag.id)); 

                setTags(tagsFilter ? filteredTags : [])
            }
        }
    }, [allOffers, fuelTypesFilter, filters, transmissionFilter, brandsFilter, carClassFilter, seatsPlacesFilter, modelsFilter, localizationFilter, tagsFilter])

    const handleChangeModel = event => {
        setModel(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleChangeTransmission = event => {
        setTransmissionLabel(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }
    const handleChangeDrive = event => {
        setDriveLabel(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleChangeEngineType = event => {
        setEngineType(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleChangeTagId = event => {
        setChosenTagId(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleDetailsChange = event => {
        setDetails(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleChangeLocalization = event => {
        setLocalizationLabel(event.target.value)
        if(event.target.value === '') {
            filterObj['minPrice'] = 0;
            filterObj['maxPrice'] = 10000;
        }
    }

    const handleDriveChecked = () => {
        setDriveChecked(prev => !prev)
        filterObj['minPrice'] = 0;
        filterObj['maxPrice'] = 10000;
    }

    const handleCarClassChange = event => {
        setCarClass(event)
    }

    const handleSeatsPlaceChange = event => {
        setSeatsPlace(event)
    }

    const handleTypeChange = str => {
        if(mark !== '') {
            handleBrandChange('', true)
        }
        setOldSliderValues([0, 0])
        setMark('')
        setModel('')
        setTransmissionLabel('')
        setEngineType('')
        setLowerValue(0)
        setHigherValue(10000)
        setDriveChecked(false)
        setIsDailyCar(str)
        setLocalizationLabel('')
        setChosenTagId('')
    }
    
    return (
        <FormContext.Provider
            value={{
                brands,
                brandsSorted,
                setBrands,
                models,
                setModels,
                fuelTypes,
                setFuelTypes,
                transmission,
                setTransmission,
                drive,
                setDrive,
                localization,
                setLocalization,
                details,
                setDetails,
                allOffers,
                setAllOffers,
                filteredOffers,
                setFilteredOffers,
                rangeValues,
                setRangeValues,
                lowerValue,
                setLowerValue,
                higherValue,
                setHigherValue,
                isDailyCar,
                handleTypeChange,
                mark,
                setMark,
                model,
                setModel,
                engineType,
                setEngineType,
                isLoading,
                setIsLoading,
                handleSliderChange,
                handleSliderChangeCommitted,
                tempRangeValues,
                handleLowerInputChange,
                handleHigherInputChange,
                handleBrandChange,
                handleCarClassChange,
                handleSeatsPlaceChange,
                handleChangeModel,
                handleChangeEngineType,
                transmissionLabel,
                driveLabel,
                handleChangeTransmission,
                handleChangeDrive,
                handleDetailsChange,
                details,
                localizationLabel,
                handleChangeLocalization,
                showUrl,
                params,
                minSliderPortlet,
                maxSliderPortlet,
                handleSortingChange,
                sortingLabel,
                setDriveChecked,
                handleDriveChecked,
                driveChecked,
                error,
                minPriceUrl,
                maxPriceUrl,
                productLine,
                productLineType,
                setProductLineType,
                seatsPlace,
                setSeatsPlace,
                seatsPlaces,
                setSeatsPlaces,
                carClass,
                setCarClass,
                carClasses,
                setCarClasses,
                resetPriceFilter,
                filters,
                oldSliderValues,
                handleSliderChangeMtrLtr,
                minMtr,
                maxMtr,
                rerender,
                chosenTagId,
                handleChangeTagId
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

export const useFormContext = () => {
    return useContext(FormContext)
}
