import React from 'react'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import DialogWindow from '../../forms/components/DialogWindow'
import ChooseButton from '../../forms/components/ChooseButton'

export default function PopupLogin({showModal, setShowModal, loginUrl, registerUrl, title}) {
  const {t} = useTranslation()
  return (
    <DialogWindow isOpen={showModal} setIsOpen={setShowModal}>
      <Typography variant='h3' fontSize={{xs: '24px', md: '36px'}} fontWeight='bold' color={'rgba(0,0,0,0.7)'} mb={4}>{title}</Typography>
      <IconButton
          aria-label="close"
          onClick={() => setShowModal(false)}
          sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
          }}
      >
          <CloseIcon />
      </IconButton>
      <div className='text-center'>{t('login_button_popup_text')}</div>
      <div className='flex flex-col items-center'>
          <a
              href={registerUrl}
              title={t('header.nav.register')}
              className='mb-4'
          >
              <ChooseButton blueBtn type="button">
                {t('header.nav.register')}
              </ChooseButton>
          </a>
          {t('login_button_popup_text_2')}
          <a 
              href={loginUrl} 
              title={t('header.nav.login')} 
          >
              <ChooseButton blueBtn type="button">
              {t('header.nav.login')}
              </ChooseButton>
          </a>
      </div>
    </DialogWindow>
  )
}
