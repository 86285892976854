import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import Spinner from '../../components/Spinner'
import ErrorBox from '../ErrorBox'
import { API, FORM_KEY } from '../../api'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import EditButton from '../../components/EditButton'
import IconShield from '../../svg/IconShield'
import ReservationDetailsBoxIcon from '../../../components/ReservationDetailsBoxIcon'

const ReservationDetailsPackage = ({ packagePrice, packageDepositPrice, packageIsSilver, activeStep }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { loading, error, fetchData } = useFetch()

    const formattedPackagePrice = useFormattedPrice(parseFloat(packagePrice).toFixed(2)).replace('.', ',') || '0 zł'

    const formattedPackageDepositPrice =
        useFormattedPrice(parseFloat(packageDepositPrice).toFixed(2)).replace('.', ',') || ''

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handleClickEditStep3 = () => {
        fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=${FORM_KEY['step3']}`, 'GET')
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        <Box
            width="100%"
            pl={{ xs: 1, lg: 2 }}
            pr={2}
            py={{ xs: 1, lg: 2 }}
            display="flex"
            justifyContent="space-between"
            borderBottom={`0.5px solid #d1d1d1`}
            alignItems="center"
        >
            <>
                <Box display="flex" alignItems="center">
                    <ReservationDetailsBoxIcon>
                        <IconShield />
                    </ReservationDetailsBoxIcon>
                    <Box alignSelf="center">
                        <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'}>
                            {packageIsSilver
                                ? t('RAC.step_three.car_insurance.1')
                                : t('RAC.step_three.car_insurance.0')}
                        </Typography>
                        <Typography fontSize={{ xs: 10, lg: 14 }} fontWeight={'normal'}>
                            {t('deposit')}: {formattedPackageDepositPrice}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography fontSize={{ xs: 14, lg: 18 }} fontWeight={'bold'} textAlign={'right'}>
                        {formattedPackagePrice}
                    </Typography>
                    {activeStep !== 3 && <EditButton handleOnClick={handleClickEditStep3} isSubmitting={loading} />}
                </Box>
            </>
        </Box>
    )
}

export default ReservationDetailsPackage
