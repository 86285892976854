import React from 'react'
import { Box } from '@mui/material'
import theme from '../../../styles/MUI-theme.js'

function textToStepper(index, locale) {
    switch (index) {
        case 0:
            return locale === 'pl' ? (
                <>
                    01.
                    <br />
                    Wybór
                    <br />
                    samochodu
                </>
            ) : (
                <>
                    01.
                    <br />
                    Car
                    <br />
                    selection
                </>
            )

        case 1:
            return locale === 'pl' ? (
                <>
                    02.
                    <br />
                    Szczegóły
                    <br />
                    oferty
                </>
            ) : (
                <>
                    02.
                    <br />
                    Offer
                    <br />
                    details
                </>
            )

        case 2:
            return locale === 'pl' ? (
                <>
                    03.
                    <br />
                    Ubezpieczenie i wyposażenie
                    <br />
                    dodatkowe
                </>
            ) : (
                <>
                    03.
                    <br />
                    Additional insurance
                    <br />
                    and equipment
                </>
            )

        case 3:
            return locale === 'pl' ? (
                <>
                    04.
                    <br />
                    Dane
                    <br />
                    do umowy
                </>
            ) : (
                <>
                    04.
                    <br />
                    Contract
                    <br />
                    details
                </>
            )

        case 4:
            return locale === 'pl' ? (
                <>
                    05.
                    <br />
                    Potwierdzenie
                    <br />
                    rezerwacji
                </>
            ) : (
                <>
                    05.
                    <br />
                    Reservation
                    <br />
                    confirmation
                </>
            )

        default:
            break
    }
}

const StepperLac = ({ steps = 5, activeStep, filterView = false }) => {
    const locale = window.location.href.includes('/en/') ? 'en' : 'pl'

    return (
        <div className={`${filterView ? 'mt-6 mb-4' : 'p-14'} bg-[#f4f4f3] rounded-[30px] hidden md:flex`}>
            {Array.from({ length: steps }).map((step, index) => (
                <Box
                    key={`step-${index}`}
                    display="flex"
                    alignItems={'center'}
                    sx={{ width: `${100 / (steps - 1)}%`, position: 'relative' }}
                >
                    <Box width={`calc(100%)`}>
                        <div
                            className={`${index < activeStep ? 'font-bold c-color3' : ' opacity-60'} text-[10px] lg:text-[11px] leading-3 mb-2`}
                        >
                            {textToStepper(index, locale)}
                        </div>
                        <Box
                            height={'6px'}
                            border={'1px solid #c7c7c7'}
                            borderRight={index === 4 ? '1px solid #c7c7c7' : 'none'}
                            borderLeft={index === 0 ? '1px solid #c7c7c7' : 'none'}
                            bgcolor={
                                index === activeStep - 2 || index < activeStep - 1
                                    ? theme.palette.secondary.main
                                    : 'transparent'
                            }
                        ></Box>
                    </Box>
                </Box>
            ))}
        </div>
    )
}

export default StepperLac
