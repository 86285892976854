import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function IconLocation({ color = '#8c8b8b' }) {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
                <path
                    class="st0"
                    d="M12,14.72c-4.06,0-7.36-3.3-7.36-7.36S7.94,0,12,0s7.36,3.3,7.36,7.36S16.06,14.72,12,14.72z M12,1
		C8.49,1,5.64,3.85,5.64,7.36c0,3.51,2.85,6.36,6.36,6.36s6.36-2.85,6.36-6.36C18.36,3.85,15.51,1,12,1z"
                />
                <path
                    class="st0"
                    d="M15.71,7.86c-0.28,0-0.5-0.22-0.5-0.5c0-1.77-1.44-3.21-3.21-3.21c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5
		c2.32,0,4.21,1.89,4.21,4.21C16.21,7.64,15.99,7.86,15.71,7.86z"
                />
                <path
                    class="st0"
                    d="M12,24c-0.23,0-0.44-0.16-0.49-0.39l-2.18-9.54c-0.06-0.27,0.11-0.54,0.38-0.6c0.27-0.06,0.54,0.11,0.6,0.38
		l1.69,7.4l1.69-7.4c0.06-0.27,0.33-0.44,0.6-0.38c0.27,0.06,0.44,0.33,0.38,0.6l-2.18,9.54C12.44,23.84,12.23,24,12,24z"
                />
            </svg>
        </SvgIcon>
    )
}
