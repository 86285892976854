import React, { useState } from 'react'
import { useFormContext } from '../../forms/LAC/FormContext'
import { MenuItem, Select } from '@mui/material'
import OffersListLACItem from './OffersListLACItem'
import OffersListLTRItem from './OffersListLTRItem'
import OffersListMTRItem from './OffersListMTRItem'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import Filters from './components/Filters'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useTags from './hooks/useTags'

const DISPLAYED_OFFERS = 15

const OffersListLAC = ({ params }) => {
    const listType = params.block?.[params.locale]?.ListType ? params.block?.[params.locale]?.ListType : 'lac';
    const [listAsGrid, setListAsGrid] = useState((listType === 'staticltr' || listType === 'staticmtr') ? false : true)
    const [displayedOffers, setDisplayedOffers] = useState(DISPLAYED_OFFERS)
    const { filteredOffers, sortingLabel, handleSortingChange, brands, models, fuelTypes, transmission, carClasses, rerender } = useFormContext()
    const { tagsData } = useTags()
    const { t } = useTranslation()
    const showMoreOffers = () => {
        setDisplayedOffers(prev => prev + DISPLAYED_OFFERS)
    }

    let choosen = (params?.params?.block[params.params.locale].hasOwnProperty('Item') ? params?.params?.block[params.params.locale]['Item'] : []);
    //get list of choosen id if so
    let resultItemsId = [];

    let setTagName = [];
    if (choosen.length > 0) {
        choosen.map((item) => {
            resultItemsId.push(item['ID'])
            setTagName.push(item['Tag'])
            return null
        })
    }
    resultItemsId = [...new Set(resultItemsId)];

    //testing names fo tags
    const tagsName = {
        "pl": {
          "1": "Kilka sztuk",
          "2": "Oferta dnia",
          "3": "Nowość w ofercie",
          "4": "Autoabonament",
          "5": "Pay Per Mile",
          "6": "Clik&Drive",
          "7": "Ciężarowe",
          "8": "Elektryczne",
          "9": "Promocja",
        },
        "en": {
            "1": "A few cars",
            "2": "Offer of the day",
            "3": "New in the offer",
            "4": "Auto subscription",
            "5": "Pay Per Mile",
            "6": "Clik&Drive",
            "7": "Trucks",
            "8": "Electric",
            "9": "Special offer",
        }
    };
    function getTagId(name) {
        return Object.keys(tagsName[params.locale]).filter(tag => tagsName[params.locale][tag] === name).toString();
    }
    function getTagName(id) {
        return tagsName[params.locale][id]
    }
    function getTags(item) {
        let id = '';
        let name = '';
        if (setTagName.length > 0) {
            if (item.hasOwnProperty('showTag') && item.showTag !== '') {
                id = getTagId(item.showTag)
                name = item.showTag
            }
        }
        else {
            if (item.tagIds.length > 0 ) {
                id = item.tagIds[0]
                name = getTagName(item.tagIds[0])
            }
            else {
                id = 0
                name = ''
            }
        }
       
        return [id,name,getColorTag(id)]
    }
    function getColorTag(tagId) {
        if (tagId === 8) return '7' 
        return [4,2,3,1][Number(tagId) % 4];
    }
    
    return (
        rerender && <div>
            <Filters params={params} />
            <div className="flex justify-between items-center mb-10 flex-wrap gap-4">
                <span className="font-bold text-[42px]">
                    {t('LAC.offer_list.label_offers')}: {filteredOffers?.length}
                </span>
                {(listType !== 'staticltr' && listType !== 'staticmtr' )&& 
                    <div className="flex items-center gap-4 flex-wrap">
                        <div className="flex items-center gap-x-4">
                            <span className="font-semibold">{t('LAC.offer_list.show_list')}:</span>
                            <button
                                onClick={() => setListAsGrid(true)}
                                className={`${listAsGrid ? 'c-color3' : 'c-color1'}`}
                            >
                                <AppsOutlinedIcon
                                    sx={{
                                        fontSize: '26px',
                                    }}
                                />
                            </button>
                            <button
                                onClick={() => setListAsGrid(false)}
                                className={`${listAsGrid ? 'c-color1' : 'c-color3'}`}
                            >
                                <FormatListBulletedOutlinedIcon
                                    sx={{
                                        fontSize: '26px',
                                    }}
                                />
                            </button>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <span className="font-semibold">{t('LAC.offer_list.sort_list')}:</span>

                            <Select
                                IconComponent={ExpandMoreIcon}
                                displayEmpty
                                value={sortingLabel}
                                onChange={handleSortingChange}
                                disableUnderline
                                sx={{
                                    minWidth: '180px',
                                    borderRadius: '100px',
                                    height: '48px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#d1d1d1',
                                    },
                                }}
                            >
                                <MenuItem value="newest">{t('LAC.offer_list.offers_list_lac.recently_added')}</MenuItem>
                                <MenuItem value="minPrice">{t('LAC.offer_list.offers_list_lac.lowest_price')}</MenuItem>
                                <MenuItem value="maxPrice">{t('LAC.offer_list.offers_list_lac.highest_price')}</MenuItem>
                            </Select>
                        </div>
                    </div>
                }
            </div>
            {filteredOffers?.length <= 0 ? (
                <h2 className='text-center mt-10'>{t('portlets.offers_list.no_offers')}</h2>
            ) : (
                <>
                    {listType !== 'staticltr' && listType !== 'staticmtr' &&
                        <div
                            className={`offer-list-lac grid ${listAsGrid ? 'sm:grid-cols-[repeat(auto-fill,minmax(380px,1fr))] grid-cols-[repeat(auto-fill,minmax(300px,1fr))]' : 'grid-cols-1'} gap-6`}
                        >
                            {filteredOffers?.slice(0, displayedOffers)?.map((item, index) => (
                                <>
                                    {item.onlineReservation ? <OffersListLACItem tagsData={tagsData} params={params} key={index} item={item} listAsGrid={listAsGrid} transmission={transmission} fuelTypes={fuelTypes} button={params.button} tags={getTags(item)} /> : null}
                                </>
                            ))}
                        </div>
                    }
                
                    {listType === 'staticltr' && 
                        <div
                            className={`grid ${listAsGrid ? 'sm:grid-cols-[repeat(auto-fill,minmax(380px,1fr))] grid-cols-[repeat(auto-fill,minmax(300px,1fr))]' : 'grid-cols-1'} gap-6`}
                        >
                            {
                                filteredOffers?.sort((a, b) => a.PriceBrutto - b.PriceBrutto).slice(0, displayedOffers)?.map((item, index) => (
                                    <OffersListLTRItem params={params} key={index} item={item} listAsGrid={listAsGrid} transmission={transmission} fuelTypes={fuelTypes} button={params.button} carClasses={carClasses} />
                                ))
                            }
                        </div>
                    }
                    {listType === 'staticmtr' && 
                        <div
                            className={`grid ${listAsGrid ? 'sm:grid-cols-[repeat(auto-fill,minmax(380px,1fr))] grid-cols-[repeat(auto-fill,minmax(300px,1fr))]' : 'grid-cols-1'} gap-6`}
                        >
                            {
                                filteredOffers?.sort((a, b) => a.LimitBrutto - b.LimitBrutto).slice(0, displayedOffers)?.map((item, index) => (
                                    <OffersListMTRItem params={params} key={index} item={item} listAsGrid={listAsGrid} transmission={transmission} fuelTypes={fuelTypes} button={params.button} carClasses={carClasses} />
                                ))
                            }
                        </div>
                    }
                </>
            )}
            {displayedOffers < filteredOffers.length && (
                <div className="flex justify-center mt-8">
                    <button className={'btn-link b-' +params.button} onClick={showMoreOffers}>
                        {t('LAC.offer_list.show_more')}
                    </button>
                </div>
            )}
        </div>
    )
}

export default OffersListLAC
